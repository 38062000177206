import { Component, ViewChild } from '@angular/core';
import { PagingRequest } from "../../common-component/pageable-datatable/pageable";
import { MatDialog } from "@angular/material/dialog";
import { PageableDatatableComponent } from "../../common-component/pageable-datatable/pageable-datatable.component";
import {finalize, switchMap, tap} from "rxjs/operators";
import {Sort} from "@angular/material/sort";
import {User} from "../user/user";
import {GstService} from "./gst-service";
import {Gst} from "./gst";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DialogService} from "../../common-component/dialog/dialog-service";

@Component({
  selector: 'app-gst',
  templateUrl: './gst.component.html',
  providers: [GstService],
  styleUrls: ['./gst.component.css'],
})
export class GstComponent {
  constructor(
    private dialog: MatDialog,
    private gstService: GstService,
    private dialogService: DialogService,
    private snackBar: MatSnackBar,
  ) {
  }

  loading = true;

  displayedColumns: string[] = [
    'effectiveStartDate',
    'effectiveEndDate',
    'value',
    'action'
  ];

  sort: Sort | null = null;

  sortChange(sort: Sort) {
    this.sort = sort;
    this.reload();
  }

  @ViewChild(PageableDatatableComponent)
  datatable!: PageableDatatableComponent<Gst>;

  keyword = "";

  searchGsts = (request: PagingRequest<Gst>) => {
    this.loading = true;
    if (this.sort && this.sort.direction !== "") {
      request.sortDir = this.sort.direction;
      request.sortBy = this.sort.active as keyof Gst;
    }
    return this.gstService.searchGsts(request, null, null)
      .pipe(finalize(() => this.loading = false));
  }

  deleteGst(param: Gst): void {

    this.dialogService.confirm("Delete GST", "Do you want to delete this GST?", {alert: true})
      .pipe(
        tap(() => this.loading = true),
        switchMap(() => this.gstService.deleteGst(param)),
        finalize(() => this.loading = false),
      )
      .subscribe({
        next: () => {
          this.snackBar.open('Delete GST successful', 'Close', {duration: 2000});
          this.reload();
        },
        error: (error) => this.snackBar.open(error, 'Close', {duration: 10000}),
      });
  }

  startDate?: Date;
  endDate?: Date;
  validateAndReload() {
    if(this.startDate
      && this.endDate
      && this.startDate.getTime()
      > this.endDate.getTime()) {
      this.snackBar.open('The start date must before the end date', 'Close', {duration: 10000})
      return;
    }
    this.reload();
  }

  reload(): void {
    this.datatable.reload();
  }
}
