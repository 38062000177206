<app-scene header="Unit Of Measurement" [loading]="loading">
    <div class="max-w-2xl">

    </div>

    <table mat-table [dataSource]="uoms" class="mat-elevation-z8" matSort (matSortChange)="localSort($event)">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <ng-container matColumnDef="uomUnit">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>UOM</th>
            <td mat-cell *matCellDef="let element; let i = index;">
                <mat-form-field floatLabel='auto' class="pt-2 pb-2 w-full">
                  <input matInput class="rounded-sm" [(ngModel)]="element.uomCode" />
                </mat-form-field>
            </td>
        </ng-container>


        <ng-container matColumnDef="multiplier">
            <th mat-header-cell *matHeaderCellDef>Multiplier</th>
            <td mat-cell *matCellDef="let element; let i = index;">
                <mat-form-field [floatLabel]="'always'" class="flex-1">
                    <span matTextPrefix>&nbsp;</span>
                    <input matInput class="rounded-sm" [(ngModel)]="element.weightInMt" />
                </mat-form-field>
            </td>
        </ng-container>


        <ng-container matColumnDef="activeStatus">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Active Status</th>
            <td mat-cell *matCellDef="let element; let i = index;">
                <mat-checkbox [(ngModel)]="element.activeStatus" class="flex-1">
                    Active
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element; let i = index;">
                <button mat-icon-button (click)="deleteUom(element, i)">
                    <span class="material-symbols-outlined text-alert">
                        delete
                    </span>
                </button>
            </td>
        </ng-container>
    </table>

    <button class="text-btn" (click)="addRow()">
        <span class="material-symbols-outlined">add</span>
        <span>Add new row</span>
    </button>


    <div class="float-right" footer>
        <button mat-button (click)="save()" class="confirm-btn">Save</button>
    </div>
</app-scene>
