import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {PageableDatatableComponent} from "./pageable-datatable/pageable-datatable.component";
import {MatTableModule} from "@angular/material/table";
import {CdkTableModule} from "@angular/cdk/table";
import { SceneComponent } from './scene/scene.component';
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatCardModule } from "@angular/material/card";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SuggestInputComponent } from './suggest-input/suggest-input.component';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import {MatSortModule} from "@angular/material/sort";


@NgModule({
  declarations: [
    PageableDatatableComponent,
    SceneComponent,
    SuggestInputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    CdkTableModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatInputModule,
  ],
  exports: [
    PageableDatatableComponent,
    SceneComponent,
    SuggestInputComponent,
  ],
  providers: []
})
export class CommonComponentModule {
}
