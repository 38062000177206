<div class="p-8">
  <div class="flex">
    <div class="flex-1">
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        DO No.
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        Error Cause
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        Other Cause
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        DO Quantity
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        New Customer Site
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        New Product
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        New Vehicle No
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        New Transporter
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        Requested Date
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border border-black">
        Is Cancel
      </div>
    </div>


    <div class="flex-1">
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{doModification.doNo}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{doModification.errorCause}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{doModification.otherCause}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{doModification.doQty | number : '1.2-2'}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{doModification.newCustomerSite}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{doModification.newProduct}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{doModification.newVehicleNo}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{doModification.newTransporter}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{doModification.newDoDate | date : 'dd-MM-yyy'}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-y border-r border-black">
        {{doModification.cancel}}
      </div>
    </div>
  </div>
  <div class="mt-2 flex w-full justify-end">
    <button
      *ngIf="!doModification.hadInvoice"
      [queryParams]="{doNo: doModification.doNo}"
      [routerLink]="['/do-modification']"
      class="confirm-btn mr-2"
      mat-button
      mat-dialog-close
    >
      edit
    </button>
    <button class="cancel-btn" mat-button mat-dialog-close>Close</button>
  </div>
</div>
