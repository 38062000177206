import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import {SuggestInputData} from "./suggest-input";

@Component({
  selector: 'app-suggest-input',
  templateUrl: './suggest-input.component.html',
  styleUrls: ['./suggest-input.component.css'],
})
export class SuggestInputComponent<T> implements OnInit {
  ngOnInit(): void {
    this.formControl?.valueChanges?.subscribe({
      next: val => {
        if (typeof val === 'string') {
          this.suggestData.reload(val);
          this.keyChange.emit(val);
        } else {
          this.value = val;
        }
      }
    });
  }

  @Input()
  label: string | null = null;

  @Input()
  control: AbstractControl | null = null;

  get formControl() {
    return this.control as FormControl;
  }

  @Input()
  value: T | null = null;
  @Output()
  valueChange = new EventEmitter<T | null>();
  @Output()
  keyChange = new EventEmitter<string>();

  @Input()
  valueField!: keyof T;
  @Input()
  nameField!: (keyof T)[];
  @Input()
  optionField!: (keyof T)[];

  @Input()
  placeholder = "";

  @Input()
  suggestData!: SuggestInputData<T>;

  onChange = (val: T | string) => {
    if (typeof val === 'string') {
      this.suggestData.reload(val);
      this.keyChange.emit(val);
    } else {
      this.valueChange.emit(val);
    }
  }

  onSelected(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this.control?.patchValue(this.value);
    this.valueChange.emit(this.value);
  }

  getName = (data: T | null): string => {
    return this.nameField.filter(field => data?.[field]).map(field => data?.[field]).join(" - ");
  }

  getOptionName = (data: T | null): string => {
    const fields = this.optionField ?? this.nameField;
    return fields.filter(field => data?.[field]).map(field => data?.[field]).join(" - ");
  }

  isOptionEqual(data1: T, data2: T) {
    return data1[this.valueField] === data2[this.valueField];
  }

  onBlur() {
    if (this.suggestData.suggests.length === 0 && this.suggestData.perfectMatch === null)
      this.clear();
  }

  clearIfNeeded = () => {
    if (this.suggestData.suggests.length === 0 && this.suggestData.perfectMatch === null) return;
    this.clear();
  }

  private clear() {
    if (this.control) {
      if (typeof this.control.value !== 'string') return;
      this.control.patchValue(null);
    } else {
      if (typeof this.value !== 'string') return;
      this.value = null;
    }

    this.valueChange.emit(null);
  }

  reload() {
    let key = this.value?.[this.valueField] as string ?? "";
    this.suggestData.reload(key);
    this.keyChange.emit(key);
  }
}
