<app-scene header="User" [loading]="loading">
  <div [formGroup]="formGroup" class="max-w-2xl">
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Username</mat-label>
        <input matInput formControlName="username" id="username" name="username">
      </mat-form-field>
      <div class="flex-1"></div>
    </div>
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Full name</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email">
        <div *ngIf="formControls.email.errors"
              class="text-warn-red col-span-2">
              <div *ngIf="formControls.email.errors.email">
                  Wrong email format.
              </div>
              <div *ngIf="formControls.email.errors.required">
                Email is required.
              </div>
          </div>
      </mat-form-field>
    </div>
    <div class="flex space-x-5" *ngIf="currentUser?.role === 'Admin'">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Role</mat-label>
        <mat-select formControlName="role">
          <mat-option *ngFor="let role of roles" [value]="role.value">
            {{role.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="flex-1">
        <mat-checkbox formControlName="activeStatus">
          Active
        </mat-checkbox>
      </div>
    </div>
    <mat-checkbox *ngIf="userId !== 0" formControlName="changePassword" class="mb-5"  (change)="onCheckboxChange($event)"  >
      Change Password
    </mat-checkbox>
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" *ngIf="userId === 0 || changePass" class="flex-1">
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" type="password" id="password" name="password" [disabled]="!changePass">
      </mat-form-field>
      <div class="flex-1">
      </div>
    </div>
  </div>
  <div class="float-right" footer>
    <button mat-button (click)="close()" class="mr-5 cancel-btn">Close</button>
    <button mat-button (click)="save()" class="confirm-btn">Save</button>
  </div>
</app-scene>
