import {HttpClient} from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {LocationDetailPopup} from './popup';
import {RadiusOverlay} from './radius-overlay';
import {ScaleControl} from './scale-control';
import {SearchControl} from './search-control';
import {ZoomControls} from './zoom-controls';
import {Location} from '../location/location'

const MARKER_LOCATION = {
  url: './assets/images/pickupLocationMarker.png',
  zoom: 2,
  anchor: new google.maps.Point(15, 35),
};

@Component({
  selector: 'gt-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss'],
})
export class LocationMapComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() selectable: boolean = false;
  @Input() location!: Location;
  @Input() showDetailLocation: boolean = false;
  @Input() latitude!: number | any;
  @Input() longitude!: number | any;
  @Input() radius!: number | any;
  @Input() canSearch: boolean = true;
  @Input() canExpand: boolean = true;
  @Input() canZoom: boolean = true;
  @Output() onChangeLatLng: EventEmitter<any> = new EventEmitter();
  @ViewChild('googleMap', { static: false }) googleMap!: ElementRef;
  map!: google.maps.Map;
  mapOptions!: google.maps.MapOptions;
  geocodingService!: google.maps.Geocoder;
  marker!: null | google.maps.Marker;
  radiusOverlay!: null | RadiusOverlay;
  popup!: LocationDetailPopup;

  constructor(private _http: HttpClient) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.initMap();
    this.initControls();
    if (this.latitude && this.longitude) {
      this.initMarker();
    }
    if (this.radius) {
      this.initOverlay();
    }
  }

  ngOnChanges(changes: any) {
    if (this.map) {
      if (changes.location && changes.location.previousValue != changes.location.currentValue) {
        this.updateMap();
        if (this.location) {
          if (this.marker) {
            this.updateMarker();
          } else {
            this.initMarker();
          }
        } else {
          this.removeMarker();
          this.removePopup();
        }
      }
      if (changes.latitude || changes.longitude) {
        this.latLngUpdated();
      }
      if (changes.radius) {
        this.radiusUpdated();
      }
    }
  }

  initMap() {
    this.mapOptions = {
      center: this.latitude && this.longitude ? new google.maps.LatLng(this.latitude, this.longitude) : new google.maps.LatLng(1.3353221349758035, 103.9064757929378),
      zoom: 12,
      disableDefaultUI: true,
    };
    this.map = new google.maps.Map(this.googleMap.nativeElement, this.mapOptions);
    this.map.addListener('click', this.onClickMap.bind(this));
    // this.map.addListener('zoom_changed', this.updateMarker.bind(this));
  }

  initControls() {
    if (this.canZoom) {
      const scaleControl = new ScaleControl({ map: this.map });
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(scaleControl.html);
    }

    if (this.canSearch) {
      const searchControl = new SearchControl({ map: this.map, onSubmit: this.onSearch.bind(this), placeholder: 'location.search-here' });
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(searchControl.html);
    }

    if (this.canExpand) {
      const zoomControls = new ZoomControls({ map: this.map, position: this.showDetailLocation ? google.maps.ControlPosition.TOP_RIGHT : google.maps.ControlPosition.RIGHT_BOTTOM });
      this.map.controls[this.showDetailLocation ? google.maps.ControlPosition.TOP_RIGHT : google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControls.html);
    }
  }

  initMarker() {
    if (!this.marker) {
      this.marker = new google.maps.Marker({
        icon: MARKER_LOCATION,
        position: new google.maps.LatLng(this.latitude, this.longitude),
        visible: true,
        map: this.map
      });
      this.initPopup();
    } else {
      this.updateMarker();
    }
  }

  initOverlay() {
    if (!this.radiusOverlay) {
      const bounds = this.toBounds(new google.maps.LatLng(this.latitude, this.longitude), this.radius);
      this.radiusOverlay = new RadiusOverlay(bounds);
      this.radiusOverlay.setMap(this.map);
    } else {
      this.updateOverlay();
    }
  }

  initPopup() {
    if (this.showDetailLocation && this.location) {
      const position: google.maps.LatLng = new google.maps.LatLng(this.location.latitude, this.location.longitude);
      const content = document.createElement('DIV');
      content.innerHTML = `
      <div class="section-form-row">
        <div class="section-form-label">location.latitude</div>
        <div class="section-form-value">${this.location.latitude.toFixed(4)}</div>
      </div>
      <div class="section-form-row">
        <div class="section-form-label">'location.longitude'</div>
        <div class="section-form-value">${this.location.longitude.toFixed(4)}</div>
      </div>
      <div class="section-form-row">
        <div class="section-form-label">'location.radius'</div>
        <div class="section-form-value">${this.location.radius} m</div>
      </div>
    `;
      const popup = new LocationDetailPopup(position, content);
      popup.setMap(this.map);
      this.popup = popup;
    }
  }

  removePopup() {
    if (this.popup) {
      this.popup.setMap(null);
    }
  }

  resetPopup() {
    this.removePopup();
    this.initPopup();
  }

  updateMap() {
    this.mapOptions = {
      center: this.location ? new google.maps.LatLng(this.location.latitude, this.location.longitude) : new google.maps.LatLng(1.3353221349758035, 103.9064757929378),
      zoom: this.map.getZoom(),
      disableDefaultUI: true,
    };
    this.map.setOptions(this.mapOptions);
  }

  updateMarker() {
    (this.marker as google.maps.Marker).setPosition(new google.maps.LatLng(this.latitude, this.longitude));
    this.resetPopup();
  }

  updateOverlay() {
    const bounds = this.toBounds(new google.maps.LatLng(this.latitude, this.longitude), this.radius);
    this.radiusOverlay?.updatePosition(bounds);
  }

  removeMarker() {
    (this.marker as google.maps.Marker).setMap(null);
    this.marker = null;
  }

  onClickMap(e: google.maps.MapMouseEvent) {
    if (this.selectable) {
      const latLng: google.maps.LatLngLiteral = e!.latLng?.toJSON() as any;
      this.onChangeLatLng.emit({ latitude: latLng.lat, longitude: latLng.lng });
    }
  }

  latLngUpdated() {
    if (this.latitude && this.longitude) {
      this.initMarker();
      this.initOverlay();
      this.map.panTo(new google.maps.LatLng(this.latitude, this.longitude));
    }
  }

  radiusUpdated() {
    if (this.radius) {
      this.initOverlay();
    }
  }

  toBounds(center: google.maps.LatLng, radiusInMeters: number): google.maps.LatLngBounds {
    const distanceFromCenterToCorner = radiusInMeters * Math.sqrt(2.0);
    const southwestCorner = google.maps.geometry.spherical.computeOffset(center, distanceFromCenterToCorner, 225.0);
    const northeastCorner = google.maps.geometry.spherical.computeOffset(center, distanceFromCenterToCorner, 45.0);
    return new google.maps.LatLngBounds(southwestCorner, northeastCorner);
  }

  onSearch(results: Array<google.maps.places.PlaceResult>) {
    const result: google.maps.places.PlaceResult = results[0];
    this.onChangeLatLng.emit({ latitude: result?.geometry?.location?.lat(), longitude: result?.geometry?.location?.lng() });
  }
}
