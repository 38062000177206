<app-scene header="Customer Summary Report" [loading]="loading">
  <div [formGroup]="formGroup" class="custom-form">
    <div class="flex space-x-5">
      <div class="flex-1">
        <mat-label class="font-bold">Sales Type</mat-label>
        <div class="flex space-x-5">
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <mat-select placeholder="Sales Type" formControlName="salesType">
              <mat-option *ngFor="let type of SALES_TYPE" [value]="type.value">
                {{ type.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="flex-1">
        <mat-label class="font-bold">Do Date</mat-label>
        <div class="flex space-x-5">
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <input placeholder="From" matInput formControlName="fromDate" [matDatepicker]="pickerDateRangeFrom">
            <mat-datepicker-toggle matIconSuffix [for]="pickerDateRangeFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerDateRangeFrom></mat-datepicker>
          </mat-form-field>
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <input placeholder="To" matInput formControlName="toDate" [matDatepicker]="pickerDateRangeTo">
            <mat-datepicker-toggle matIconSuffix [for]="pickerDateRangeTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerDateRangeTo></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="flex space-x-5">
      <div class="flex-1">
        <mat-label class="font-bold">Customer Code</mat-label>
        <div class="flex space-x-5">
          <app-suggest-input class="flex-1"
                             placeholder="From"
                             [control]="formGroup.get('fromCustomerCode')"
                             [valueField]="'code'"
                             [nameField]="['code']"
                             [suggestData]="customerCodeSuggestData"
          >
          </app-suggest-input>
          <app-suggest-input class="flex-1"
                             placeholder="To"
                             [control]="formGroup.get('toCustomerCode')"
                             [valueField]="'code'"
                             [nameField]="['code']"
                             [suggestData]="customerCodeSuggestData"
          >
          </app-suggest-input>
        </div>
      </div>
      <div class="flex-1">
        <mat-label class="font-bold">Location Code</mat-label>
        <div class="flex space-x-5">
          <app-suggest-input placeholder="From" class="flex-1"
                             [control]="formGroup.get('fromLocationCode')"
                             [valueField]="'locationCode'"
                             [nameField]="['locationCode']"
                             [suggestData]="locationSuggestData"
          >
          </app-suggest-input>
          <app-suggest-input placeholder="To" class="flex-1"
                             [control]="formGroup.get('toLocationCode')"
                             [valueField]="'locationCode'"
                             [nameField]="['locationCode']"
                             [suggestData]="locationSuggestData"
          >
          </app-suggest-input>
        </div>
      </div>
    </div>
    <div class="flex space-x-5">
      <div class="flex-1">
        <mat-label class="font-bold">Product Code</mat-label>
        <div class="flex space-x-5">
          <app-suggest-input class="flex-1"
                             placeholder="From"
                             [control]="formGroup.get('fromProductCode')"
                             [valueField]="'code'"
                             [nameField]="['code']"
                             [suggestData]="productSuggestData"
          >
          </app-suggest-input>
          <app-suggest-input  class="flex-1"
                              placeholder="To"
                              [control]="formGroup.get('toProductCode')"
                              [valueField]="'code'"
                              [nameField]="['code']"
                              [suggestData]="productSuggestData"
          >
          </app-suggest-input>
        </div>
      </div>
      <div class="flex-1"></div>
    </div>
    <div>
      <button mat-button (click)="generateReport()" class="confirm-btn mr-5">
        <mat-icon>receipt</mat-icon>
        Generate Report
      </button>
      <button mat-button (click)="clearInput()" class="mr-5 cancel-btn">Clear</button>
    </div>

    <div class="mt-4 flex">
      <div class="flex-1">
        <mat-label class="font-bold mr-4">Sort By</mat-label>
        <mat-form-field [floatLabel]="'always'">
          <mat-select placeholder="Sort By" formControlName="sortBy">
            <mat-option *ngFor="let sort of getSortList()" [value]="sort">
              {{ sort }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex-1"></div>
    </div>
    <table mat-table [dataSource]="reportData">
      <tr mat-header-row *matHeaderRowDef="reportDisplayedColumns"></tr>
      <tr mat-header-row *matFooterRowDef="reportDisplayedTotalColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: reportDisplayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="reportDisplayedColumns.length">
          No data matching the filter.
        </td>
      </tr>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{ element.date | date : 'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef>Customer Name</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{ element.customerName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="locationName">
        <th mat-header-cell *matHeaderCellDef>Location Name</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{ element.locationName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="productName">
        <th mat-header-cell *matHeaderCellDef>Product Name</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{ element.productName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalQty">
        <th mat-header-cell *matHeaderCellDef>Total Qty</th>
        <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
          {{ element.totalQty | number : '1.2-2' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalLoad">
        <th mat-header-cell *matHeaderCellDef>Total Load</th>
        <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
          {{ element.totalLoad }}
        </td>
      </ng-container>

      <ng-container matColumnDef="emptyFooter">
        <td *matFooterCellDef class="bg-gray-500" [hidden]="!this.reportData || !this.reportData.length"></td>
      </ng-container>

      <ng-container matColumnDef="granTotalQtyLabel">
        <td *matFooterCellDef class="text-left bg-gray-500 text-white px-4"
            [hidden]="!this.reportData || !this.reportData.length">Grand Total QTY:
        </td>
      </ng-container>

      <ng-container matColumnDef="totalAmountQty">
        <td *matFooterCellDef class="text-center bg-gray-500 text-white px-4"
            [hidden]="!this.reportData || !this.reportData.length">{{ getTotalAmountQty() | number : '1.2-2' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalAmountLoad">
        <td *matFooterCellDef class="text-center bg-gray-500 text-white px-4"
            [hidden]="!this.reportData || !this.reportData.length">{{ getTotalAmountLoad() | number : '1.2-2' }}
        </td>
      </ng-container>

    </table>
  </div>

  <div class="float-right" footer *ngIf="this.reportData && this.reportData.length > 0">
    <button mat-button (click)="downloadReportExcel()" class="mr-5 outline-btn">
      <mat-icon>save_alt</mat-icon>
      Download Excel
    </button>
    <button mat-button (click)="downloadReportPdf()" class="mr-5 outline-btn">
      <mat-icon>save_alt</mat-icon>
      Download PDF
    </button>
    <button mat-button (click)="printReport()" class="confirm-btn">
      <mat-icon>local_printshop</mat-icon>
      Print Report
    </button>
  </div>
</app-scene>

