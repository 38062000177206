import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Price, ProductPrice, TransportPrice } from './price';
import {PagingRequest, PagingResult} from "../../common-component/pageable-datatable/pageable";
import { handleError } from "../../common/http-request";
import { User } from "../user/user";
import { throwError } from "rxjs";

@Injectable()
export class PriceService {
  base = environment.API_ENDPOINT + '/price/';
  productPriceBase = this.base + 'product-price/';
  transportPriceBase = this.base + 'transport-price/';

  constructor(private http: HttpClient) {}
  searchPrices(request: PagingRequest<Price>, search: any) {
    Object.keys(search).forEach(key => {
      if (search[key] === null) {
        delete search[key];
      }
    });
    return this.http
      .get<PagingResult<Price>>(this.base + 'page', {
        params: {...request, ...search}
      })
      .pipe(catchError(handleError));
  }

  savePrice(id: number, isRevise: boolean, price: any, productPrices: ProductPrice[], transportPrices: TransportPrice[]) {
    const data = {
      ...price,
      priceTermId: price.terms.id,
      customer: null,
      customerId: price.customerCode.id,
      productPrices: productPrices.filter(productPrice => productPrice.product && productPrice.product.id).map(productPrice => ({
        id: productPrice.id,
        price: productPrice.price,
        uomId: productPrice.product!.uom?.id,
        productId: productPrice.product!.id
      })),
      transportPrices: transportPrices.filter(transportPrice => transportPrice.location && transportPrice.location.id).map(transportPrice => ({
        id: transportPrice.id,
        transportPricePerLoad: transportPrice.transportPricePerLoad,
        transportPricePerTon: transportPrice.transportPricePerTon,
        locationId: transportPrice.location!.id
      }))
    }
    const apiAction = id === 0 ? 'create' : isRevise ? 'revise/' + id : 'update/' + id;
    if(id === 0) {
      return this.http
        .post<any>(this.base + apiAction, data)
        .pipe(catchError(handleError));
    }
    return this.http
      .put<any>(this.base + apiAction, data)
      .pipe(catchError(handleError));
  }

  deletePrice(param: User) {
    if (param.id) {
      return this.http
        .delete<any>(this.base + 'delete/' + param.id)
        .pipe(catchError(handleError));
    } else {
      return throwError('Invalid Param. Id not exist.');
    }
  }

  getPrice(id: number) {
    return this.http
      .get<Price>(this.base + id)
      .pipe(catchError(handleError));
  }

  getProductPrices(priceId: number) {
    return this.http
      .get<ProductPrice[]>(this.productPriceBase + 'list', {
        params: {priceId}
      })
      .pipe(catchError(handleError));
  }

  deleteProductPrice(param: ProductPrice) {
    if (param.id) {
      return this.http
        .delete<any>(this.productPriceBase + 'delete/' + param.id)
        .pipe(catchError(handleError));
    } else {
      return throwError('Invalid Param. Id not exist.');
    }
  }

  getTransportPrices(priceId: number) {
    return this.http
      .get<TransportPrice[]>(this.transportPriceBase + 'list', {
        params: {priceId}
      })
      .pipe(catchError(handleError));
  }

  deleteTransportPrice(param: TransportPrice) {
    if (param.id) {
      return this.http
        .delete<any>(this.transportPriceBase + 'delete/' + param.id)
        .pipe(catchError(handleError));
    } else {
      return throwError('Invalid Param. Id not exist.');
    }
  }
}
