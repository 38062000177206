<app-scene header="Generate Invoice" [loading]="loading">
  <div [formGroup]="formGroup" class="max-w-2xl custom-form">
    <div>
      <mat-label class="font-bold">DO Date</mat-label>
      <div class="flex space-x-5">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input placeholder="From" matInput formControlName="fromDate" [matDatepicker]="pickerDODateFrom">
          <mat-datepicker-toggle matIconSuffix [for]="pickerDODateFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerDODateFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input placeholder="To" matInput formControlName="toDate" [matDatepicker]="pickerDODateTo">
          <mat-datepicker-toggle matIconSuffix [for]="pickerDODateTo"></mat-datepicker-toggle>
          <mat-datepicker #pickerDODateTo></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-label class="font-bold">Customer Code</mat-label>
      <div class="flex space-x-5">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input placeholder="From" matInput formControlName="fromCustomerCode">
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input placeholder="To" matInput formControlName="toCustomerCode">
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-label class="font-bold">Product Code</mat-label>
      <div class="flex space-x-5">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input placeholder="From" matInput formControlName="fromProductCode">
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input placeholder="To" matInput formControlName="toProductCode">
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-label class="font-bold">Location Code</mat-label>
      <div class="flex space-x-5">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input placeholder="From" matInput formControlName="fromLocationCode">
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input placeholder="To" matInput formControlName="toLocationCode">
        </mat-form-field>
      </div>
    </div>
    <div>
      <button mat-button (click)="generateInvoicing()" class="confirm-btn mr-5"><mat-icon>receipt</mat-icon> Generate Invoicing</button>
      <button mat-button (click)="printPreview()" class="outline-btn mr-5">
        <span class="flex items-center">
          <span class="relative">
            <mat-icon>local_printshop</mat-icon>
            <mat-icon class="absolute left-[10px] top-[10px] !w-[14px] !h-[14px] text-[14px] rounded-[50%] bg-white">search</mat-icon>
          </span>
          <span>
            Print Preview
          </span>
        </span>
      </button>
    </div>
  </div>
</app-scene>
