<div class="p-8">
  <div class="flex">
    <div class="flex-1">
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        DO No.
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        DO Date
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        Customer Code
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        Customer Name
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        Location Code
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        Location Name
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        Product
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        Vehicle No
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        Transporter
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border-t border-x border-black">
        DO Quantity (MT)
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] bg-gray-500 text-white border border-black">
        Invoice No
      </div>
    </div>


    <div class="flex-1">
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{searchDo.doNo}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{searchDo.doDate | date : 'dd-MM-yyy'}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{searchDo.customerCode}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{searchDo.customerName}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{searchDo.locationCode}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{searchDo.locationName}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{searchDo.productName}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{searchDo.vehicleNo}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{searchDo.transporterOrgName}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-t border-r border-black">
        {{searchDo.weightNet | number : '1.2-2'}}
      </div>
      <div class="flex items-center flex-1 p-2 h-[37px] border-y border-r border-black">
        {{searchDo.invoiceNo}}
      </div>
    </div>
  </div>
  <div class="mt-2 flex w-full justify-end">
    <button class="cancel-btn" mat-button mat-dialog-close>Close</button>
  </div>
</div>
