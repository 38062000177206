import { Component } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DailyTransactionReportService } from "./daily-transaction-report-service";
import { DailyTransactionReportRecord } from "./daily-transaction-report-record";
import { ReportAbstractComponent } from "../report-abstract.component";
import {CustomerService} from "../../customer/customer-service";
import {ProductService} from "../../product/product-service";
import {LocationService} from "../../location/location-service";
import {of, Subject} from "rxjs";
import {catchError, map, switchMap} from "rxjs/operators";
import {Location} from "../../location/location";
import {Product} from "../../product/product";

@Component({
  selector: 'app-daily-transaction-report',
  templateUrl: './daily-transaction-report.component.html',
  styleUrls: ['./daily-transaction-report.component.css'],
  providers: [DailyTransactionReportService, CustomerService, ProductService, LocationService]
})
export class DailyTransactionReportComponent extends ReportAbstractComponent<DailyTransactionReportRecord[]> {


  reportDisplayedColumns = [
    "sNo",
    "productName",
    "doNo",
    "dateTime",
    "vehicleNo",
    "customerName",
    "locationName",
    "transporterName",
    "qty",
  ]
  reportDisplayedTotalColumns = [
    'emptyFooter',
    'emptyFooter',
    'emptyFooter',
    'emptyFooter',
    'emptyFooter',
    'emptyFooter',
    'emptyFooter',
    'granTotalQtyLabel',
    'totalAmountQty',
  ];

  constructor(private _formBuilder: FormBuilder,
              private _snackBar: MatSnackBar,
              private dailyTransactionReportService: DailyTransactionReportService,
              customerService: CustomerService,
              productService: ProductService,
              locationService: LocationService
  ) {
    super(_formBuilder, _snackBar, dailyTransactionReportService, customerService, productService, locationService);
  }

  getTotalAmountQty(): number {
    if (!this.reportData || !this.reportData.length) return 0;
    return this.reportData.reduce((store, current) => store + current.qty, 0);
  }

  getTotalAmount(): number {
    if (!this.reportData || !this.reportData.length) return 0;
    return this.reportData.reduce((store, current) => store + current.amount, 0);
  }

  getTotalGST(): number {
    if (!this.reportData || !this.reportData.length) return 0;
    return this.reportData.reduce((store, current) => store + current.gst, 0);
  }

  getTotalNetAmount(): number {
    if (!this.reportData || !this.reportData.length) return 0;
    return this.reportData.reduce((store, current) => store + current.netAmount, 0);
  }

  getFieldList(): { fieldName: string; validators?: any }[] {
    return [
      {fieldName: "fromDate", validators: [Validators.required]},
      {fieldName: "toDate", validators: [Validators.required]},
      {fieldName: "fromCustomerCode"},
      {fieldName: "toCustomerCode"},
      {fieldName: "fromProductCode"},
      {fieldName: "toProductCode"},
      {fieldName: "fromLocationCode"},
      {fieldName: "toLocationCode"},
      {fieldName: "transporterName"},
      {fieldName: "vehicleNo"},
    ];
  }

  getSortDefault(): number {
    return 1;
  }

  getSortList(): string[] {
    return [
      "Product Name",
      "Do No",
      "Date Time",
      "Vehicle No",
      "Customer Name",
      "Location Name",
      "Transporter Name",
      "Qty",
    ];
  }

  changeReportDisplayedColumns() {
    debugger
    if(this.formGroup.controls['salesType'].value !== '0') {
      this.reportDisplayedColumns = [
        "sNo",
        "productName",
        "doNo",
        "dateTime",
        "vehicleNo",
        "customerName",
        "locationName",
        "transporterName",
        "qty",
        "amount",
        "gst",
        "netAmount"
      ];
    } else {
      this.reportDisplayedColumns = [
        "sNo",
        "productName",
        "doNo",
        "dateTime",
        "vehicleNo",
        "customerName",
        "locationName",
        "transporterName",
        "qty",
      ];
    }
  }

  changeReportDisplayedTotalColumns() {
    if(this.formGroup.controls['salesType'].value !== '0') {
      this.reportDisplayedTotalColumns = [
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'granTotalQtyLabel',
        'totalAmountQty',
        'totalAmount',
        'totalGST',
        'totalNetAmount',
      ];
    } else {
      this.reportDisplayedTotalColumns = [
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'granTotalQtyLabel',
        'totalAmountQty',
      ];
    }
  }

  customerKeyword = new Subject<{ name?: string, code?: string }>();
  suggestCustomers = this.customerKeyword.pipe(
    switchMap(key => this.customerService
      .searchCustomers({page: 0, size: 5, sortBy: key.name ? 'name' : key.code ? 'code' : 'id'}, key)
    ),
    map(pagingResult => pagingResult.content)
  );

  perfectMatchCustomer = this.customerKeyword.pipe(
    switchMap(key => this.customerService
      .findCustomerByCode(key)
    )
  );

  perfectMatchProduct: Product | null = null;
  suggestProducts: Product[] = [];

  reloadSuggestProducts = (code: string) => {
    this.perfectMatchProduct = null;
    this.suggestProducts = [];
    this.productService.findProductByCode(code).pipe(catchError(() => of(null))).subscribe(value => {
      this.perfectMatchProduct = value;
    })
    this.productService.pageProducts({page: 0, size: 5, sortBy: 'code'}, code, false, true)
      .subscribe(value => this.suggestProducts = value.content)
  };

  perfectMatchLocation: Location | null = null;
  suggestLocations: Location[] = [];

  reloadSuggestLocations = (code: string) => {
    this.perfectMatchLocation = null;
    this.suggestLocations = [];
    this.locationService.findLocationByCode(code).pipe(catchError(() => of(null))).subscribe(value => {
      this.perfectMatchLocation = value;
    })
    this.locationService.pageLocations({page: 0, size: 5, sortBy: 'locationCode'}, code).subscribe(value => {
      this.suggestLocations = value.content;
    })
  };

}
