import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DoModification } from './do-modification';
import { PagingRequest, PagingResult } from "../../common-component/pageable-datatable/pageable";
import { handleError } from "../../common/http-request";

@Injectable()
export class DoModificationService {
  base: string = environment.API_ENDPOINT + '/do-modification/';

  constructor(private http: HttpClient) {
  }

  searchDoModifications(request: PagingRequest<DoModification>, doNo: string) {
    return this.http
      .get<PagingResult<DoModification>>(this.base + 'page', {
        params: {...request, doNo}
      })
      .pipe(catchError(handleError));
  }

  saveDoModification(id: number, params: any) {
    const req = {
      ...params
    }
    req.newProduct = req.newProduct?.id;
    req.newCustomerSite = req.newCustomerSite?.id;
    req.newDoDate = req.newDoDate ? req.newDoDate.format('DD-MM-YYYY') : undefined;
    if (id === 0) {
      return this.http
        .post<any>(this.base + 'create', req)
        .pipe(catchError(handleError));
    }
    return this.http
      .put<any>(this.base + 'update/' + id, req)
      .pipe(catchError(handleError));
  }

  getDoModificationByDoNo(doNo: string) {
    return this.http
      .get<DoModification>(this.base + "filter", {params: {doNo}})
      .pipe(catchError(handleError));
  }
}
