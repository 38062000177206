<app-scene header="Customer" [loading]="loading">
  <div [formGroup]="formGroup" class="max-w-2xl custom-form">
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" (blur)="transformToUppercase('name')">
      </mat-form-field>
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Code</mat-label>
        <input matInput formControlName="code">
      </mat-form-field>
    </div>
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Type</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let type of types" [value]="type">
            {{type}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox formControlName="activeStatus" class="flex-1">
        Active
      </mat-checkbox>
    </div>
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Address 1</mat-label>
        <input matInput formControlName="address1">
      </mat-form-field>
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Address 2</mat-label>
        <input matInput formControlName="address2">
      </mat-form-field>
    </div>
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Address 3</mat-label>
        <input matInput formControlName="address3">
      </mat-form-field>
      <div class="flex-1"></div>
    </div>
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Telephone</mat-label>
        <input matInput formControlName="telephone">
      </mat-form-field>
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Fax</mat-label>
        <input matInput formControlName="fax">
      </mat-form-field>
    </div>
  </div>
  <div class="float-right" footer>
    <button mat-button (click)="close()" class="mr-5 cancel-btn">Close</button>
    <button mat-button (click)="save()" class="confirm-btn">Save</button>
  </div>
</app-scene>
