import { HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";

export function parseErrorBlob(err: HttpErrorResponse): Observable<any> {
  const reader: FileReader = new FileReader();
  const obs = Observable.create((observer: any) => {
    reader.onloadend = () => {
      observer.error({
        status: err.status,
        error: reader.result
      });
      observer.complete();
    }
  });
  reader.readAsText(err.error);
  return obs;
}

export function handleCommonFile(blob: Blob, filename: string, showInNewTab: boolean = false) {
  const a = document.createElement("a");
  a.setAttribute("href", URL.createObjectURL(blob));
  !showInNewTab && a.setAttribute("download", filename);
  showInNewTab && (a.target = '_blank');
  a.click();
  URL.revokeObjectURL(a.getAttribute("href") as string);
}

export function parseDate(date: string | number | Date, defaultValue: any = null) {
  return date ? new Date(date) : (defaultValue ? defaultValue : null);
}
