<app-scene header="Price Listing" [loading]="loading">
  <div [formGroup]="formGroup" class="max-w-2xl custom-form">
    <div class="flex space-x-5">
      <app-suggest-input class="flex-1"
                         label="Customer Name"
                         [control]="formGroup.get('customerName')"
                         (valueChange)="updateCustomer($event)"
                         [valueField]="'name'"
                         [nameField]="['name']"
                         [optionField]="['name', 'code']"
                         [suggestData]="customerNameSuggestData"
      >
      </app-suggest-input>
      <app-suggest-input class="flex-1"
                         label="Customer Code"
                         [control]="formGroup.get('customerCode')"
                         (valueChange)="updateCustomer($event)"
                         [valueField]="'code'"
                         [nameField]="['code']"
                         [optionField]="['code', 'name']"
                         [suggestData]="customerCodeSuggestData"
      >
      </app-suggest-input>
    </div>
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Customer Type</mat-label>
        <mat-select formControlName="customerType">
          <mat-option *ngFor="let type of types" [value]="type">
            {{type}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="flex-1"></div>
    </div>
    <div class="">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Customer Address</mat-label>
        <textarea matInput formControlName="customerAddress"
               [matAutocomplete]="autoAddress"
        ></textarea>
        <mat-autocomplete #autoAddress="matAutocomplete">
          <mat-option *ngFor="let address of suggestAddresses | async" [value]="address">
            {{address}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="relative">
      <div class="absolute right-0 -translate-y-4">
        <mat-checkbox class="" formControlName="sameAddress">
          Same as Customer Address
        </mat-checkbox>
      </div>
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Mailing Address</mat-label>
        <textarea matInput formControlName="customerMailingAddress"
                  [matAutocomplete]="autoAddress"></textarea>
        <mat-autocomplete #autoAddress="matAutocomplete">
          <mat-option *ngFor="let address of suggestAddresses | async" [value]="address">
            {{address}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Quotation Ref.</mat-label>
        <input matInput formControlName="quotationRef">
      </mat-form-field>
      <div class="flex-1"></div>
    </div>
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Contract Start Date</mat-label>
        <input matInput formControlName="contractStartDate" [matDatepicker]="pickerStartDate">
        <mat-datepicker-toggle matIconSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerStartDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Contract End Date</mat-label>
        <input matInput formControlName="contractEndDate" [matDatepicker]="pickerEndDate">
        <mat-datepicker-toggle matIconSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerEndDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="flex space-x-5">
      <app-suggest-input class="flex-1"
                         label="Term"
                         [control]="formGroup.get('terms')"
                         [valueField]="'label'"
                         [nameField]="['label']"
                         [suggestData]="priceTermSuggestData"
      >
      </app-suggest-input>
      <div class="flex-1"></div>
    </div>
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Remarks</mat-label>
        <textarea matInput formControlName="remarks"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="flex-1 price-template bg-white px-5" right>
    <div>
      <h2 class="px-5 pt-5">Products</h2>
      <div class="mb-3 flex space-x-5 items-baseline">
        <app-suggest-input class="flex-1 no-subscript"
                           [(value)]="productTemplateSuggestData.value"
                           placeholder="Search product template"
                           [valueField]="'name'"
                           [nameField]="['name']"
                           [suggestData]="productTemplateSuggestData"
        >
        </app-suggest-input>
        <button mat-button class="confirm-btn"
                [disabled]="productTemplateSuggestData.value === null"
                (click)="loadProductTemplate()">
          Apply template
        </button>
      </div>
      <table mat-table [dataSource]="productPrices">
        <tr mat-header-row *matHeaderRowDef="productDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: productDisplayedColumns;"></tr>

        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>Product Code</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <ng-container *ngIf="castProductPrice(element) as productPrice">
              <app-suggest-input [(value)]="productPrice.product"
                                 [valueField]="'code'"
                                 [nameField]="['code']"
                                 [suggestData]="productSuggestData"
              >
              </app-suggest-input>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Product Description</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <mat-form-field [floatLabel]="'always'" class="flex-1">
              <input matInput class="rounded-sm" [value]="element.product?.description || ''" disabled/>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="uom">
          <th mat-header-cell *matHeaderCellDef>UOM</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <ng-container *ngIf="castProductPrice(element) as productPrice">
              <app-suggest-input [(value)]="productPrice.product!.uom"
                                 [valueField]="'uomCode'"
                                 [nameField]="['uomCode']"
                                 [suggestData]="uomSuggestData"
              >
              </app-suggest-input>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef>Price</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <mat-form-field [floatLabel]="'always'" class="flex-1">
              <span matTextPrefix>$</span>
              <input matInput class="rounded-sm" [(ngModel)]="element.price"/>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <button mat-icon-button (click)="deleteProductPrice(element, i)">
          <span
            class="material-symbols-outlined text-alert">
              delete
          </span>
            </button>
          </td>
        </ng-container>
      </table>
      <button class="text-btn" (click)="addProductRow()">
        <span class="material-symbols-outlined">add</span>
        <span>Add new row</span>
      </button>
    </div>
    <div *ngIf="!this.selectedCustomer.value?.name?.includes('(SELF-COLLECT)')">
      <h2 class="px-5 pt-5">Location Transport Price</h2>
      <div class="mb-3 flex space-x-5 items-baseline">
        <app-suggest-input class="flex-1 no-subscript"
                           [(value)]="locationTemplateSuggestData.value"
                           placeholder="Search transport template"
                           [valueField]="'name'"
                           [nameField]="['name']"
                           [suggestData]="locationTemplateSuggestData"
        >
        </app-suggest-input>
        <button mat-button class="confirm-btn"
                [disabled]="locationTemplateSuggestData.value === null"
                (click)="loadTransportTemplate()">
          Apply template
        </button>
      </div>
      <table mat-table [dataSource]="transportPrices">
        <tr mat-header-row *matHeaderRowDef="transportDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: transportDisplayedColumns;"></tr>

        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>Location Code</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <ng-container *ngIf="castTransportPrice(element) as transportPrice">
              <app-suggest-input [(value)]="transportPrice.location"
                                 [valueField]="'locationCode'"
                                 [nameField]="['locationCode']"
                                 [optionField]="['locationCode', 'locationName']"
                                 [suggestData]="locationSuggestData"
              >
              </app-suggest-input>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Location Name</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <mat-form-field [floatLabel]="'always'" class="flex-1">
              <input matInput class="rounded-sm" [value]="element.location?.locationName || ''" disabled/>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef>Transport Price/Ton</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <mat-form-field [floatLabel]="'always'" class="flex-1">
              <span matTextPrefix>$</span>
              <input matInput class="rounded-sm" [(ngModel)]="element.transportPricePerTon"/>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="uom">
          <th mat-header-cell *matHeaderCellDef>Transport Price/Load</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <mat-form-field [floatLabel]="'always'" class="flex-1">
              <input matInput class="rounded-sm" [(ngModel)]="element.transportPricePerLoad"/>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <button mat-icon-button (click)="deleteTransportPrice(element, i)">
          <span
            class="material-symbols-outlined text-alert">
              delete
          </span>
            </button>
          </td>
        </ng-container>
      </table>
      <button class="text-btn" (click)="addTransportRow()">
        <span class="material-symbols-outlined">add</span>
        <span>Add new row</span>
      </button>
    </div>
  </div>
  <div class="float-right" footer>
    <button mat-button (click)="close()" class="mr-5 cancel-btn">Close</button>
    <button mat-button (click)="save()" class="confirm-btn">Save</button>
  </div>
</app-scene>
