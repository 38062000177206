<app-scene [header]="'Product'" [loading]="loading">
  <table mat-table [dataSource]="products" class="mat-elevation-z8" matSort (matSortChange)="localSort($event)">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="code">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Code</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <mat-form-field floatLabel='auto' class="pt-2 pb-2 w-full">
          <input matInput class="rounded-sm" [(ngModel)]="element.code" />
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <mat-form-field floatLabel='auto' class="pt-2 pb-2 w-full">
          <input matInput class="rounded-sm" [(ngModel)]="element.description" />
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="uom">
      <th mat-header-cell *matHeaderCellDef>UOM</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <app-suggest-input [(value)]="element.uom"
                           [valueField]="'uomCode'"
                           [nameField]="['uomCode']"
                           [suggestData]="uomSuggestData"
        >
        </app-suggest-input>
      </td>
    </ng-container>

    <ng-container matColumnDef="activeStatus">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Active Status</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <mat-checkbox [(ngModel)]="element.activeStatus" class="flex-1">
          Active
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <button mat-icon-button (click)="deleteProduct(element, i)">
          <span
            class="material-symbols-outlined text-alert">
              delete
          </span>
        </button>
      </td>
    </ng-container>
  </table>

  <button class="text-btn" (click)="addRow()">
    <span class="material-symbols-outlined">add</span>
    <span>Add new row</span>
  </button>

  <div class="float-right" footer>
    <button mat-button (click)="save()" class="confirm-btn">Save</button>
  </div>
</app-scene>
