import {TextFieldModule} from '@angular/cdk/text-field';
import {NgModule} from '@angular/core';
import {MatSortModule} from '@angular/material/sort';

import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {A11yModule} from '@angular/cdk/a11y';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkTableModule} from '@angular/cdk/table';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatButtonModule} from '@angular/material/button';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatRippleModule
} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './components/app/app.component';
import {SampleComponent} from './components/sample/sample.component';

import {ClipboardModule} from '@angular/cdk/clipboard';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTreeModule} from '@angular/cdk/tree';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {JWT_OPTIONS, JwtHelperService} from '@auth0/angular-jwt';
import {AvatarModule} from 'ngx-avatar';
import {CommonComponentModule} from './common-component/common-component.module';
import {CustomerDetailComponent} from "./components/customer/customer-detail/customer-detail.component";
import {RouterModule} from "@angular/router";
import {CustomerService} from "./components/customer/customer-service";
import {CustomerComponent} from './components/customer/customer.component';
import {LoginComponent} from './components/login/login.component';
import {DialogModule} from "./common-component/dialog/dialog.module";
import {ProductService} from "./components/product/product-service";
import {ProductComponent} from './components/product/product.component';
import {TwoComponent} from './components/two/two.component';
import {UserDetailComponent} from './components/user/user-detail/user-detail.component';
import {UserService} from "./components/user/user-service";
import {UserComponent} from "./components/user/user.component";
import {LocationComponent} from './components/location/location.component';
import {ProductTemplateComponent} from './components/product-template/product-template.component';
import {
  ProductTemplateDetailComponent
} from './components/product-template/product-template-detail/product-template-detail.component';
import {LocationTemplateComponent} from "./components/location-template/location-template.component";
import {
  LocationTempDetailsComponent
} from "./components/location-template/location-temp-details/location-temp-details.component";
import {PriceComponent} from './components/price/price.component';
import {PriceDetailComponent} from './components/price/price-detail/price-detail.component';
import {UomComponent} from './components/uom/uom.component';

import {MiscTransactionComponent} from './components/misc-transaction/misc-transaction.component';
import {
  MiscTransactionDetailComponent
} from './components/misc-transaction/misc-transaction-detail/misc-transaction-detail.component';
import {MiscTransactionService} from "./components/misc-transaction/misc-transaction-service";
import {LocationDetailComponent} from './components/location/location-detail/location-detail.component';
import {LocationMapComponent} from "./components/location-map/location-map.component";

import {GenerateInvoicingComponent} from './components/invoice/generate-invoicing/generate-invoicing.component';
import {InvoiceService} from "./components/invoice/invoice-service";
import {
  CustomerSummaryReportComponent
} from './components/report/customer-summary-report/customer-summary-report.component';
import {
  DailyTransactionReportComponent
} from './components/report/daily-transaction-report/daily-transaction-report.component';
import {
  CustomerProductSummaryReportComponent
} from './components/report/customer-product-summary-report/customer-product-summary-report.component';
import {
  VehicleSummaryReportComponent
} from "./components/report/vehicle-summary-report/vehicle-summary-report.component";
import {
  ProductSummaryReportComponent
} from "./components/report/product-summary-report/product-summary-report.component";
import {
  CustomerDetailedReportComponent
} from "./components/report/customer-detailed-report/customer-detailed-report.component";
import {
  CustomerVehicleReportComponent
} from "./components/report/customer-vehicle-report/customer-vehicle-report.component";
import {InventoryReportComponent} from "./components/report/inventory-report/inventory-report.component";
import {SearchInvoiceComponent} from "./components/invoice/search-invoice/search-invoice.component";
import {UserEmulationInterceptor} from "./common/user-emulation-interceptor";
import {InvoiceDetailComponent} from './components/invoice/invoice-detail/invoice-detail.component';
import {SearchDoComponent} from './components/search-do/search-do.component';
import {DoModificationComponent} from "./components/do-modification/do-modification.component";
import {
  DoModificationDetailComponent
} from "./components/do-modification/do-modification-detail/do-modification-detail.component";
import {DoDetailComponent} from './components/search-do/do-detail/do-detail.component';
import {
  DoModificationPopupComponent
} from './components/search-do/do-modification-popup/do-modification-popup.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {ForgetPasswordComponent} from "./components/login/forget-password/forget-password.component";
import {ResetPasswordPage} from "./components/login/reset-password/reset-password.page";
import {GstDetailComponent} from "./components/gst/gst-detail/gst-detail.component";
import {GstComponent} from "./components/gst/gst.component";
import {PriceTermComponent} from "./components/price-term/price-term.component";

export const CUSTOM_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ResetPasswordPage,
    SampleComponent,
    TwoComponent,
    LocationComponent,
    PriceTermComponent,
    UserComponent,
    UserDetailComponent,
    CustomerComponent, CustomerDetailComponent,
    PriceComponent, PriceDetailComponent,
    ProductComponent,
    ProductTemplateComponent, ProductTemplateDetailComponent,
    CustomerComponent,
    CustomerDetailComponent,
    LocationTemplateComponent,
    LocationTempDetailsComponent,
    UomComponent,
    MiscTransactionComponent,
    MiscTransactionDetailComponent,
    MiscTransactionComponent,
    MiscTransactionDetailComponent,
    LocationDetailComponent,
    LocationMapComponent,
    GenerateInvoicingComponent,
    CustomerSummaryReportComponent,
    DailyTransactionReportComponent,
    CustomerProductSummaryReportComponent,
    VehicleSummaryReportComponent,
    ProductSummaryReportComponent,
    CustomerDetailedReportComponent,
    CustomerVehicleReportComponent,
    InventoryReportComponent,
    SearchInvoiceComponent,
    InvoiceDetailComponent,
    SearchDoComponent,
    DoModificationComponent,
    DoModificationDetailComponent,
    DoDetailComponent,
    DoModificationPopupComponent,
    GstComponent,
    GstDetailComponent,
  ],
  imports: [
    AvatarModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    TextFieldModule,
    MatSnackBarModule,

    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,

    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,

    MatAutocompleteModule,

    CommonComponentModule,
    DialogModule,
  ],
  providers: [
    {provide: JWT_OPTIONS, useValue: JWT_OPTIONS},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    JwtHelperService,
    UserService, CustomerService,
    ProductService, MiscTransactionService, InvoiceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserEmulationInterceptor,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_DATE_FORMAT
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
