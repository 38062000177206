import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SearchDoRecord } from "../search-do-record";

@Component({
  selector: 'app-do-detail',
  templateUrl: './do-detail.component.html',
  styleUrls: ['./do-detail.component.css']
})
export class DoDetailComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public searchDo: SearchDoRecord) {
  }
}
