import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {PagingRequest, PagingResult} from "../../common-component/pageable-datatable/pageable";
import { handleError } from "../../common/http-request";
import { throwError } from "rxjs";
import { MiscTransaction, MiscTransactionType } from "./misc-transaction";
import { MiscTransactionAttachment } from "./misc-transaction-attachment";

@Injectable()
export class MiscTransactionService {
  base = environment.API_ENDPOINT + '/misc-transaction/';

  constructor(private http: HttpClient) {}

  searchMiscTransactions(request: PagingRequest<MiscTransaction>, search: any) {
    if(search.type === "All") {
      delete search["type"];
    }
    Object.keys(search).forEach(key => {
      if (search[key] === null) {
        delete search[key];
      }
    });
    return this.http
      .get<PagingResult<MiscTransaction>>(this.base + 'page', {
        params: {...request, ...search} as any
      })
      .pipe(catchError(handleError));
  }

  saveMiscTransaction(id: number, miscTransaction: MiscTransaction, attachments: MiscTransactionAttachment[]) {
    const headers = new HttpHeaders();
    const params = new HttpParams();
    headers.set('Content-Type', '');
    headers.set('Accept', "multipart/form-data");
    if(miscTransaction.type !== MiscTransactionType[0]) {
      miscTransaction = {
        id: miscTransaction.id,
        type: miscTransaction.type,
        productCode: miscTransaction.productCode,
        transactionDate: miscTransaction.transactionDate,
        qty: miscTransaction.qty,
        remarks: miscTransaction.remarks
      }
    }
    const form: FormData = new FormData();
    for (let key in miscTransaction) {
      if((miscTransaction as any)[key] == undefined) continue;
      form.append(key, (miscTransaction as any)[key]);
    }
    let index = 0;
    attachments.forEach(attachment => {
      if(!attachment.id && attachment.file) {
        form.append(`attachments[${index}].file`, attachment.file, attachment.file.name)
      } else {
        for (let key in attachment) {
          if((attachment as any)[key] == undefined) continue;
          form.append(`attachments[${index}].` + key, (attachment as any)[key]);
        }
      }
      index ++;
    });
    if (id === 0) {
      return this.http
        .post<any>(this.base + 'create', form, {headers, params})
        .pipe(catchError(handleError));
    }
    return this.http
      .put<any>(this.base + 'update/' + id, form, {headers, params})
      .pipe(catchError(handleError));
  }

  deleteMiscTransaction(miscTransaction: MiscTransaction) {
    if (miscTransaction.id) {
      return this.http
        .delete<any>(this.base + 'delete/' + miscTransaction.id)
        .pipe(catchError(handleError));
    } else {
      return throwError('Invalid Param. Id not exist.');
    }
  }

  getMiscTransaction(id: number) {
    return this.http
      .get<MiscTransaction>(this.base + id)
      .pipe(catchError(handleError));
  }
}
