import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { handleError } from "../../common/http-request";
import { SearchDoRecord } from "./search-do-record";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";

@Injectable()
export class SearchDoService {
  base: string = environment.API_ENDPOINT + '/search-do/';

  public constructor(private http: HttpClient) {

  }

  getDoList(params: any) {
    return this
      .http
      .get<SearchDoRecord[]>(this.base + "list", {params})
      .pipe(catchError(handleError));
  }
}
