<div
  class="h-[90vh] flex flex-col justify-center items-center overflow-hidden">
  <div class="w-[90%] pt-5 pb-5">
    <div class="float-left">
      <h1 class="text-3xl font-bold">Sample</h1>
    </div>
  </div>
  <div class="w-[90%] space-y-10 mb-auto overflow-hidden">
    <div class="h-4/5">
      <mat-table [dataSource]="dataSource"
                 class="mat-elevation-z8 h-4/5">
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index;">
            {{ element.id > 0 ? element.id : ''}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sampleStr">
          <mat-header-cell *matHeaderCellDef>sample string</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index;">
            <mat-form-field floatLabel='auto'
                            class="pt-2 pb-2 w-full">
              <input matInput
                     class="rounded-sm"
                     [(ngModel)]="element.sampleStr"/>
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sampleBool">
          <mat-header-cell *matHeaderCellDef>sample bool</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index;">
            {{element.sampleBool}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sampleValue">
          <mat-header-cell *matHeaderCellDef>sample value
          </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index;">
            {{element.sampleValue}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sampleDate">
          <mat-header-cell *matHeaderCellDef>sample date</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index;">
            {{element.sampleDate | date: 'yyyy-MMM-dd'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="child">
          <mat-header-cell *matHeaderCellDef>child</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index;">
            {{element.child?.name}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="childListAsStr">
          <mat-header-cell *matHeaderCellDef>child list</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index;">
            {{element.childListAsStr}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actionCol">
          <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index;">
            <button mat-icon-button
                    *ngIf="element.id > 0"
                    (click)="deleteSample(element)">
                            <span
                              class="material-symbols-outlined text-alert">
                                delete
                            </span>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-card *ngIf="isLoading"
                style="display: flex; justify-content: center; align-items: center">
        <mat-progress-spinner
          color="primary"
          mode="indeterminate">
        </mat-progress-spinner>
      </mat-card>
    </div>
  </div>
  <div class="w-[90%] pt-5 pb-5">
    <div class="w-full float-left">
      <button mat-button color="primary" (click)="addNewRow()">
        <mat-icon>add</mat-icon>
        Add New Row
      </button>
    </div>
    <div class="float-right">
      <button mat-raised-button color="primary"
              (click)="saveSamples()">Save
      </button>
    </div>
  </div>
</div>
