<app-scene header="Product Template" [loading]="loading">
  <div [formGroup]="formGroup" class="max-w-2xl">
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <mat-checkbox formControlName="activeStatus" class="flex-1">
        Active
      </mat-checkbox>

    </div>
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
      <div class="flex-1"></div>
    </div>
  </div>
  <table mat-table [dataSource]="prices" class="mat-elevation-z8">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef>Product Code</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <app-suggest-input class="flex-1"
                           [(value)]="element.product"
                           [valueField]="'code'"
                           [nameField]="['code']"
                           [suggestData]="productSuggestData"
        >
        </app-suggest-input>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Product Description</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input matInput class="rounded-sm" [value]="element.product?.description || ''" disabled/>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="uom">
      <th mat-header-cell *matHeaderCellDef>UOM</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <app-suggest-input class="flex-1"
                           [(value)]="element.product.uom"
                           [valueField]="'uomCode'"
                           [nameField]="['uomCode']"
                           [suggestData]="uomSuggestData"
        >
        </app-suggest-input>
      </td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>Price</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <span matTextPrefix>$ </span>
          <input matInput class="rounded-sm" [(ngModel)]="element.price"/>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <button mat-icon-button (click)="deletePrice(element, i)">
          <span
            class="material-symbols-outlined text-alert">
              delete
          </span>
        </button>
      </td>
    </ng-container>
  </table>

  <button class="text-btn" (click)="addRow()">
    <span class="material-symbols-outlined">add</span>
    <span>Add new row</span>
  </button>

  <div class="float-right" footer>
    <button mat-button (click)="close()" class="mr-5 cancel-btn">Close</button>
    <button mat-button (click)="save()" class="confirm-btn">Save</button>
  </div>
</app-scene>
