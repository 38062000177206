import {AfterContentInit, Component, ContentChildren, OnInit, QueryList, ViewChild} from '@angular/core';
import {MatNoDataRow, MatTable} from "@angular/material/table";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {finalize, tap} from "rxjs/operators";
import {ReportAbstractService} from "./report-abstract-service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Customer, CustomerTypes} from "../customer/customer";
import {SuggestInputData} from "../../common-component/suggest-input/suggest-input";
import {forkJoin} from "rxjs";
import {CustomerService} from "../customer/customer-service";
import {ProductService} from "../product/product-service";
import {LocationService} from "../location/location-service";
import {Product} from "../product/product";
import {Location} from "../location/location";

@Component({
  template: ''
})
export abstract class ReportAbstractComponent<T> implements OnInit, AfterContentInit {
  @ViewChild(MatTable, {static: true}) table!: MatTable<T>;
  @ContentChildren(MatNoDataRow) noDataRows!: QueryList<MatNoDataRow>;
  SALES_TYPE = [
    {label: 'All', value: ''},
    {label: CustomerTypes[0], value: '1'},
    {label: CustomerTypes[1], value: '0'}
  ];
  loading = false;
  formGroup: FormGroup;
  reportData: T = [] as any;
  abstract reportDisplayedColumns: string[];
  abstract reportDisplayedTotalColumns: string[];

  protected constructor(formBuilder: FormBuilder,
                        private snackBar: MatSnackBar,
                        private reportAbstractService: ReportAbstractService<T>,
                        protected customerService: CustomerService,
                        protected productService: ProductService,
                        protected locationService: LocationService,
  ) {
    this.formGroup = formBuilder.group({
      salesType: new FormControl(''),
      sortBy: new FormControl(this.getSortList()[this.getSortDefault()]),
    });
    this.getFieldList().forEach(field => this.formGroup.addControl(field.fieldName, new FormControl(field.initValue ?? "", field.validators)));
    this.formGroup.controls["sortBy"].valueChanges.subscribe({
      next: (value) => {
        if (!value) return;
        const validateFields = this.getFieldList().filter(field => field.validators && field.validators.length);
        if (validateFields.find(field => !this.formGroup.controls[field.fieldName].value)) {
          return;
        }
        this.generateReport();
      }
    });
  }

  ngOnInit(): void {
    this.loading = true;
    forkJoin({
      customers: this.customerService.listCustomers().pipe(
        tap(r => this.customers = r)
      ),
      products: this.productService.listProducts().pipe(
        tap(r => this.products = r)
      ),
      locations: this.locationService.listLocations().pipe(
        tap(r => this.locations = r)
      ),
    }).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      error: (error) => {
        this.snackBar.open(error, 'Hide', {duration: 10000});
      },
    });
  }

  ngAfterContentInit() {
    if (this.noDataRows.last) {
      this.table.setNoDataRow(this.noDataRows.last);
    }
  }

  printReport() {
    this.loading = true;
    let rawValue = this.getRawValue();

    this.reportAbstractService.previewPdf(
      rawValue,
      this.loadingChange,
      this.errorDisplay
    )
  }

  downloadReportPdf() {
    this.loading = true;
    let rawValue = this.getRawValue();

    this.reportAbstractService.downloadPdf(
      rawValue,
      this.loadingChange,
      this.errorDisplay
    )
  }

  downloadReportExcel() {
    this.loading = true;

    let rawValue = this.getRawValue();

    this.reportAbstractService.downloadExcel(
      rawValue,
      this.loadingChange,
      this.errorDisplay
    )
  }

  loadingChange = (change: boolean) => this.loading = change;

  errorDisplay = (error: any) => this.snackBar.open(error, 'Hide', {duration: 10000});

  generateReport() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) return;

    this.loading = true;
    let rawValue = this.getRawValue();

    console.log(rawValue)
    this.reportAbstractService.generateReport(rawValue).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: data => {
        this.changeReportDisplayedColumns();
        this.changeReportDisplayedTotalColumns();
        this.reportData = data;
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  clearInput() {
    const clearValues = {} as any;
    this.getFieldList().forEach(field => clearValues[field.fieldName] = "");
    this.formGroup.patchValue({
      ...clearValues,
      sortBy: this.getSortList()[this.getSortDefault()]
    })
    this.changeReportDisplayedColumns();
    this.changeReportDisplayedTotalColumns();
    this.reportData = [] as any;
  }

  abstract getSortList(): string[];

  abstract getSortDefault(): number;

  abstract getFieldList(): {
    fieldName: string,
    initValue?: any,
    validators?: any[]
  }[];

  changeReportDisplayedColumns() {
    return;
  }

  changeReportDisplayedTotalColumns() {
    return;
  }

  private getRawValue() {
    let rawValue = this.formGroup.getRawValue();
    rawValue.fromLocationCode = rawValue.fromLocationCode?.locationCode ?? "";
    rawValue.toLocationCode = rawValue.toLocationCode?.locationCode ?? "";
    rawValue.fromProductCode = rawValue.fromProductCode?.code ?? "";
    rawValue.toProductCode = rawValue.toProductCode?.code ?? "";
    rawValue.fromCustomerCode = rawValue.fromCustomerCode?.code ?? "";
    rawValue.toCustomerCode = rawValue.toCustomerCode?.code ?? "";
    rawValue.fromProduct = rawValue.fromProduct?.code ?? "";
    rawValue.toProduct = rawValue.toProduct?.code ?? "";
    return rawValue;
  }

  customers: Customer[] = [];
  customerCodeSuggestData = new SuggestInputData(
    () => this.customers,
    (key, c) => c.code.includes(key),
    (key, c) => c.code === key,
  )

  products: Product[] = [];
  productSuggestData = new SuggestInputData(
    () => this.products,
    (key, p) => p.code.includes(key),
    (key, p) => p.code === key,
  )

  locations: Location[] = [];
  locationSuggestData = new SuggestInputData(
    () => this.locations,
    (key, l) => l.locationCode.includes(key),
    (key, l) => l.locationCode === key,
  )
}
