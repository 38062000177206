import {Component, OnInit} from '@angular/core';
import { PriceTermService } from "./price-term.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PriceTerm } from "./price-term";
import { finalize, switchMap, tap } from "rxjs/operators";
import { DialogService } from "../../common-component/dialog/dialog-service";
import {Sort} from "@angular/material/sort";

@Component({
  selector: 'app-price-term',
  templateUrl: './price-term.component.html',
  styleUrls: ['./price-term.component.css'],
  providers: [PriceTermService]
})
export class PriceTermComponent implements OnInit {
  constructor(private priceTermService: PriceTermService,
              private snackBar: MatSnackBar,
              private dialog: DialogService,
  ) {
  }

  loading = true;

  ngOnInit(): void {
    this.reload();
  }

  compare(a: any, b: any, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  localSort(sort: Sort): void {
    this.priceTerms = [...this.priceTerms.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'tabel':
        default:
          return this.compare(a.label, b.label, isAsc);
      }
    })]
  }

  private reload() {
    this.loading = true;
    this.priceTermService.listPriceTerms().pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: data => {
        this.priceTerms = data;
        if (this.priceTerms.length < 1) this.addRow();
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  displayedColumns: string[] = [
    'label',
    'action'
  ];

  priceTerms: PriceTerm[] = [];

  addRow = () => {
    this.priceTerms = [...this.priceTerms, {id: 0, label: ''}]
  }

  deletePriceTerm(priceTerm: PriceTerm, index: number) {
    if(priceTerm.id) {
      this.dialog.confirm("Delete priceTerm", "Do you want to delete this priceTerm?", {alert: true})
        .pipe(
          tap(() => this.loading = true),
          switchMap(() => this.priceTermService.deletePriceTerm(priceTerm)),
          finalize(() => this.loading = false),
        )
        .subscribe({
          next: () => {
            this.priceTerms = this.priceTerms.filter(p => p.id !== priceTerm.id);
            this.snackBar.open('Delete priceTerm successful', 'Close', {duration: 2000});
          },
          error: (error) => this.snackBar.open(error, 'Close', {duration: 10000}),
        });
    } else {
      this.priceTerms.splice(index, 1);
      this.priceTerms = [...this.priceTerms];
    }
  }

  save() {
    // validate table data
    const messages : string[] = [];
    this.priceTerms.forEach(priceTerm => {
      !priceTerm.label && messages.push("Label is required");
    })
    if(messages.length > 0) {
      this.snackBar.open(messages as any, 'Hide', {duration: 10000});
      return;
    }

    this.loading = true;
    this.priceTermService.savePriceTerms(this.priceTerms).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: () => {
        this.reload();
        this.snackBar.open('Save priceTerms successful', 'Close', {duration: 2000});
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    })
  }
}
