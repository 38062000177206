import { Component } from '@angular/core';
import { ReportAbstractComponent } from "../report-abstract.component";
import { CustomerDetailedReportRecord } from "./customer-detailed-report-record";
import {FormBuilder, Validators} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomerDetailedReportService } from "./customer-detailed-report-service";
import {CustomerService} from "../../customer/customer-service";
import {ProductService} from "../../product/product-service";
import {LocationService} from "../../location/location-service";

@Component({
  selector: 'app-customer-product-summary-report',
  templateUrl: './customer-detailed-report.component.html',
  styleUrls: ['./customer-detailed-report.component.css'],
  providers: [CustomerDetailedReportService, CustomerService, ProductService, LocationService]
})
export class CustomerDetailedReportComponent extends ReportAbstractComponent<CustomerDetailedReportRecord[]> {

  reportDisplayedColumns = [
    "sNo",
    "productName",
    "doNo",
    "dateTime",
    "vehNo",
    "customerName",
    "locationName",
    "transporterName",
    "qty",
  ];
  reportDisplayedTotalColumns = [
    'emptyFooter',
    'emptyFooter',
    'emptyFooter',
    'emptyFooter',
    'emptyFooter',
    'emptyFooter',
    'emptyFooter',
    'granTotalQtyLabel',
    'totalAmountQty',
  ];

  constructor(private _formBuilder: FormBuilder,
              private _snackBar: MatSnackBar,
              private customerProductSummaryReportService: CustomerDetailedReportService,
              customerService: CustomerService,
              productService: ProductService,
              locationService: LocationService

  ) {
    super(_formBuilder, _snackBar, customerProductSummaryReportService, customerService, productService, locationService);
  }

  getFieldList(): { fieldName: string; validators?: any[] }[] {
    return [
      {fieldName: "fromDate", validators: [Validators.required]},
      {fieldName: "toDate", validators: [Validators.required]},
      {fieldName: "fromCustomerCode"},
      {fieldName: "toCustomerCode"},
      {fieldName: "fromProductCode"},
      {fieldName: "toProductCode"},
      {fieldName: "fromLocationCode"},
      {fieldName: "toLocationCode"},
    ];
  }

  getSortDefault(): number {
    return 0;
  }

  getSortList(): string[] {
    return ["Customer Name", "Location Name", "Product Name"];
  }

  getTotalAmountQty(): number {
    if (!this.reportData || !this.reportData.length) return 0;
    return this.reportData.reduce((store, current) => store + current.qty, 0);
  }

  getTotalAmount(): number {
    if (!this.reportData || !this.reportData.length) return 0;
    return this.reportData.reduce((store, current) => store + current.amount, 0);
  }

  getTotalGST(): number {
    if (!this.reportData || !this.reportData.length) return 0;
    return this.reportData.reduce((store, current) => store + current.gst, 0);
  }

  getTotalNetAmount(): number {
    if (!this.reportData || !this.reportData.length) return 0;
    return this.reportData.reduce((store, current) => store + current.netAmount, 0);
  }

  changeReportDisplayedColumns() {
    debugger
    if(this.formGroup.controls['salesType'].value !== '0') {
      this.reportDisplayedColumns = [
        "sNo",
        "productName",
        "doNo",
        "dateTime",
        "vehNo",
        "customerName",
        "locationName",
        "transporterName",
        "qty",
        "amount",
        "gst",
        "netAmount"
      ];
    } else {
      this.reportDisplayedColumns = [
        "sNo",
        "productName",
        "doNo",
        "dateTime",
        "vehNo",
        "customerName",
        "locationName",
        "transporterName",
        "qty",
      ];
    }
  }

  changeReportDisplayedTotalColumns() {
    if(this.formGroup.controls['salesType'].value !== '0') {
      this.reportDisplayedTotalColumns = [
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'granTotalQtyLabel',
        'totalAmountQty',
        'totalAmount',
        'totalGST',
        'totalNetAmount',
      ];
    } else {
      this.reportDisplayedTotalColumns = [
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'emptyFooter',
        'granTotalQtyLabel',
        'totalAmountQty',
      ];
    }
  }
}
