import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from './user';
import { PagingRequest, PagingResult } from "../../common-component/pageable-datatable/pageable";
import { handleError } from "../../common/http-request";

@Injectable()
export class UserService {
  base: string = environment.API_ENDPOINT + '/user/';

  constructor(private http: HttpClient) {
  }

  searchUsers(request: PagingRequest<User>) {
    return this.http
      .get<PagingResult<User>>(this.base + 'page', {
        params: {...request}
      })
      .pipe(catchError(handleError));
  }

  saveUser(id: number, params: any) {
    if (id === 0) {
      return this.http
        .post<any>(this.base + 'create', params)
        .pipe(catchError(handleError));
    }
    return this.http
      .put<any>(this.base + 'update/' + id, params)
      .pipe(catchError(handleError));
  }

  deleteUser(param: User) {
    if (param.id) {
      return this.http
        .delete<any>(this.base + 'delete/' + param.id)
        .pipe(catchError(handleError));
    } else {
      return throwError('Invalid Param. Id not exist.');
    }
  }

  getUser(id: number) {
    return this.http
      .get<User>(this.base + id)
      .pipe(catchError(handleError));
  }
}
