import { Component } from '@angular/core';
import { ReportAbstractComponent } from "../report-abstract.component";
import { CustomerVehicleReportRecord } from "./customer-vehicle-report-record";
import { FormBuilder, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomerVehicleReportService } from "./customer-vehicle-report-service";
import {Subject} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {CustomerService} from "../../customer/customer-service";
import {ProductService} from "../../product/product-service";
import {LocationService} from "../../location/location-service";

@Component({
  selector: 'app-customer-product-summary-report',
  templateUrl: './customer-vehicle-report.component.html',
  styleUrls: ['./customer-vehicle-report.component.css'],
  providers: [CustomerVehicleReportService, CustomerService, ProductService, LocationService]
})
export class CustomerVehicleReportComponent extends ReportAbstractComponent<CustomerVehicleReportRecord[]> {

  reportDisplayedColumns = [
    "vehNo",
  ];

  reportDisplayedTotalColumns: string[] = [];

  constructor(private _formBuilder: FormBuilder,
              private _snackBar: MatSnackBar,
              private customerProductSummaryReportService: CustomerVehicleReportService,
              customerService: CustomerService,
              productService: ProductService,
              locationService: LocationService
  ) {
    super(_formBuilder, _snackBar, customerProductSummaryReportService, customerService, productService, locationService);
  }

  getFieldList(): { fieldName: string; validators?: any[] }[] {
    return [
      {fieldName: "fromDate", validators: [Validators.required]},
      {fieldName: "toDate", validators: [Validators.required]},
      {fieldName: "fromCustomerCode"},
      {fieldName: "toCustomerCode"},
    ];
  }

  getSortDefault(): number {
    return 0;
  }

  getSortList(): string[] {
    return ["Veh No"];
  }

  customerKeyword = new Subject<{ name?: string, code?: string }>();
  suggestCustomers = this.customerKeyword.pipe(
    switchMap(key => this.customerService
      .searchCustomers({page: 0, size: 5, sortBy: key.name ? 'name' : key.code ? 'code' : 'id'}, key)
    ),
    map(pagingResult => pagingResult.content)
  );

  perfectMatchCustomer = this.customerKeyword.pipe(
    switchMap(key => this.customerService
      .findCustomerByCode(key)
    )
  );

}
