import { Component, OnInit } from '@angular/core';
import { CustomerTypes } from "../customer";
import { CustomerService } from "../customer-service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css'],
  providers: [CustomerService]
})
export class CustomerDetailComponent implements OnInit {
  constructor(private route: ActivatedRoute,
              private router: Router,
              private customerService: CustomerService,
              private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
  ) {
    this.formGroup = formBuilder.group({
      code: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      type: new FormControl(CustomerTypes[0]),
      address1: new FormControl(''),
      address2: new FormControl(''),
      address3: new FormControl(''),
      telephone: new FormControl(''),
      fax: new FormControl(''),
      activeStatus: new FormControl(true),
    });
  }

  loading = true;

  customerId = 0;

  formGroup: FormGroup;
  types = CustomerTypes;

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    if (id === 'new') {
      this.loading = false;
      return;
    }
    this.loading = true;
    this.customerService.getCustomer(id).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: (data) => {
        this.customerId = data.id;
        this.formGroup.get('password')?.clearValidators();
        this.formGroup.patchValue({
          code: data.code,
          name: data.name,
          type: data.type,
          address1: data.address1,
          address2: data.address2,
          address3: data.address3,
          telephone: data.telephone,
          fax: data.fax,
          activeStatus: data.activeStatus,
        });
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  transformToUppercase(controlName: string): void {
    const control = this.formGroup.get(controlName);
    if (control) {
      const value = control.value;
      control.setValue(value.toUpperCase());
    }
  }

  save() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) return;

    const action = this.customerId === 0 ? 'Create' : 'Update';
    this.loading = true;
    this.customerService.saveCustomer(this.customerId, this.formGroup.getRawValue()).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: () => {
        this.snackBar.open(action + ' customer successful', 'Close', {duration: 2000});
        this.close();
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  close() {
    this.router.navigate(["/customers"]);
  }
}
