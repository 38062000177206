const ICON_PLUS = `<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 5.57143H5.57143V9H4.42857V5.57143H1V4.42857H4.42857V1H5.57143V4.42857H9V5.57143Z" fill="#F9562B" stroke="#F9562B" stroke-linejoin="round"/>
</svg>`;
const ICON_MINUS = `<svg width="10" height="4" viewBox="0 0 10 4" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.57143 3H9V1H5.57143H4.42857H1V3H4.42857H5.57143Z" fill="#F9562B" stroke="#F9562B" stroke-linejoin="round"/>
</svg>`;
export class ZoomControls {
  map: google.maps.Map;
  position: google.maps.ControlPosition;
  html: HTMLDivElement;
  zoomInBtn: HTMLButtonElement;
  zoomOutBtn: HTMLButtonElement;
  constructor(options: any) {
    this.map = options.map;
    this.position = options.position;
    this.html = document.createElement('DIV') as HTMLDivElement;
    this.zoomInBtn = document.createElement('BUTTON') as HTMLButtonElement;
    this.zoomOutBtn = document.createElement('BUTTON') as HTMLButtonElement;
    this.initUI();
    this.initEvent();
  }

  initUI() {
    if (this.position == google.maps.ControlPosition.RIGHT_BOTTOM) {
      this.html.style.marginBottom = '12px';
    } else {
      this.html.style.marginTop = '12px';
    }
    this.html.style.marginRight = '12px';
    this.html.style.display = 'flex';

    this.zoomInBtn.type = 'button';
    this.zoomInBtn.style.cursor = 'pointer';
    this.zoomInBtn.style.display = 'inline-flex';
    this.zoomInBtn.style.alignItems = 'center';
    this.zoomInBtn.style.justifyContent = 'center';
    this.zoomInBtn.style.width = '30px';
    this.zoomInBtn.style.height = '26px';
    this.zoomInBtn.style.padding = '0';
    this.zoomInBtn.style.margin = '0';
    this.zoomInBtn.style.border = '1px solid #E5E5E5';
    this.zoomInBtn.style.borderRadius = '6px 0px 0px 6px';
    this.zoomInBtn.style.background = 'rgba(255, 255, 255, 0.8)';

    this.zoomInBtn.innerHTML = ICON_PLUS;

    this.zoomOutBtn.type = 'button';
    this.zoomOutBtn.style.cursor = 'pointer';
    this.zoomOutBtn.style.display = 'inline-flex';
    this.zoomOutBtn.style.alignItems = 'center';
    this.zoomOutBtn.style.justifyContent = 'center';
    this.zoomOutBtn.style.width = '30px';
    this.zoomOutBtn.style.height = '26px';
    this.zoomOutBtn.style.padding = '0';
    this.zoomOutBtn.style.margin = '0';
    this.zoomOutBtn.style.border = '1px solid #E5E5E5';
    this.zoomOutBtn.style.borderRadius = '0px 6px 6px 0px';
    this.zoomOutBtn.style.background = 'rgba(255, 255, 255, 0.8)';

    this.zoomOutBtn.innerHTML = ICON_MINUS;

    this.html.appendChild(this.zoomInBtn);
    this.html.appendChild(this.zoomOutBtn);
  }

  initEvent() {
    this.zoomInBtn.addEventListener('click', () => {
      this.map.setZoom(this.map.getZoom() as number + 1);
    });
    this.zoomOutBtn.addEventListener('click', () => {
      this.map.setZoom(this.map.getZoom() as number - 1);
    });
  }
}
