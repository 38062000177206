import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, retry} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {Location} from './location';
import {PagingRequest, PagingResult} from 'src/app/common-component/pageable-datatable/pageable';
import {handleError} from 'src/app/common/http-request';
import {throwError} from "rxjs";

@Injectable()
export class LocationService {
  base: string = environment.API_ENDPOINT + '/location/';

  constructor(private http: HttpClient) {
  }

  pageLocations(request: PagingRequest<Location>, key: string, customerId?: number, checkKeyName: boolean = false) {
    let params = {...request, key, checkKeyName} as any;
    if (customerId) params = {...params, customerId}
    return this.http
      .get<PagingResult<Location>>(this.base + 'page', {
        params
      })
      .pipe(catchError(handleError));
  }

  listLocations() {
    return this.http
      .get<Location[]>(this.base + 'list')
      .pipe(catchError(handleError));
  }

  deleteLocation(location: Location) {
    if (location.id) {
      return this.http
        .delete<any>(this.base + 'delete/' + location.id)
        .pipe(catchError(handleError));
    } else {
      return throwError('Invalid Param. Id not exist.');
    }
  }

  saveLocation(id: number, params: any) {
    params = {
      ...params,
      customer: null,
      customerId: params.customer.id
    }
    if (id === 0) {
      return this.http
        .post<any>(this.base + 'create', params)
        .pipe(retry(1), catchError(handleError));
    }
    return this.http
      .put<any>(this.base + 'update/' + id, params)
      .pipe(catchError(handleError));

  }

  getLocation(id: number) {
    return this.http
      .get<Location>(this.base + id)
      .pipe(catchError(handleError));
  }

  listLocationCodes(customerId: number) {
    return this.http
      .get<Location[]>(this.base + 'list', {
        params: {customerId}
      })
      .pipe(catchError(handleError));

  }

  findLocationByDoNo(doNo: string | null){
    return this.http.get<Location[]>(this.base + 'getLocationByDoNo?doNo=' + doNo);
  }

  findLocationByCode(code: string | null){
    return this.http.get<Location>(this.base + 'getLocationByCode?code=' + code);
  }
}
