import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PriceTerm } from './price-term';
import { handleError } from "../../common/http-request";
import { PagingRequest, PagingResult } from "../../common-component/pageable-datatable/pageable";
import {Customer} from "../customer/customer";

@Injectable()
export class PriceTermService {
  base: string = environment.API_ENDPOINT + '/priceTerm/';

  constructor(private http: HttpClient) {}

  listPriceTerms() {
    return this.http
      .get<PriceTerm[]>(this.base + 'list')
      .pipe(catchError(handleError));
  }

  pagePriceTerms(request: PagingRequest<PriceTerm>, key: string, filterActive: boolean = true, filterForTransaction = false) {
    return this.http
      .get<PagingResult<PriceTerm>>(this.base + 'page', {
        params: {...request, key, filterActive, filterForTransaction}
      })
      .pipe(catchError(handleError));
  }

  savePriceTerms(priceTerms: PriceTerm[]) {
    priceTerms = priceTerms.map(priceTerm => ({...priceTerm}));
    return this.http
      .put<any>(this.base + 'save-all', priceTerms)
      .pipe(catchError(handleError));
  }
  search(request: PagingRequest<PriceTerm>, label: string) {
    const params: any = {...request};
    if (label) params.label = label;
    return this.http
      .get<PagingResult<PriceTerm>>(this.base + 'page', {
        params
      })
      .pipe(catchError(handleError));
  }
  deletePriceTerm(priceTerm: PriceTerm) {
    if (!priceTerm.id) {
      return throwError('Invalid Param. Id not exist.');
    }

    return this.http
      .delete<any>(this.base + 'delete/' + priceTerm.id)
      .pipe(catchError(handleError));
  }
}
