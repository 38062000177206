import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { SearchDoRecord } from "./search-do-record";
import { MatSnackBar } from "@angular/material/snack-bar";
import { finalize } from "rxjs/operators";
import { SearchDoService } from "./search-do-service";
import { MatDialog } from "@angular/material/dialog";
import { DoDetailComponent } from "./do-detail/do-detail.component";
import { DoModificationPopupComponent } from "./do-modification-popup/do-modification-popup.component";

@Component({
  selector: 'app-search-do',
  templateUrl: './search-do.component.html',
  styleUrls: ['./search-do.component.css'],
  providers: [SearchDoService]
})
export class SearchDoComponent {

  loading = false;
  formGroup: FormGroup;
  searchDoData: SearchDoRecord[] = [];
  reportDisplayedColumns = [
    "status",
    "viewDo",
    "doNo",
    "doDate",
    "customerCode",
    "customerName",
    "locationCode",
    "locationName",
    "doModification"
  ]

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private searchDoService: SearchDoService,
    private dialog: MatDialog
  ) {
    this.formGroup = formBuilder.group({
      fromDoNo: new FormControl(''),
      toDoNo: new FormControl(''),
      fromDate: new FormControl('', [Validators.required]),
      toDate: new FormControl('', [Validators.required]),
      vehicleNo: new FormControl(''),
      transporterCode: new FormControl(''),
      fromCustomerCode: new FormControl(''),
      toCustomerCode: new FormControl(''),
      fromLocationCode: new FormControl(''),
      toLocationCode: new FormControl(''),
    });
  }

  getListData() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) return;

    this.loading = true;
    let rawValue = this.formGroup.getRawValue();
    rawValue.fromDate = rawValue.fromDate.format('yyyy-MM-DD');
    rawValue.toDate = rawValue.toDate.format('yyyy-MM-DD');
    this.searchDoService.getDoList(rawValue).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: data => {
        this.searchDoData = data;
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  clearInput() {
    this.formGroup.patchValue({
      fromDoNo: "",
      toDoNo: "",
      fromDate: "",
      toDate: "",
      vehicleNo: "",
      transporterCode: "",
      fromCustomerCode: "",
      toCustomerCode: "",
      fromLocationCode: "",
      toLocationCode: "",
    });
    this.searchDoData = [];
  }

  handleViewDo(searchDo: SearchDoRecord) {
    this.dialog.open(DoDetailComponent, {width: '600px', data: searchDo});
  }

  handleViewDoModification(searchDo: SearchDoRecord) {
    this.dialog.open(DoModificationPopupComponent, {width: '600px', data: searchDo.doNo});
  }

  getDOInvoiced() {
    return this.searchDoData.filter(doInfo => doInfo.invoiceNo).length;
  }

  getDONonInvoiced() {
    return this.searchDoData.length - this.getDOInvoiced();
  }

  getDOCanceled() {
    return this.searchDoData.filter(doInfo => doInfo.cancel).length;
  }
}
