<app-scene [header]="'Search Customer'" [loading]="loading">
  <div class="flex">
    <mat-form-field class="w-[30%]">
      <button mat-icon-button matPrefix (click)="reload()" style="margin-left: 5px">
        <mat-icon class="text-primary">search</mat-icon>
      </button>
      <input matInput placeholder="Search customer" [(ngModel)]="keyword" (change)="reload()">
    </mat-form-field>
    <div class="flex" style="padding-bottom: 22px">
      <button [routerLink]="['/customer', 'new']" class="text-btn" style="margin: auto 20px">
        <span class="material-symbols-outlined">add</span>
        <span>Add customer</span>
      </button>
    </div>
  </div>
  <app-pageable-datatable [getDataFn]="searchCustomers" matSort (matSortChange)="sortChange($event)">
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        No data matching the filter.
      </td>
    </tr>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.id > 0 ? element.id : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="code">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Customer Code</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.code }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Customer Name</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef>Address</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.address1 }}
        <br>
        {{ element.address2 }}
        <br>
        {{ element.address3 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.type }}
      </td>
    </ng-container>

    <ng-container matColumnDef="contact">
      <th mat-header-cell *matHeaderCellDef>Contact No.</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.telephone ? 'Telephone: ' + element.telephone : '' }}
        <br>
        {{ element.fax ? 'Fax: ' + element.fax : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="activeStatus">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Active Status</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <span class="active-status" [class.disable]="!element.activeStatus"></span>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <button mat-icon-button
                *ngIf="element.id > 0"
                [routerLink]="['/customer', element.id]">
          <span class="material-symbols-outlined text-primary">edit</span>
        </button>
      </td>
    </ng-container>
  </app-pageable-datatable>
</app-scene>
