<app-scene header="Location Template" [loading]="loading">
  <div [formGroup]="formGroup" class="max-w-2xl">
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <mat-checkbox formControlName="activeStatus" class="flex-1">
        Active
      </mat-checkbox>

    </div>
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
      <div class="flex-1"></div>
    </div>
  </div>

  <table mat-table [dataSource]="prices" class="mat-elevation-z8">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="locationCode">
      <th mat-header-cell *matHeaderCellDef>Location Code</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <app-suggest-input [(value)]="element.location"
                           [valueField]="'locationCode'"
                           [nameField]="['locationCode']"
                           [suggestData]="locationSuggestData"
        >
        </app-suggest-input>
      </td>
    </ng-container>

    <ng-container matColumnDef="locationName">
      <th mat-header-cell *matHeaderCellDef>Location Description</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input matInput class="rounded-sm" [value]="element.location?.locationName || ''" disabled/>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="transportPricePerLoad">
      <th mat-header-cell *matHeaderCellDef>transportPricePerLoad</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <span matTextPrefix>$&nbsp;</span>
          <input matInput class="rounded-sm" [(ngModel)]="element.transportPricePerLoad"/>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="transportPricePerTon">
      <th mat-header-cell *matHeaderCellDef>TransportPricePerTon</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <span matTextPrefix>$&nbsp;</span>
          <input matInput class="rounded-sm" [(ngModel)]="element.transportPricePerTon"/>
        </mat-form-field>
      </td>
    </ng-container>


    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <button mat-icon-button (click)="deletePrice(element, i)">
            <span
              class="material-symbols-outlined text-alert">
                delete
            </span>
        </button>
      </td>
    </ng-container>
  </table>

  <button class="text-btn" (click)="addRow()">
    <span class="material-symbols-outlined">add</span>
    <span>Add new row</span>
  </button>


  <div class="float-right" footer>
    <button mat-button (click)="close()" class="mr-5 cancel-btn">Close</button>
    <button mat-button (click)="save()" class="confirm-btn">Save</button>
  </div>
</app-scene>
