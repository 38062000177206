import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject,Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {User} from '../user/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  base: string = environment.API_ENDPOINT + '/authentication/';
  public currentUserSubject: BehaviorSubject<User | null | undefined>;
  public currentUser: Observable<User | null | undefined>;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    console.log('AuthenticationService constructor');
    if (localStorage.getItem('currentUser')) {
      console.log('have user in storage');
      this.currentUserSubject = new BehaviorSubject<User | null | undefined>(
        JSON.parse(localStorage.getItem('currentUser')!)
      );
    } else {
      console.log("don't have user in storage");
      this.currentUserSubject = new BehaviorSubject<User | null | undefined>(
        null
      );
    }
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User | null | undefined {
    if (this.currentUserSubject) {
      return this.currentUserSubject.value;
    }
    return null;
  }

  login(username: string, password: string) {
    console.log('login called');
    return this.http
      .post<any>(`${this.base}login`, { username, password })
      .pipe(
        map((response) => {
          console.log('login success with : ' + response);
          console.log(response);

          let user = {} as User;
          if (response && response.jwttoken) {
            user = response.user;
            user.token = response.jwttoken;
            localStorage.setItem('currentUser', JSON.stringify(user));
            console.log('current user set to : ' + user);
            this.router.navigate(['']);
            this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }

  logout(redirect = true) {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    if (redirect) this.router.navigate(['/login']);
  }

  forgetPassword(username: string) {
    return this.http.post<any>(`${this.base}forgetPassword`, { username })
  }

  resetPassword(data: any) {
    return this.http.post<any>(`${this.base}resetPassword`, data)
  }
}
