import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {
  title: string;
  message: string;
  close: string;
  confirm: string;
  confirmClass: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: {title: string, message: string, alert?: boolean, close?: string, confirm?: string},
  ) {
    this.title = data.title;
    this.message = data.message;
    this.close = data.close || "Cancel";
    this.confirm = data.confirm|| "Confirm";
    this.confirmClass = data.alert ? "alert-btn" : "confirm-btn";
  }
}
