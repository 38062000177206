<app-scene [header]="'Search GST'" [loading]="loading">
  <div class="flex gap-x-[2%]">
    <div class="flex w-[49%] gap-x-[2%]">
      <mat-form-field class="w-[49%]">
        <input matInput placeholder="Effective Start Date" [(ngModel)]="startDate" (dateChange)="validateAndReload()" [matDatepicker]="pickerStartDate">
        <mat-datepicker-toggle matIconSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerStartDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="w-[49%]">
        <input matInput placeholder="Effective End Date" [(ngModel)]="endDate" (dateChange)="validateAndReload()" [matDatepicker]="pickerEndDate">
        <mat-datepicker-toggle matIconSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerEndDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="flex w-[49%] gap-x-[2%]">
    </div>
  </div>
  <div class="flex">
    <div class="flex" style="padding-bottom: 22px">
      <button [routerLink]="['/gst', 'new']" class="text-btn" style="margin: auto 20px">
        <span class="material-symbols-outlined">add</span>
        <span>Add GST</span>
      </button>
    </div>
  </div>
  <app-pageable-datatable [getDataFn]="searchGsts">
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        No data matching the filter.
      </td>
    </tr>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="effectiveStartDate">
      <th mat-header-cell *matHeaderCellDef>Effective Start Date</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.effectiveStartDate | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="effectiveEndDate">
      <th mat-header-cell *matHeaderCellDef>Effective End Date</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.effectiveEndDate | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>Value</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.value }}%
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <button mat-icon-button
                *ngIf="element.id > 0"
                [routerLink]="['/gst', element.id]">
          <span class="material-symbols-outlined text-primary">edit</span>
        </button>
        <button mat-icon-button
                *ngIf="element.id > 0"
                (click)="deleteGst(element)">
          <span class="material-symbols-outlined text-alert">delete</span>
        </button>
        <button mat-icon-button
                *ngIf="element.id > 0 && !element.effectiveEndDate"
                [routerLink]="['/gst', element.id]"
                [queryParams]="{isRevise: true}"
        >
          <span class="material-symbols-outlined text-primary">source_notes</span>
        </button>
      </td>
    </ng-container>
  </app-pageable-datatable>
</app-scene>
