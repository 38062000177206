import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-route',
  templateUrl: './auth-route.component.html',
  // providers: [UserService],
  // styleUrls: ['./auth-route.component.css']
})
export class AuthRouteComponent implements OnInit {
  role: String = '';
  constructor(private router: Router, private snackBar: MatSnackBar) {}

  ngOnInit() {
    // this.userService.checkFirstLoginUser().subscribe((data: Boolean) => {
    //   this.isFirstLogin = data;
    //   if (this.isFirstLogin) {
    //     this.router.navigate(['/change-password']);
    //   } else {
    //     this.checkUserRole();
    //   }
    // });
    this.router.navigate(['/sample']);
  }

  checkUserRole() {
    // this.userService.getRole().subscribe((data) => {
    //   this.role = data;
    //   this.checkOrgs();
    // }, this.processError);
    this.role = 'ROLE_1';
  }

  processError(error: any) {
    this.snackBar.open(error, 'Close', { duration: 10000 });
    console.log(error);
  }
}
