import {FlatTreeControl} from '@angular/cdk/tree';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener
} from '@angular/material/tree';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../auth-route/auth-route.service';
import {Role,Roles,User} from '../user/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
  title = 'fiab';
  currentYear = 2025;
  currentUser: User | null | undefined;
  userSubscription: Subscription | undefined;
  autoSaveInterval: any;
  visibleNodes = [] as MenuNode[];

  constructor(
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef
  ) {
    this.currentUser = this.authenticationService.currentUserValue;

    this.visibleNodes = MENU_DATA.filter(n => this.shouldShow(n)).map(node => {
      const {children, ...items} = {...node};
      const showChildren = children?.filter(node => this.shouldShow(node)) ?? [];
      console.log(children, showChildren);
      return {
        ...items,
        children: showChildren
      }
    });
    console.log(this.visibleNodes);
    this.dataSource.data = this.visibleNodes;
    this.treeControl.expandAll();
    this.currentYear = new Date().getFullYear();
  }
  ngOnInit(): void {
    // this.autoSaveInterval = setInterval(() => {
    //   console.log('timer');
    //   this.currentUser = this.authenticationService.currentUserValue;
    // }, 5000);

    this.userSubscription =
      this.authenticationService.currentUserSubject.subscribe((val) => {
        // console.log('change detected for nav');
        this.currentUser = val;
        this.visibleNodes = MENU_DATA.filter(n => this.shouldShow(n)).map(node => {
          const {children, ...items} = {...node};
          const showChildren = children?.filter(node => this.shouldShow(node)) ?? [];
          console.log(children, showChildren);
          return {
            ...items,
            children: showChildren
          }
        });
        this.dataSource.data = this.visibleNodes;
        this.treeControl.expandAll();
        this.cdr.detectChanges();
      });
  }

  ngAfterViewInit(): void {
    // console.log(this.dataSource.data)
    // console.log(this.treeControl)
  }

  ngAfterViewChecked(): void {}

  ngOnDestroy(): void {
    if (this.userSubscription) this.userSubscription.unsubscribe();
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  logout() {
    this.authenticationService.logout();
  }

  @ViewChild('drawer') public drawer!: MatDrawer;

  private _transformer = (node: MenuNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      show: this.shouldShow(node),
      icon: node.icon,
      name: node.name,
      route: node.route,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  shouldShow(node: MenuNode) : boolean{
    // console.log("Node roles : " + JSON.stringify(node.showFor));
    // console.log("User's role : " + this.currentUser?.role);
    if (!node.showFor) return false;

    const roleStr = this.currentUser?.role;
    if (!roleStr) return false;

    const role = Roles.find(r => r.value === roleStr);
    if (!role) return false;

    return node.showFor.includes(role);
  }
}

interface MenuNode {
  icon?: string;
  name: string;
  route?: string;
  children?: MenuNode[];
  showFor?: Role[];
}

interface FlatNode {
  expandable: boolean;
  show: boolean;
  icon: string | undefined;
  name: string;
  route: string | undefined;
  level: number;
}

const MENU_DATA: MenuNode[] = [
  {
    name: 'Master Table',
    children: [
      {
        name: 'User',
        route: '/users',
        icon: 'account_circle',
        showFor: [Roles[0], Roles[1]],
      },
      {
        name: 'UOM',
        route: '/uom',
        icon: 'weight',
        showFor: [Roles[0], Roles[1]],
      },
      {
        name: 'Product',
        route: '/products',
        icon: 'move_up',
        showFor: [Roles[0], Roles[1]],
      },
      {
        name: 'Customer',
        route: '/customers',
        icon: 'inventory',
        showFor: [Roles[0], Roles[1]],
      },
      {
        name: 'Location',
        route: '/locations',
        icon: 'inventory',
        showFor: [Roles[0], Roles[1]],
      },
      {
        name: 'Price Term',
        route: '/price-term',
        icon: 'inventory',
        showFor: [Roles[0], Roles[1]],
      },
      {
        name: 'GST',
        route: '/gsts',
        icon: 'inventory',
        showFor: [Roles[0], Roles[1]],
      },
    ],
    showFor: [Roles[0], Roles[1]],
  },
  {
    name: 'Inventory Control',
    children: [
      {
        name: 'Stock Transaction',
        route: '/misc-transactions',
        icon: 'move_up',
        showFor: [Roles[0]],
      },
    ],
    showFor: [Roles[0]],
  },
  {
    name: 'Order Management',
    children: [
      {
        name: 'Product Template',
        route: '/product-templates',
        icon: 'inventory',
        showFor: [Roles[0], Roles[1]],
      },
      {
        name: 'Location Transport Price',
        route: '/location-templates',
        icon: 'inventory',
        showFor: [Roles[0], Roles[1]],
      },
      {
        name: 'Price Listing',
        route: '/prices',
        icon: 'inventory',
        showFor: [Roles[0], Roles[1]],
      },
    ],
    showFor: [Roles[0], Roles[1]],
  },
  {
    name: 'Billing',
    children: [
      {
        name: 'Search DO',
        route: '/search-do',
        icon: 'receipt',
        showFor: [Roles[0], Roles[1]],
      },
      {
        name: 'DO Modification',
        route: '/do-modifications',
        icon: 'receipt',
        showFor: [Roles[0], Roles[1]],
      },
      {
        name: 'Generate Invoicing',
        route: '/generate-invoicing',
        icon: 'receipt',
        showFor: [Roles[0]],
      },
      {
        name: 'Search Invoice',
        route: '/search-invoice',
        icon: 'receipt',
        showFor: [Roles[0]],
      },
    ],
    showFor: [Roles[0], Roles[1]],
  },
  {
    name: 'Reports',
    children: [
      {
        name: 'Customer Summary Report',
        route: '/report/customer-summary-report',
        icon: 'inventory',
        showFor: [Roles[0], Roles[1]],
      },
      {
        name: 'Customer Product Summary Report',
        route: '/report/customer-product-summary-report',
        icon: 'inventory',
        showFor: [Roles[0], Roles[1]],
      },
      {
        name: 'Vehicle Summary Report',
        route: '/report/vehicle-summary-report',
        icon: 'inventory',
        showFor: [Roles[0], Roles[1]],
      },
      {
        name: 'Customer Detailed Report',
        route: '/report/customer-detailed-report',
        icon: 'inventory',
        showFor: [Roles[0], Roles[1]],
      },
      {
        name: 'Product Summary Report',
        route: '/report/product-summary-report',
        icon: 'inventory',
        showFor: [Roles[0], Roles[1]],
      },
      {
        name: 'Daily Transaction Report',
        route: '/report/daily-transaction-report',
        icon: 'inventory',
        showFor: [Roles[0], Roles[1]],
      },
      {
        name: 'Customer Vehicle Report',
        route: '/report/customer-vehicle-report',
        icon: 'inventory',
        showFor: [Roles[0], Roles[1]],
      },
      {
        name: 'Inventory Report',
        route: '/report/inventory-report',
        icon: 'inventory',
        showFor: [Roles[0], Roles[1]],
      },
    ],
    showFor: [Roles[0], Roles[1]],
  },
];

