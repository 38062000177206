import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Location} from './location';
import {LocationService} from './location-service';
import {CustomerService} from '../customer/customer-service';
import {finalize, switchMap, tap} from "rxjs/operators";
import {PagingRequest} from "../../common-component/pageable-datatable/pageable";
import {PageableDatatableComponent} from "../../common-component/pageable-datatable/pageable-datatable.component";
import {SuggestInputData} from "../../common-component/suggest-input/suggest-input";
import {DialogService} from "../../common-component/dialog/dialog-service";
import {Sort} from "@angular/material/sort";
import {Customer} from "../customer/customer";

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css'],
  providers: [LocationService, CustomerService]
})
export class LocationComponent implements OnInit {
  displayedColumns: string[] = [
    "customerName",
    'locationCode',
    'locationName',
    'latitude',
    'longitude',
    'radius',
    'locationType',
    'Action',
  ];
  locations: Location[] = [];
  loading = false;

  customers: Customer[] = [];
  customerSuggestData = new SuggestInputData(
    () => this.customers,
    (key, c) => c.name.includes(key),
    (key, c) => c.name === key,
  )

  @ViewChild(PageableDatatableComponent)
  datatable!: PageableDatatableComponent<Location>;

  sort: Sort | null = null;

  constructor(
    private locationService: LocationService,
    private snackBar: MatSnackBar,
    private customerService: CustomerService,
    private dialogService: DialogService,
  ) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.customerService.listCustomers().pipe(
      finalize(() => this.loading = false)
    ).subscribe(r => this.customers = r);
  }

  sortChange(sort: Sort) {
    this.sort = sort;
    this.reload();
  }

  searchLocations = (request: PagingRequest<Location>) => {
    this.loading = true;
    if (this.sort && this.sort.direction !== "") {
      request.sortDir = this.sort.direction;
      request.sortBy = this.sort.active as keyof Location;
    }
    return this.locationService.pageLocations(request, '', this.customerSuggestData.value?.id).pipe(finalize(() => this.loading = false));
  }

  deleteLocation(param: Location): void {
    this.dialogService.confirm(
      "Delete Location",
      "Do you want to delete this location?",
      {alert: true}
    )
      .pipe(
        tap(() => this.loading = true),
        switchMap(() => this.locationService.deleteLocation(param)),
        finalize(() => this.loading = false),
      )
      .subscribe({
        next: () => {
          this.snackBar.open('Delete location successful', 'Close', {duration: 2000});
          this.reload();
        },
        error: (error) => this.snackBar.open(error, 'Close', {duration: 10000}),
      });
  }

  reload(): void {
    this.datatable.reload();
  }

}

