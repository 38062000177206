import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Sample } from './sample';
import { handleError } from "../../common/http-request";

@Injectable()
export class SampleService {
  base: string = environment.API_ENDPOINT + '/sample/';

  constructor(private http: HttpClient) {}

  getSamples() {
    return this.http
      .get<Sample[]>(this.base + 'all')
      .pipe(catchError(handleError));
  }

  saveSample(params: Sample[]) {
    return this.http
      .put<any>(this.base + 'save', params)
      .pipe(catchError(handleError));
  }

  deleteSample(param: Sample) {
    if (param.id) {
      return this.http
        .delete<any>(this.base + 'delete/' + param.id)
        .pipe(catchError(handleError));
    } else {
      return throwError('Invalid Param. Id not exist.');
    }
  }
}
