import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {PagingRequest, PagingResult} from "../../common-component/pageable-datatable/pageable";
import { handleError } from "../../common/http-request";
import {Gst} from "./gst";
import {User} from "../user/user";
import {throwError} from "rxjs";

@Injectable()
export class GstService {
  base: string = environment.API_ENDPOINT + '/gst/';

  constructor(private http: HttpClient) {}
  searchGsts(request: PagingRequest<Gst>, startDate: any, endDate: any) {
    const params: any = {...request};
    if (startDate) params.effectiveStartDate = startDate;
    if (endDate) params.effectiveEndDate = endDate;
    return this.http
      .get<PagingResult<Gst>>(this.base + 'page', {
        params
      })
      .pipe(catchError(handleError));
  }

  saveGst(id: number,isRevise: boolean, params: any) {

    const apiAction = id === 0 ? 'create' : isRevise ? 'revise/' + id : 'update/' + id;
    if (id === 0) {
      return this.http
        .post<any>(this.base + 'create', params)
        .pipe(catchError(handleError));
    }
    return this.http
      .put<any>(this.base + apiAction, params)
      .pipe(catchError(handleError));
  }

  getGst(id: number) {
    return this.http
      .get<Gst>(this.base + id)
      .pipe(catchError(handleError));
  }

  deleteGst(param: Gst) {
    if (param.id) {
      return this.http
        .delete<any>(this.base + 'delete/' + param.id)
        .pipe(catchError(handleError));
    } else {
      return throwError('Invalid Param. Id not exist.');
    }
  }
}
