import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, } from '@angular/router';
import { AuthenticationService } from '../components/auth-route/auth-route.service';
import { Role } from '../components/user/user';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private snackBar: MatSnackBar,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(route.data.path)
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.role) {
      if (route.data.roles) {
        const foundRole = route.data.roles.find((role2: Role) => role2.value === currentUser.role);

        if (foundRole) {
          return true;
        } else {
          this.snackBar.open('Not allowed to access', 'Close', {duration: 10000});
          return false;
        }
      }
      return true;
    } else {
      const allowRoutes = ['forget-password'];
      console.log(route.data.path)
      if (allowRoutes.includes(route.data.path)) {
        return true;
      }
    }

    this.router.navigate(['/login']);
    return false;
  }
}
