<app-scene [loading]="loading" header="Search DO">
  <div [formGroup]="formGroup" class="max-w-2xl custom-form">
    <div>
      <mat-label class="font-bold">DO No.</mat-label>
      <div class="flex space-x-5">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input formControlName="fromDoNo" matInput placeholder="From">
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input formControlName="toDoNo" matInput placeholder="To">
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-label class="font-bold">DO Date</mat-label>
      <div class="flex space-x-5">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input [matDatepicker]="pickerDateRangeFrom" formControlName="fromDate" matInput placeholder="From">
          <mat-datepicker-toggle [for]="pickerDateRangeFrom" matIconSuffix></mat-datepicker-toggle>
          <mat-datepicker #pickerDateRangeFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input [matDatepicker]="pickerDateRangeTo" formControlName="toDate" matInput placeholder="To">
          <mat-datepicker-toggle [for]="pickerDateRangeTo" matIconSuffix></mat-datepicker-toggle>
          <mat-datepicker #pickerDateRangeTo></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="flex space-x-5">
      <div class="flex-1">
        <mat-label class="font-bold">Vehicle No.</mat-label>
        <div>
          <mat-form-field [floatLabel]="'always'" class="w-full">
            <input formControlName="vehicleNo" matInput placeholder="">
          </mat-form-field>
        </div>
      </div>
      <div class="flex-1">
        <mat-label class="font-bold">Transporter Code</mat-label>
        <div>
          <mat-form-field [floatLabel]="'always'" class="w-full">
            <input formControlName="transporterCode" matInput placeholder="">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div>
      <mat-label class="font-bold">Customer Code</mat-label>
      <div class="flex space-x-5">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input formControlName="fromCustomerCode" matInput placeholder="From">
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input formControlName="toCustomerCode" matInput placeholder="To">
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-label class="font-bold">Location Code</mat-label>
      <div class="flex space-x-5">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input formControlName="fromLocationCode" matInput placeholder="From">
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input formControlName="toLocationCode" matInput placeholder="To">
        </mat-form-field>
      </div>
    </div>
    <div>
      <button (click)="getListData()" class="confirm-btn mr-5" mat-button>
        <mat-icon>search</mat-icon>
        Search
      </button>
      <button (click)="clearInput()" class="mr-5 cancel-btn" mat-button>Clear</button>
    </div>
  </div>

  <div *ngIf="searchDoData && searchDoData.length" class="flex mt-4">
    <div class="flex-1">Total DO : ({{searchDoData.length}} Record(s))</div>
    <div class="flex-1">DO Invoiced: ({{getDOInvoiced()}} Record(s))</div>
    <div class="flex-1">DO Non-Invoiced: ({{getDONonInvoiced()}} Record(s))</div>
    <div class="flex-1">DO Cancelled: ({{getDOCanceled()}} Record(s))</div>
  </div>

  <div class="mt-4">
    <table [dataSource]="searchDoData" mat-table>
      <tr *matHeaderRowDef="reportDisplayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: reportDisplayedColumns;" mat-row></tr>

      <tr *matNoDataRow class="mat-row">
        <td [attr.colspan]="reportDisplayedColumns.length" class="mat-cell">
          No data matching the filter.
        </td>
      </tr>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell>Status</th>
        <td *matCellDef="let element; let i = index;" mat-cell>
          <span *ngIf="element.cancel" class="material-symbols-outlined text-primary">delete</span>
          <span *ngIf="element.invoiceNo" class="material-symbols-outlined text-primary">attach_money</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="viewDo">
        <th *matHeaderCellDef mat-header-cell>View</th>
        <td *matCellDef="let element; let i = index;" mat-cell>
          <button (click)="handleViewDo(element)"
                  mat-icon-button>
            <span class="material-symbols-outlined text-primary">remove_red_eye</span>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="doNo">
        <th *matHeaderCellDef mat-header-cell>DO No.</th>
        <td *matCellDef="let element; let i = index;" mat-cell>
          {{element.doNo}}
        </td>
      </ng-container>

      <ng-container matColumnDef="doDate">
        <th *matHeaderCellDef mat-header-cell>DO Date</th>
        <td *matCellDef="let element; let i = index;" mat-cell>
          {{element.doDate | date : 'dd-MM-yyyy'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="customerCode">
        <th *matHeaderCellDef mat-header-cell>Customer Code</th>
        <td *matCellDef="let element; let i = index;" mat-cell>
          {{element.customerCode}}
        </td>
      </ng-container>

      <ng-container matColumnDef="customerName">
        <th *matHeaderCellDef mat-header-cell>Customer Name</th>
        <td *matCellDef="let element; let i = index;" mat-cell>
          {{element.customerName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="locationCode">
        <th *matHeaderCellDef mat-header-cell>Location Code</th>
        <td *matCellDef="let element; let i = index;" mat-cell>
          {{element.locationCode}}
        </td>
      </ng-container>

      <ng-container matColumnDef="locationName">
        <th *matHeaderCellDef mat-header-cell>Location Name</th>
        <td *matCellDef="let element; let i = index;" mat-cell>
          {{element.locationName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="vehNo">
        <th *matHeaderCellDef mat-header-cell>Veh No</th>
        <td *matCellDef="let element; let i = index;" mat-cell>
          {{element.vehNo}}
        </td>
      </ng-container>

      <ng-container matColumnDef="doModification">
        <th *matHeaderCellDef mat-header-cell>DO Modification</th>
        <td *matCellDef="let element; let i = index;" mat-cell>
          <button
            *ngIf="!element.invoiceNo && element.doNo && !element.hadDoModification"
            [queryParams]="{doNo: element.doNo}"
            [routerLink]="['/do-modification']"
            mat-icon-button
          >
            <span class="material-symbols-outlined text-primary">edit</span>
          </button>
          <button
            (click)="handleViewDoModification(element)"
            *ngIf="element.doNo && element.hadDoModification"
            mat-icon-button
          >
            <span class="material-symbols-outlined text-primary">remove_red_eye</span>
          </button>
        </td>
      </ng-container>
    </table>
  </div>
</app-scene>


