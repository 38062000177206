import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { Injectable } from "@angular/core";
import { map, takeWhile } from "rxjs/operators";

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog) { }

  public confirm(title: string, message: string, option?: {alert?: boolean, cancel?: string, confirm?: string}, handleClose?: (value: boolean) => void) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {title, message, ...option},
    });

    if(handleClose) {
      dialogRef.afterClosed().pipe().subscribe({
        next: value => handleClose(value)
      })
    }

    return dialogRef.afterClosed().pipe(
      map(confirm => !!confirm),
      takeWhile(confirm => confirm),
    );
  }
}
