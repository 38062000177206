import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {AuthenticationService} from '../../auth-route/auth-route.service';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css'],
})
export class ForgetPasswordComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  error = null as any;
  errorMessage: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private snackBar: MatSnackBar,
  ) {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
    });
  }

  get formControls() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService
      .forgetPassword(this.formControls.username.value)
      .pipe(first())
      .subscribe(
        () => {
          this.snackBar.open('A reset password email sent to your email address!');
        },
        (error: any) => {
          console.log(error);
          console.log(JSON.stringify(error));
          this.snackBar.open('Something when wrong!');
          this.error = error;

          if (error.error && !(error.error instanceof ProgressEvent)) {
            this.errorMessage = error.error;
          } else {
            this.errorMessage = error.statusText;
          }

          this.loading = false;
        }
      );
  }
  ngOnInit(): void {
    this.authenticationService.logout(false);
  }
}
