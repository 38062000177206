import { Component, ViewChild } from '@angular/core';
import { DoModification } from './do-modification';
import { DoModificationService } from './do-modification-service';
import { PagingRequest } from "../../common-component/pageable-datatable/pageable";
import { MatDialog } from "@angular/material/dialog";
import { PageableDatatableComponent } from "../../common-component/pageable-datatable/pageable-datatable.component";
import { finalize } from "rxjs/operators";

@Component({
  selector: 'app-customer',
  templateUrl: './do-modification.component.html',
  providers: [DoModificationService],
  styleUrls: ['./do-modification.component.css'],
})
export class DoModificationComponent {
  doNo = "";
  loading = true;
  displayedColumns: string[] = [
    'doNo',
    'doQty',
    'errorCause',
    'otherCause',
    'newCustomerSite',
    'newVehicleNo',
    'newProduct',
    'newTransporter',
    'newDoDate',
    'cancel',
    'action'
  ];
  @ViewChild(PageableDatatableComponent)
  datatable!: PageableDatatableComponent<DoModification>;

  constructor(
    private dialog: MatDialog,
    private customerService: DoModificationService,
  ) {
  }

  searchDoModifications = (request: PagingRequest<DoModification>) => {
    this.loading = true;
    return this.customerService.searchDoModifications(request, this.doNo)
      .pipe(finalize(() => this.loading = false));
  }

  reload(): void {
    this.datatable.reload();
  }
}
