import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { InvoiceHeader } from "../invoice-header";
import { MatSnackBar } from "@angular/material/snack-bar";
import { finalize } from "rxjs/operators";
import { InvoiceService } from "../invoice-service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-search-do',
  templateUrl: './search-invoice.component.html',
  styleUrls: ['./search-invoice.component.css'],
  providers: [InvoiceService]
})
export class SearchInvoiceComponent {

  loading = false;
  formGroup: FormGroup;
  searchInvoiceData: InvoiceHeader[] = [];
  allCheck = false;
  checks: boolean[] = [];
  reportDisplayedColumns = [
    "check",
    "viewInvoice",
    "invoiceNo",
    "invoiceDate",
    "customerCode",
    "customerName",
    "locationCode",
    "locationName",
    "invoiceAmount",
  ]

  constructor(private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private invoiceService: InvoiceService,
              private router: Router
  ) {
    this.formGroup = formBuilder.group({
      fromInvoiceNo: new FormControl(''),
      toInvoiceNo: new FormControl(''),
      fromInvoiceDate: new FormControl(''),
      toInvoiceDate: new FormControl(''),
      fromCustomerCode: new FormControl(''),
      toCustomerCode: new FormControl(''),
      fromLocationCode: new FormControl(''),
      toLocationCode: new FormControl(''),
    });
  }

  getListData() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) return;

    this.loading = true;
    this.invoiceService.filterInvoiceHeader(this.formGroup.getRawValue()).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: data => {
        this.searchInvoiceData = data;
        this.checks = new Array(data.length).fill(false);
        this.allCheck = false;
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  clearInput() {
    this.formGroup.patchValue({
      fromInvoiceNo: '',
      toInvoiceNo: '',
      fromInvoiceDate: '',
      toInvoiceDate: '',
      fromCustomerCode: '',
      toCustomerCode: '',
      fromLocationCode: '',
      toLocationCode: '',
    })
    this.searchInvoiceData = [];
    this.checks = [];
    this.allCheck = false;
  }

  handleViewInvoice(invoiceHeader: InvoiceHeader) {
    this.router.navigate(["/invoice-detail", invoiceHeader.id]);
  }

  handleReprint() {
    if (this.checks.filter(check => check).length === 0) {
      this.snackBar.open("Please choose invoice first", 'Close', {duration: 10000});
      return;
    }
    const invoiceIds: number[] = [];
    for (let i = 0; i < this.searchInvoiceData.length; i++) {
      if (this.checks[i]) invoiceIds.push(this.searchInvoiceData[i].id);
    }
    this.invoiceService.reprintInvoicing(
      invoiceIds,
      (value: boolean) => this.loading = value,
      this.errorDisplay
    );
  }

  handleAllCheck() {
    this.checks = new Array(this.searchInvoiceData.length).fill(this.allCheck);
  }

  handleCheck() {
    this.allCheck = this.checks.length > 0 && this.checks.filter(check => !check).length === 0;
  }

  errorDisplay = (error: any) => this.snackBar.open(error, 'Hide', {duration: 10000});
}
