import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from 'src/app/common-component/dialog/dialog-service';
import { Uom } from './uom';
import { UomService } from './uom.service';
import { finalize, switchMap, tap } from "rxjs/operators";
import {Sort} from "@angular/material/sort";
import {forkJoin} from "rxjs";

@Component({
  selector: 'app-uom',
  templateUrl: './uom.component.html',
  styleUrls: ['./uom.component.css'],
  providers: [UomService],
})
export class UomComponent implements OnInit {

  uoms: Uom[] = [];
  ngOnInit(): void {
    this.reload();
  }

  compare(a: any, b: any, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  localSort(sort: Sort): void {
    this.uoms = [...this.uoms.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'activeStatus':
          return this.compare(a.activeStatus, b.activeStatus, isAsc);
        case 'uomUnit':
        default:
          return this.compare(a.uomCode, b.uomCode, isAsc);
      }
    })]
  }

  private reload() {
    this.loading = true;
    this.uomService.listUom().pipe(
      tap(data => {
        this.uoms = data;
        if (this.uoms.length < 1) this.addRow();
      })
    ).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    })
  }

  displayedColumns = [
    "uomUnit",
    "multiplier",
    "activeStatus",
    "action",
  ];

  constructor(private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: DialogService,
    private uomService:UomService,
  ){

  }
  loading = false;
  addRow = () => this.uoms = [...this.uoms, {id: 0, uomCode: '', weightInMt: 0, activeStatus:false}];

  deleteUom(price: Uom, index: number) {
    if (price.id === 0) {
      this.uoms.splice(index, 1);
      this.uoms = [...this.uoms];
      return;
    }
    this.dialog.confirm("Delete uom ", "Do you want to delete this uom?", {alert: true})
      .pipe(
        tap(() => this.loading = true),
        switchMap(() => this.uomService.deleteUom(price)),
        finalize(() => this.loading = false),
      ).subscribe({
        next: () => {
          this.uoms.splice(index, 1);
          this.uoms = [...this.uoms];
          this.snackBar.open('Delete Uom successful', 'Close', {duration: 2000});
        },
        error: (error) => this.snackBar.open(error, 'Close', {duration: 10000}),
      }
    );
  }

  save() {
    // validate table data
    const messages : any[] = [];
    const codeSet = new Set();
    this.uoms && this.uoms.forEach(uom => {
      !uom.uomCode && messages.push("Uom code is required");
      !uom.weightInMt && messages.push("Weight value is required");
      (uom.activeStatus === null || uom.activeStatus === undefined ) && messages.push("Active status is required");
      uom.uomCode && (codeSet.has(uom.uomCode) ? messages.push("Duplicated code") : codeSet.add(uom.uomCode));
    })
    if(messages.length > 0) {
      this.snackBar.open(messages as any, 'Hide', {duration: 10000});
      return;
    }

    this.loading = true;
    this.uomService.saveUom(this.uoms)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: () => {
          this.reload();
          this.snackBar.open( 'Uom save successfully', 'Close', {duration: 2000});
        },
        error: (error) => {
          this.snackBar.open(error, 'Hide', { duration: 5000 });
        }
      }
    );
  }
}
