const ICON_SEARCH = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z" fill="#F9562B"/>
</svg>`;

export class SearchControl {
  map: google.maps.Map;
  html: HTMLDivElement;
  form: HTMLFormElement;
  button: HTMLButtonElement;
  input: HTMLInputElement;
  placeholder: string;
  // searchBox!: google.maps.places.SearchBox;
  searchBox!: google.maps.places.SearchBox;
  autoComplete!: google.maps.places.Autocomplete;
  onSubmit: (search: Array<google.maps.places.PlaceResult>) => void;
  isShowInput: boolean = false;
  constructor(options: any) {
    this.map = options.map;
    this.html = document.createElement('DIV') as HTMLDivElement;
    this.form = document.createElement('FORM') as HTMLFormElement;
    this.button = document.createElement('BUTTON') as HTMLButtonElement;
    this.input = document.createElement('INPUT') as HTMLInputElement;
    this.placeholder = options.placeholder;
    this.onSubmit = options.onSubmit;
    this.initUI();
    this.initEvent();
  }

  initUI() {
    this.html.style.marginTop = '12px';
    this.html.style.marginLeft = '12px';

    this.form.style.display = 'flex';
    this.form.style.borderRadius = '6px';
    this.form.style.overflow = 'hidden';
    this.form.style.minWidth = '30px';

    this.input.style.width = '0';
    this.input.style.height = '30px';
    this.input.style.fontSize = '12px';
    this.input.style.lineHeight = '16px';
    this.input.style.padding = '0';
    this.input.style.margin = '0';
    this.input.style.outline = '0 !important';
    this.input.style.border = 'none';
    this.input.style.borderRadius = '6px 0px 0px 6px';
    this.input.style.background = 'rgba(255, 255, 255, 0.8)';
    this.input.style.boxShadow = 'inset 0px 0px 4px rgba(0, 0, 0, 0.2)';
    this.input.style.transition = 'all 0.3s ease';
    this.input.placeholder = this.placeholder;

    this.button.type = 'button';
    this.button.style.cursor = 'pointer';
    this.button.style.display = 'flex';
    this.button.style.alignItems = 'center';
    this.button.style.justifyContent = 'center';
    this.button.style.position = 'absolute';
    this.button.style.right = '0px';
    this.button.style.top = '0px';
    this.button.style.width = '30px';
    this.button.style.height = '30px';
    this.button.style.padding = '0';
    this.button.style.margin = '0';
    this.button.style.border = 'none';
    this.button.style.borderRadius = '6px';
    this.button.style.background = 'rgba(255, 255, 255, 0.8)';
    this.button.style.boxShadow = 'inset 0px 0px 4px rgba(0, 0, 0, 0.2)';


    this.button.innerHTML = ICON_SEARCH;

    this.form.appendChild(this.input);
    this.form.appendChild(this.button);

    this.html.appendChild(this.form);

    /** show input by default */
    this.isShowInput = true;
    this.input.style.background = '#FFF';
    this.input.style.width = '230px';
    this.input.style.padding = '7px 12px';
    this.input.style.border = '1px solid #E5E5E5';
    this.input.style.borderRight = 'none';
    this.input.style.boxShadow = 'none';

    this.button.type = 'submit';
    this.button.style.background = '#FFF';
    this.button.style.border = '1px solid #E5E5E5';
    this.button.style.borderLeft = 'none';
    this.button.style.borderRadius = '0px 6px 6px 0px';
    this.button.style.boxShadow = 'none';
  }

  initEvent() {
    // this.button.addEventListener('click', (e: any) => {
    //   if (!this.isShowInput) {
    //     this.isShowInput = true;
    //     this.input.style.background = '#FFF';
    //     this.input.style.width = '230px';
    //     this.input.style.padding = '7px 12px';
    //     this.input.style.border = '1px solid #E5E5E5';
    //     this.input.style.borderRight = 'none';
    //     this.input.style.boxShadow = 'none';

    //     this.button.type = 'submit';
    //     this.button.style.background = '#FFF';
    //     this.button.style.border = '1px solid #E5E5E5';
    //     this.button.style.borderLeft = 'none';
    //     this.button.style.borderRadius = '0px 6px 6px 0px';
    //     this.button.style.boxShadow = 'none';
    //   } else {
    //     this.isShowInput = false;

    //     this.input.style.width = '0';
    //     this.input.style.padding = '0';
    //     this.input.style.border = 'none';
    //     this.input.style.borderRadius = '6px 0px 0px 6px';
    //     this.input.style.background = 'rgba(255, 255, 255, 0.8)';
    //     this.input.style.boxShadow = 'inset 0px 0px 4px rgba(0, 0, 0, 0.2)';

    //     this.button.style.border = 'none';
    //     this.button.style.borderRadius = '6px';
    //     this.button.style.background = 'rgba(255, 255, 255, 0.8)';
    //     this.button.style.boxShadow = 'inset 0px 0px 4px rgba(0, 0, 0, 0.2)';
    //   }
    // });
    const center = { lat: 50.064192, lng: -130.605469 };
    // Create a bounding box with sides ~10km away from the center point
    const defaultBounds = {
      north: center.lat + 0.1,
      south: center.lat - 0.1,
      east: center.lng + 0.1,
      west: center.lng - 0.1,
    };
    const autoCompleteOptions = {
      bounds: defaultBounds,
    };
    const searchBoxOptions = {
      bounds: defaultBounds,
    };
    this.searchBox = new google.maps.places.SearchBox(this.input, searchBoxOptions);
    this.searchBox.addListener('places_changed', this.onPlaceChanges.bind(this));
    // this.autoComplete = new google.maps.places.Autocomplete(this.input, autoCompleteOptions)
    this.form.addEventListener('submit', this.onFormSubmit.bind(this));
  }

  onPlaceChanges() {
    const places: Array<google.maps.places.PlaceResult> = this.searchBox.getPlaces() as any;
    this.onSubmit(places);
  }

  onFormSubmit(e: any) {
    e.preventDefault();
    return false;
  }
}
