import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DoModification } from "../../do-modification/do-modification";
import { DoModificationService } from "../../do-modification/do-modification-service";
import { finalize } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-do-modification-popup',
  templateUrl: './do-modification-popup.component.html',
  styleUrls: ['./do-modification-popup.component.css'],
  providers: [DoModificationService]
})
export class DoModificationPopupComponent implements AfterViewInit {

  loading = true;
  doModification: DoModification = <DoModification>{};

  constructor(
    @Inject(MAT_DIALOG_DATA) public doNo: string,
    private doModificationService: DoModificationService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DoModificationPopupComponent>
  ) {
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  loadData() {
    this.doModificationService.getDoModificationByDoNo(this.doNo).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: (data) => {
        this.doModification = data;
      },
      error: (error) => {
        this.dialogRef.close();
        this.snackBar.open(error, 'Hide', {duration: 10000});
      },
    });
  }

}
