import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { InvoiceService } from "../invoice-service";

@Component({
  selector: 'app-generate-invoicing',
  templateUrl: './generate-invoicing.component.html',
  styleUrls: ['./generate-invoicing.component.css']
})
export class GenerateInvoicingComponent {
  loading = false;
  formGroup: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private invoiceService: InvoiceService,
              private snackBar: MatSnackBar,
  ) {
    this.formGroup = formBuilder.group({
      fromDate: new FormControl('', [Validators.required]),
      toDate: new FormControl('', [Validators.required]),
      fromCustomerCode: new FormControl(''),
      toCustomerCode: new FormControl(''),
      fromProductCode: new FormControl(''),
      toProductCode: new FormControl(''),
      fromLocationCode: new FormControl(''),
      toLocationCode: new FormControl(''),
    });
  }

  printPreview() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) return;
    let rawValue = this.formGroup.getRawValue();
    rawValue.fromDateStr = rawValue.fromDate.format('yyyy-MM-DD');
    rawValue.toDateStr = rawValue.toDate.format('yyyy-MM-DD');
    this.invoiceService.previewInvoiceOrInvoiceException(
      rawValue,
      (value: boolean) => this.loading = value,
      this.errorDisplay
    );
  }

  generateInvoicing() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) return;
    let rawValue = this.formGroup.getRawValue();
    rawValue.fromDateStr = rawValue.fromDate.format('yyyy-MM-DD');
    rawValue.toDateStr = rawValue.toDate.format('yyyy-MM-DD');
    this.invoiceService.generateInvoicingOrInvoiceException(
      rawValue,
      (value: boolean) => this.loading = value,
      this.errorDisplay
    );
  }

  errorDisplay = (error: any) => this.snackBar.open(error, 'Hide', {duration: 10000});
}
