import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { ReportAbstractService } from "../report-abstract-service";
import { HttpClient } from "@angular/common/http";
import { CustomerDetailedReportRecord } from "./customer-detailed-report-record";

@Injectable()
export class CustomerDetailedReportService extends ReportAbstractService<CustomerDetailedReportRecord[]> {
  public constructor(private _http: HttpClient) {
    super(
      _http,
      "Customer Detailed Report",
      environment.API_ENDPOINT + '/report/customer-detailed-report/',
    );
  }

  processParams(params: any): any {
    params.fromDate = params.fromDate.format('yyyy-MM-DD');
    params.toDate = params.toDate.format('yyyy-MM-DD');
    return params;
  }

}
