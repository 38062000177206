import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Customer, CustomerTypes} from "../../customer/customer";
import {finalize, map, pairwise, switchMap, tap} from "rxjs/operators";
import {PriceService} from "../price-service";
import {PriceTerms, ProductPrice, TransportPrice} from "../price";
import {BehaviorSubject, combineLatest, forkJoin} from "rxjs";
import {CustomerService} from "../../customer/customer-service";
import {ProductTemplate, ProductTemplatePrice} from "../../product-template/product-template";
import {ProductService} from "../../product/product-service";
import {ProductTemplateService} from "../../product-template/product-template-service";
import {SuggestInputData} from "../../../common-component/suggest-input/suggest-input";
import {DialogService} from "../../../common-component/dialog/dialog-service";
import {LocationTemplateService} from "../../location-template/location-template.service";
import {LocationTemplate, LocationTemplatePrice} from "../../location-template/location-template";
import {LocationService} from "../../location/location-service";
import {UomService} from "../../uom/uom.service";
import {Uom} from "../../uom/uom";
import {parseDate} from "../../../common/utilities";
import {PriceTermService} from "../../price-term/price-term.service";
import {Product} from "../../product/product";
import {Location} from "../../location/location";
import {PriceTerm} from "../../price-term/price-term";

@Component({
  selector: 'app-price-detail',
  templateUrl: './price-detail.component.html',
  styleUrls: ['./price-detail.component.css'],
  providers: [PriceService, PriceTermService, ProductTemplateService, LocationTemplateService, LocationService, UomService]
})
export class PriceDetailComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private priceTermService: PriceTermService,
              private priceService: PriceService,
              private customerService: CustomerService,
              formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private productService: ProductService,
              private productTemplateService: ProductTemplateService,
              private locationTemplateService: LocationTemplateService,
              private locationService: LocationService,
              private uomService: UomService,
              private dialog: DialogService,
  ) {
    const address = new FormControl('', [Validators.required]);
    const mailingAddress = new FormControl('', [Validators.required]);
    const sameAddress = new FormControl(false);
    const customerName = new FormControl('', [Validators.required]);
    const customerCode = new FormControl('', [Validators.required]);
    this.formGroup = formBuilder.group({
      customerName, customerCode,
      customerType: new FormControl(CustomerTypes[0], [Validators.required]),
      customerAddress: address,
      customerMailingAddress: mailingAddress,
      quotationRef: new FormControl('', [Validators.required]),
      contractStartDate: new FormControl('', [Validators.required]),
      contractEndDate: new FormControl(''),
      terms: new FormControl('', [Validators.required]),
      remarks: new FormControl(''),
      sameAddress: sameAddress
    });
    address.valueChanges.subscribe({
      next: val => {
        this.addressKeyword.next(val || '');
        if (sameAddress.value) mailingAddress.patchValue(val);
      }
    });
    mailingAddress.valueChanges.subscribe({
      next: val => {
        if (!sameAddress.value) return;
        this.addressKeyword.next(val || '');
      }
    });
    sameAddress.valueChanges.subscribe({
      next: val => {
        if (val) {
          mailingAddress.disable();
          mailingAddress.patchValue(address.value);
        } else {
          mailingAddress.enable();
        }
      }
    })
    customerCode.valueChanges.pipe(pairwise()).subscribe(
      ([, next]: [any, any]) => {
        if (typeof next === 'string') return;
        customerName.patchValue(next);
      });
    let prevCustomer: any = null;
    let customerCancelUpdateFlag = false;
    customerName.valueChanges.pipe(pairwise()).subscribe(
      ([prev, next]: [any, any]) => {
        if (typeof next === 'string') return;
        prevCustomer = typeof prev === 'string' ? prevCustomer : prev;
        if (prevCustomer === next) return;

        const havePrice = this.transportPrices && this.transportPrices.length > 0;
        if (!havePrice || !prevCustomer || !next || prevCustomer.id === next.id || customerCancelUpdateFlag) {
          customerCode.patchValue(next);
          if (next) this.locationService.listLocationCodes(next.id).subscribe(r => this.locations = r)
          customerCancelUpdateFlag = false;
          return;
        }
        const handleClose = (value: boolean) => {
          if (!value) {
            customerCancelUpdateFlag = true;
            customerName.setValue(prevCustomer);
          }
        }
        this.dialog.confirm("Change customer", "Clear current transport prices and change customer?", {}, handleClose)
          .subscribe({
            next: confirm => {
              if (!confirm) return;
              customerCode.patchValue(next);
              if (next) this.locationService.listLocationCodes(next.id).subscribe(r => this.locations = r)
              this.transportPrices = [];
            }
          })
      }
    )
    customerName.patchValue('');
  }

  selectedCustomer = new BehaviorSubject<Customer | null>(null);
  addressKeyword = new BehaviorSubject<string>("");

  suggestAddresses = combineLatest([
    this.selectedCustomer.pipe(
      map(customer => customer ? [customer.address1, customer.address2, customer.address3] : []),
    ),
    this.addressKeyword.asObservable(),
  ]).pipe(
    map(([addresses, key]) => addresses
      .filter(address => address !== null && address.trim().length > 0)
      .filter(address => address.includes(key))
    )
  );

  updateCustomer = (customer: Customer | null) => {
    this.selectedCustomer.next(customer);
    if (!customer) return;
    this.formGroup.get("customerType")?.patchValue(customer.type);
  }

  loading = true;

  priceId = 0;
  isRevise = false;
  formGroup: FormGroup;
  types = CustomerTypes;
  priceTerms = PriceTerms;

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    if (id === 'new') {
      forkJoin({
        customers: this.customerService.listCustomers().pipe(
          tap(r => this.customers = r)
        ),
        terms: this.priceTermService.listPriceTerms().pipe(
          tap(r => this.terms = r)
        ),
        productTemplates: this.productTemplateService.listTemplates().pipe(
          tap(r => this.productTemplates = r)
        ),
        locationTemplates: this.locationTemplateService.listTemplates().pipe(
          tap(r => this.locationTemplates = r)
        ),
        products: this.productService.listProducts().pipe(
          tap(r => this.products = r)
        ),
        uom: this.uomService.listUom().pipe(
          tap(r => this.uom = r)
        ),
      }).pipe(
        finalize(() => this.loading = false)
      ).subscribe({
        error: (error) => {
          this.snackBar.open(error, 'Hide', {duration: 10000});
        },
      });
      return;
    }
    const queryParams = this.route.snapshot.queryParamMap;
    this.isRevise = queryParams.get("isRevise") === "true";
    this.priceId = id;
    this.loading = true;
    forkJoin({
      customers: this.customerService.listCustomers().pipe(
        tap(r => this.customers = r)
      ),
      terms: this.priceTermService.listPriceTerms().pipe(
        tap(r => this.terms = r)
      ),
      productTemplates: this.productTemplateService.listTemplates().pipe(
        tap(r => this.productTemplates = r)
      ),
      locationTemplates: this.locationTemplateService.listTemplates().pipe(
        tap(r => this.locationTemplates = r)
      ),
      products: this.productService.listProducts().pipe(
        tap(r => this.products = r)
      ),
      uom: this.uomService.listUom().pipe(
        tap(r => this.uom = r)
      ),
      price: this.priceService.getPrice(id).pipe(
        tap(data => {
          data.contractStartDate = parseDate(data.contractStartDate);
          data.contractEndDate = parseDate(data.contractEndDate);

          if (data.contractEndDate && this.isRevise) {
            throw new Error("Cannot revise price had contract end date!");
          }
          this.selectedCustomer.next(data.customer);
          this.formGroup.patchValue({
            customerName: data.customer,
            customerCode: data.customer,
            customerType: data.customerType,
            customerAddress: data.customerAddress,
            customerMailingAddress: data.customerMailingAddress,
            quotationRef: data.quotationRef,
            contractStartDate: data.contractStartDate,
            contractEndDate: data.contractEndDate,
            terms: data.terms,
            remarks: data.remarks
          });
        })
      ),
      productPrices: this.priceService.getProductPrices(id).pipe(
        tap(data => {
          this.productPrices = data.map(record => {
            record.product!.uom = record.uom;
            if (this.isRevise) {
              record.id = 0;
            }
            return record;
          });
        })
      ),
      transportPrices: this.priceService.getTransportPrices(id).pipe(
        tap(data => {
          this.transportPrices = data.map(record => {
            if (this.isRevise) {
              record.id = 0;
            }
            return record;
          });
        })
      )
    }).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      error: (error) => {
        this.snackBar.open(error, 'Hide', {duration: 10000});
        this.router.navigate(["/prices"]);
      },
    });
  }

  customers: Customer[] = [];
  customerCodeSuggestData = new SuggestInputData(
    () => this.customers,
    (key, c) => c.code.includes(key),
    (key, c) => c.code === key,
  )
  customerNameSuggestData = new SuggestInputData(
    () => this.customers,
    (key, c) => c.name.includes(key),
    (key, c) => c.name === key,
  )

  terms: PriceTerm[] = [];
  priceTermSuggestData = new SuggestInputData(
    () => this.terms,
    (key, t) => t.label.includes(key),
    (key, t) => t.label === key,
  )

  save() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) return;

    // validate table data
    const messages: any[] = [];
    this.productPrices && this.productPrices.forEach(productPrice => {
      productPrice.product!.id && (!productPrice.product!.uom || !productPrice.product!.uom.id) && messages.push("Uom is required");
    })
    this.transportPrices && this.transportPrices.forEach(transportPrice => {
      transportPrice.location
      && ((this.isEmptyOrZero(transportPrice.transportPricePerLoad) && this.isEmptyOrZero(transportPrice.transportPricePerTon))
        || (!this.isEmptyOrZero(transportPrice.transportPricePerLoad) && !this.isEmptyOrZero(transportPrice.transportPricePerTon)))
      && messages.push("You must set just one value for transport per load or transport per ton");
    })
    if (messages.length > 0) {
      this.snackBar.open(messages as any, 'Hide', {duration: 10000})
      return;
    }

    const action = this.priceId === 0 ? 'Create' : this.isRevise ? "Revise" : "Update";
    this.loading = true;
    this.priceService.savePrice(
      this.priceId, this.isRevise, this.formGroup.getRawValue(), this.productPrices, this.transportPrices
    ).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: () => {
        this.snackBar.open(action + ' price successful', 'Close', {duration: 2000});
        this.close();
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  isEmptyOrZero(source: any) {
    if (source === 0) return true;
    if (source === "0") return true;
    return !source;
  }

  close() {
    this.router.navigate(["/prices"]);
  }

  // Product price
  productDisplayedColumns = [
    "code",
    "description",
    "uom",
    "price",
    "action",
  ];
  productPrices: ProductPrice[] = [];
  addProductRow = () => this.productPrices = [...this.productPrices, {
    id: 0,
    price: 0,
    product: {uom: <Uom>{}} as any,
    uom: <Uom>{}
  }];

  castProductPrice(product: ProductTemplatePrice) {
    return product;
  }

  productTemplates: ProductTemplate[] = [];
  productTemplateSuggestData = new SuggestInputData(
    () => this.productTemplates,
    (key, t) => t.name.includes(key),
    (key, t) => t.name === key,
  )

  loadProductTemplate = () => {
    this.dialog.confirm("Apply template", "Clear current product prices and apply this template?")
      .subscribe({
        next: confirm => {
          if (!confirm) return;
          const productTemplate = this.productTemplateSuggestData.value;
          if (productTemplate === null) return;
          this.productTemplateService.listProductPrices(productTemplate.id).pipe(
            tap(() => this.loading = true),
            finalize(() => this.loading = false),
          ).subscribe({
            next: value => {
              this.productPrices = value.map(v => ({...v, id: 0}));
              if (this.productPrices.length < 1) this.addProductRow();
            }
          })
        }
      })
  }

  products: Product[] = [];
  productSuggestData = new SuggestInputData(
    () => this.products,
    (key, p) => p.code.includes(key),
    (key, p) => p.code === key,
  )

  uom: Uom[] = [];
  uomSuggestData = new SuggestInputData(
    () => this.uom,
    (key, t) => t.uomCode.includes(key),
    (key, t) => t.uomCode === key,
  )

  deleteProductPrice(productPrice: ProductPrice, index: number) {
    if (productPrice.id) {
      this.dialog.confirm("Delete product price", "Do you want to delete this product price?", {alert: true})
        .pipe(
          tap(() => this.loading = true),
          switchMap(() => this.priceService.deleteProductPrice(productPrice)),
          finalize(() => this.loading = false),
        )
        .subscribe({
          next: () => {
            this.productPrices = this.productPrices.filter(p => p.id !== productPrice.id);
            this.snackBar.open('Delete product price successful', 'Close', {duration: 2000});
          },
          error: (error) => this.snackBar.open(error, 'Close', {duration: 10000}),
        });
    } else {
      this.productPrices.splice(index, 1);
      this.productPrices = [...this.productPrices];
    }
  }

  // Transport price
  transportDisplayedColumns = [
    "code",
    "description",
    "uom",
    "price",
    "action",
  ];
  transportPrices: TransportPrice[] = [];
  addTransportRow = () => this.transportPrices = [...this.transportPrices, {
    id: 0,
    location: null,
    transportPricePerLoad: 0,
    transportPricePerTon: 0
  }];

  castTransportPrice(transport: LocationTemplatePrice) {
    return transport;
  }

  locationTemplates: LocationTemplate[] = [];
  locationTemplateSuggestData = new SuggestInputData(
    () => this.locationTemplates,
    (key, t) => t.name.includes(key),
    (key, t) => t.name === key,
  )

  loadTransportTemplate = () => {
    this.dialog.confirm("Apply template", "Clear current transport prices and apply this template?")
      .subscribe({
        next: confirm => {
          if (!confirm) return;
          const transportTemplate = this.locationTemplateSuggestData.value;
          if (transportTemplate === null) return;
          this.locationTemplateService.listLocationPrices(transportTemplate.id, this.formGroup.get('customer')?.value?.id).pipe(
            tap(() => this.loading = true),
            finalize(() => this.loading = false),
          ).subscribe({
            next: value => {
              this.transportPrices = value.map(v => ({...v, id: 0}));
            }
          })
        }
      })
  }

  locations: Location[] = [];
  locationSuggestData = new SuggestInputData(
    () => this.locations,
    (key, l) => l.locationCode.includes(key),
    (key, l) => l.locationCode === key,
  )

  deleteTransportPrice(transportPrice: TransportPrice, index: number) {
    if (transportPrice.id) {
      this.dialog.confirm("Delete transport price", "Do you want to delete this transport price?", {alert: true})
        .pipe(
          tap(() => this.loading = true),
          switchMap(() => this.priceService.deleteTransportPrice(transportPrice)),
          finalize(() => this.loading = false),
        )
        .subscribe({
          next: () => {
            this.transportPrices = this.transportPrices.filter(t => t.id !== transportPrice.id);
            this.snackBar.open('Delete transport price successful', 'Close', {duration: 2000});
          },
          error: (error) => this.snackBar.open(error, 'Close', {duration: 10000}),
        });
    } else {
      this.transportPrices.splice(index, 1);
      this.transportPrices = [...this.transportPrices];
    }
  }
}
