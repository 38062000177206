import {Component,OnInit} from '@angular/core';
import {FormBuilder,FormControl,FormGroup,Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute,Router} from "@angular/router";
import {finalize} from "rxjs/operators";
import {Roles, User} from "../user";
import {UserService} from "../user-service";
import {AuthenticationService} from "../../auth-route/auth-route.service";

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css'],
})
export class UserDetailComponent implements OnInit {
  currentUser?: User | null;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private authenticationService: AuthenticationService,
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.formGroup = this.formBuilder.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      changePassword: new FormControl(false),
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      role: new FormControl(Roles[0]),
      activeStatus: new FormControl(true),
    });

    this.formGroup.get('changePassword')?.valueChanges.subscribe(v => {
      this.changePass = v;
      if (this.changePass)
        this.formGroup.get('password')?.addValidators(Validators.required);
      else
        this.formGroup.get('password')?.clearValidators();
    });
  }

  loading = true;

  formGroup!: FormGroup;
  userId = 0;
  changePass = false;
  roles = Roles;

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    if (id === 'new') {
      this.loading = false;
      return;
    }

    this.loading = true;

    this.userService.getUser(id).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: data => {
        this.userId = data.id;
        this.formGroup.get('password')?.clearValidators();
        this.formGroup.patchValue({
          username: data.username,
          password: '',
          name: data.name,
          email: data.email,
          role: data.role,
          activeStatus: data.activeStatus,
        });
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    })
  }

  save() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) return;

    const action = this.userId === 0 ? 'Create' : 'Update';
    this.loading = true;
    const submitData = this.formGroup.getRawValue();
    if(submitData && submitData.password && !this.changePass && this.userId !== 0) {
      delete submitData.password;
    }
    this.userService.saveUser(this.userId, submitData).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: () => {
        this.snackBar.open(action + ' user successful', 'Close', {duration: 2000});
        this.close();
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  close() {
    this.router.navigate(["/users"]);
  }

  onCheckboxChange(event: any) {
    this.changePass = event.checked;
  }

  get formControls() {
    return this.formGroup.controls;
  }
}
