<app-scene header="Invoice Detail" [loading]="loading">
  <div>
    <h2 class="flex items-center gap-x-[8px] !font-normal !text-[16px]">
      <mat-icon>remove_red_eye</mat-icon>
      View Invoice Detail {{ invoiceHeader.invoiceNo }}
    </h2>
    <div class="overflow-auto w-[2000px]">
      <table *ngIf="!canModifyActual()" class="mb-16" mat-table [dataSource]="invoiceDetails">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-header-row *matFooterRowDef="footer"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            No data matching the filter.
          </td>
        </tr>

        <ng-container matColumnDef="SNo">
          <th mat-header-cell *matHeaderCellDef>SNo</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{ i + 1 }}
          </td>

        </ng-container>

        <ng-container matColumnDef="doNo">
          <th mat-header-cell *matHeaderCellDef>D/O No</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{ element.doNo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="customerName">
          <th mat-header-cell *matHeaderCellDef>Customer</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{ invoiceHeader.location ? invoiceHeader.location.customer.name : invoiceHeader.customer?.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="locationName">
          <th mat-header-cell *matHeaderCellDef>Location</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{ element.location ? element.location.locationName : invoiceHeader.location?.locationName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="productDesc">
          <th mat-header-cell *matHeaderCellDef>Product</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            {{ element.product?.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="doDate">
          <th mat-header-cell *matHeaderCellDef>Date/Time</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            {{ element.unloadDoneTime | date : 'dd/MM/yyy HH:mm:ss' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="vehNo">
          <th mat-header-cell *matHeaderCellDef>Veh No</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{ element.vehicleNo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="doQty">
          <th mat-header-cell *matHeaderCellDef>Qty (MT)</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-right">
            {{ element.doQty | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="productPrice">
          <th mat-header-cell *matHeaderCellDef>Unit Price</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-right whitespace-nowrap">
            $ {{ element.productPrice | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="transportPerLoad">
          <th mat-header-cell *matHeaderCellDef>TPT Per Load</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-right whitespace-nowrap">
            $ {{ element.transportPerLoad | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="grandTotal">
          <th mat-header-cell *matHeaderCellDef>Grand Total</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-right whitespace-nowrap">
            $ {{ getGrandTotal(element) | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="gstAmount">
          <th mat-header-cell *matHeaderCellDef>GST</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-right whitespace-nowrap">
            $ {{ element.gstAmount | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="netTotal">
          <th mat-header-cell *matHeaderCellDef>Net Total</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-right whitespace-nowrap">
            $ {{ calcNetTotal(element) | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actualAmount">
          <th mat-header-cell *matHeaderCellDef style="background: red !important">AMT COLLECT WITH GST</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-right">
            <mat-form-field [floatLabel]="'always'" class="flex-1">
              <span matTextPrefix>$</span>
              <input matInput class="rounded-sm" [(ngModel)]="element.actualInvoiceAmount"/>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="emptyFooter">
          <td *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="grandTotalTotal">
          <td *matFooterCellDef class="border-y-2 border-black px-4 !text-right whitespace-nowrap">
            $ {{ getGrandTotalTotal() | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="gstTotal">
          <td *matFooterCellDef class="border-y-2 border-black px-4 !text-right whitespace-nowrap">
            $ {{ getGSTTotal() | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="netTotalTotal">
          <td *matFooterCellDef class="border-y-2 border-black px-4 !text-right whitespace-nowrap">
            $ {{ getNetTotalTotal() | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="totalText">
          <td *matFooterCellDef class="border-y-2 border-black px-4 !text-right">
            Grand Total QTY:
          </td>
        </ng-container>
      </table>
      <table *ngIf="canModifyActual()" class="mb-16" mat-table [dataSource]="invoiceDetails">
        <tr mat-header-row *matHeaderRowDef="displayedColumnsWithActualAmount"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsWithActualAmount;"></tr>
        <tr mat-header-row *matFooterRowDef="footer1"></tr>
        <tr mat-header-row *matFooterRowDef="footer2"></tr>
        <tr mat-header-row *matFooterRowDef="footer3"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            No data matching the filter.
          </td>
        </tr>

        <ng-container matColumnDef="SNo">
          <th mat-header-cell *matHeaderCellDef>SNo</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{ i + 1 }}
          </td>

        </ng-container>

        <ng-container matColumnDef="doNo">
          <th mat-header-cell *matHeaderCellDef>D/O No</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{ element.doNo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="customerName">
          <th mat-header-cell *matHeaderCellDef>Customer</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{ invoiceHeader.location ? invoiceHeader.location.customer.name : invoiceHeader.customer?.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="locationName">
          <th mat-header-cell *matHeaderCellDef>Location</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{ element.location ? element.location.locationName : invoiceHeader.location?.locationName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="productDesc">
          <th mat-header-cell *matHeaderCellDef>Product</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            {{ element.product?.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="doDate">
          <th mat-header-cell *matHeaderCellDef>Date/Time</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            {{ element.unloadDoneTime | date : 'dd/MM/yyy HH:mm:ss' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="vehNo">
          <th mat-header-cell *matHeaderCellDef>Veh No</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{ element.vehicleNo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="doQty">
          <th mat-header-cell *matHeaderCellDef>Qty (MT)</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-right">
            {{ element.doQty | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="productPrice">
          <th mat-header-cell *matHeaderCellDef>Unit Price</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-right whitespace-nowrap">
            $ {{ element.productPrice | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="transportPerLoad">
          <th mat-header-cell *matHeaderCellDef>TPT Per Load</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-right whitespace-nowrap">
            $ {{ element.transportPerLoad | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="grandTotal">
          <th mat-header-cell *matHeaderCellDef>Amount</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-right whitespace-nowrap">
            $ {{ getGrandTotal(element) | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="gstAmount">
          <th mat-header-cell *matHeaderCellDef>GST</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-right whitespace-nowrap">
            $ {{ element.gstAmount | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="netTotal">
          <th mat-header-cell *matHeaderCellDef>Net Total</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-right whitespace-nowrap">
            $ {{ calcNetTotal(element) | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actualAmount">
          <th mat-header-cell *matHeaderCellDef style="background: red !important">AMT COLLECT WITH GST</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-right">
            <mat-form-field [floatLabel]="'always'" class="flex-1">
              <span matTextPrefix>$</span>
              <input matInput class="rounded-sm" [(ngModel)]="element.actualInvoiceAmount"/>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="remark">
          <th mat-header-cell *matHeaderCellDef style="background: red !important">Remarks</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-right">
            <mat-form-field [floatLabel]="'always'" class="flex-1">
              <input matInput class="rounded-sm" [(ngModel)]="element.remark"/>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="emptyFooter">
          <td *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="grandTotalTotal">
          <td *matFooterCellDef class="border-y-2 border-black px-4 !text-right whitespace-nowrap">
            $ {{ getGrandTotalTotal() | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="gstTotal">
          <td *matFooterCellDef class="border-y-2 border-black px-4 !text-right whitespace-nowrap">
            $ {{ getGSTTotal() | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="netTotalTotal">
          <td *matFooterCellDef class="border-y-2 border-black px-4 !text-right whitespace-nowrap">
            $ {{ getNetTotalTotal() | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="totalText">
          <td *matFooterCellDef class="border-y-2 border-black px-4 !text-right">
            Grand Total QTY:
          </td>
        </ng-container>

        <ng-container matColumnDef="actualAmountTotal">
          <td *matFooterCellDef class="border-y-2 border-black px-4 !text-right !bg-red text-white whitespace-nowrap">
            $ {{ getActualAmountTotal() | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actualAmountTotalNoGst">
          <td *matFooterCellDef class="border-y-2 border-black px-4 !text-right !bg-red text-white whitespace-nowrap">
            $ {{ getActualAmountTotalNoGst() | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actualAmountGst">
          <td *matFooterCellDef class="border-y-2 border-black px-4 !text-right !bg-red text-white whitespace-nowrap">
            $ {{ getActualGst() | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="grandTotalDiff">
          <td *matFooterCellDef class="border-y-2 border-black px-4 !text-right whitespace-nowrap">
            $ {{ getActualAmountTotalNoGst() - getGrandTotalTotal() | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="gstDiff">
          <td *matFooterCellDef class="border-y-2 border-black px-4 !text-right whitespace-nowrap">
            $ {{ getActualGst() - getGSTTotal() | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="netTotalDiff">
          <td *matFooterCellDef class="border-y-2 border-black px-4 !text-right whitespace-nowrap">
            $ {{ getActualAmountTotal() - getNetTotalTotal() | number : '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actualDiff">
          <td *matFooterCellDef class="border-y-2 border-black px-4 !text-right">
            $ 0.00
          </td>
        </ng-container>

        <ng-container matColumnDef="diffText">
          <td *matFooterCellDef class="border-y-2 border-black px-4 !text-right !bg-red text-white">
            LESS DISCOUNT ALLOWED
          </td>
        </ng-container>

        <ng-container matColumnDef="actualText">
          <td *matFooterCellDef class="border-y-2 border-black px-4 !text-right !bg-red text-white">
            TOTAL SALES COLLECTED
          </td>
        </ng-container>
      </table>
    </div>
  </div>
  <div class="float-right" footer>
    <button mat-button (click)="handleExcel()" class="confirm-btn">
      <mat-icon>save_alt</mat-icon>
      Download Excel
    </button>
    <button mat-button (click)="handlePrint()" class="ml-5 confirm-btn">
      <mat-icon>local_printshop</mat-icon>
      Print Page
    </button>
    <button mat-button (click)="close()" class="ml-5 cancel-btn">Close</button>
    <button mat-button (click)="save()" *ngIf="canModifyActual()" class="ml-5 confirm-btn">Save</button>
  </div>
</app-scene>

