<app-scene [loading]="loading" header="Do Modification">
  <div [formGroup]="formGroup" class="max-w-2xl custom-form">
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>DO No.</mat-label>
        <input formControlName="doNo" matInput>
      </mat-form-field>
      <div class="flex-1"></div>
    </div>
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Error Cause</mat-label>
        <input formControlName="errorCause" matInput>
      </mat-form-field>
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Other Cause</mat-label>
        <input formControlName="otherCause" matInput>
      </mat-form-field>
    </div>
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>New DO Quantity</mat-label>
        <input formControlName="doQty" matInput type="number">
      </mat-form-field>
      <app-suggest-input label="New Customer Site" class="flex-1"
                         [control]="formGroup.get('newCustomerSite')"
                         [valueField]="'locationName'"
                         [nameField]="['locationName']"
                         [optionField]="['locationName', 'locationCode']"
                         [suggestData]="locationSuggestData"
      >
      </app-suggest-input>
    </div>
    <div class="flex space-x-5">
      <app-suggest-input label="New Product" class="flex-1"
                         [control]="formGroup.get('newProduct')"
                         [valueField]="'description'"
                         [nameField]="['description']"
                         [optionField]="['description', 'code']"
                         [suggestData]="productSuggestData"
      >
      </app-suggest-input>
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>New Vehicle No</mat-label>
        <input formControlName="newVehicleNo" matInput>
      </mat-form-field>
    </div>
    <div class="flex space-x-5 items-center">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>New Do Date</mat-label>
        <input [matDatepicker]="newDoDate" formControlName="newDoDate" matInput>
        <mat-datepicker-toggle [for]="newDoDate" matIconSuffix></mat-datepicker-toggle>
        <mat-datepicker #newDoDate></mat-datepicker>
      </mat-form-field>
      <mat-checkbox class="flex-1" formControlName="cancel">
        Is Cancel
      </mat-checkbox>
    </div>
  </div>
  <div class="float-right" footer>
    <button (click)="close()" class="mr-5 cancel-btn" mat-button>Close</button>
    <button *ngIf="!hadInvoice || doModificationId === 0" (click)="save()" class="confirm-btn" mat-button>Save</button>
  </div>
</app-scene>
