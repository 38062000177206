<app-scene [header]="'Search Location'" [loading]="loading">
  <div class="w-[90%]  pb-4">
    <app-suggest-input [(value)]="customerSuggestData.value"
                       placeholder="Search customer"
                       [valueField]="'name'"
                       [nameField]="['name']"
                       [suggestData]="customerSuggestData"
                       (valueChange)="reload()"
    >
    </app-suggest-input>
    <div class="flex" style="padding-bottom: 22px">
      <button [routerLink]="['/location', 'new']" class="text-btn" style="margin: auto 20px">
        <span class="material-symbols-outlined">add</span>
        <span>Add location</span>
      </button>
    </div>
  </div>
  <div class="w-[90%] space-y-10 mb-auto overflow-hidden">
    <div class="h-4/5">
      <app-pageable-datatable [getDataFn]="searchLocations"  matSort (matSortChange)="sortChange($event)">
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            No data matching the filter.
          </td>
        </tr>
        <<tr mat-header-row
          *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <ng-container matColumnDef="customerName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Customer Name</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            {{element.customer.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="locationCode">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Location Code</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            {{element.locationCode}}
          </td>
        </ng-container>

        <ng-container matColumnDef="locationName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Location Name</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            {{element.locationName}}
          </td>
        </ng-container>

        <ng-container matColumnDef="latitude">
          <th mat-header-cell *matHeaderCellDef>Latitude</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <span style="word-break: break-all">
              {{element.latitude}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="longitude">
          <th mat-header-cell *matHeaderCellDef>Longitude</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <span style="word-break: break-all">
              {{element.longitude}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="radius">
          <th mat-header-cell *matHeaderCellDef>Radius</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            {{element.radius}}
          </td>
        </ng-container>

        <ng-container matColumnDef="locationType">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Location Type</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            {{element.locationType}}
          </td>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef> Action</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <button mat-icon-button
                    *ngIf="element.id > 0"
                    [routerLink]="['/location', element.id]">
              <span class="material-symbols-outlined text-primary">edit</span>
            </button>
            <button mat-icon-button (click)="deleteLocation(element)">
                            <span class="material-symbols-outlined text-alert">
                                delete
                            </span>
            </button>
          </td>
        </ng-container>

      </app-pageable-datatable>
    </div>
  </div>
</app-scene>
