import { Component, ViewChild } from '@angular/core';
import { Customer } from './customer';
import { CustomerService } from './customer-service';
import { PagingRequest } from "../../common-component/pageable-datatable/pageable";
import { MatDialog } from "@angular/material/dialog";
import { PageableDatatableComponent } from "../../common-component/pageable-datatable/pageable-datatable.component";
import { finalize } from "rxjs/operators";
import {Sort} from "@angular/material/sort";
import {User} from "../user/user";

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  providers: [CustomerService],
  styleUrls: ['./customer.component.css'],
})
export class CustomerComponent {
  constructor(
    private dialog: MatDialog,
    private customerService: CustomerService,
  ) {
  }

  loading = true;

  displayedColumns: string[] = [
    'id',
    'code',
    'name',
    'type',
    'address',
    'contact',
    'activeStatus',
    'action'
  ];

  sort: Sort | null = null;

  sortChange(sort: Sort) {
    this.sort = sort;
    this.reload();
  }

  @ViewChild(PageableDatatableComponent)
  datatable!: PageableDatatableComponent<Customer>;

  keyword = "";

  searchCustomers = (request: PagingRequest<Customer>) => {
    this.loading = true;
    if (this.sort && this.sort.direction !== "") {
      request.sortDir = this.sort.direction;
      request.sortBy = this.sort.active as keyof Customer;
    }
    return this.customerService.searchCustomers(request, {key: this.keyword})
      .pipe(finalize(() => this.loading = false));
  }

  reload(): void {
    this.datatable.reload();
  }
}
