export const DO_TYPE = "Do"

export const MiscTransactionType = ["Misc Receipt", "Stock Gain", "Stock Lost", DO_TYPE] as const;

export const MiscTransactionTypeAll = ["All", ...MiscTransactionType] as const;

export interface MiscTransaction {
  id: number,
  transactionDate: Date,
  type: typeof MiscTransactionType[number],
  productCode: string,
  productName?: string;
  qty: number,
  balance?: number,
  supplierName?: string,
  countryOfOrigin?: string,
  invoiceDate?: Date,
  invoiceNo?: string,
  rawMaterialCost?: number,
  freightCharges?: number,
  distanceCharges?: number,
  subTotalCostPrice?: number,
  carriageCharges?: number,
  netTotalCostPrice?: number,
  totalCost?: number,
  remarks: string,
  attachments?: File[]
}

export interface MiscTransactionSearch {
  productCode?: string,
  transactionStartDate?: Date,
  transactionEndDate?: Date
  type: typeof MiscTransactionTypeAll[number]
}
