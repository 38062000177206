import { Customer, CustomerTypes } from "../customer/customer";
import { Product } from "../product/product";
import { Location } from "../location/location";
import { Uom } from "../uom/uom";

export const PriceTerms = ['CASH', '15 DAYS', '30 DAYS', '45 DAYS'] as const;

export interface Price {
  id: number,
  customer: Customer,
  customerType: typeof CustomerTypes[number],
  customerAddress: string,
  customerMailingAddress: string,
  quotationRef: string,
  contractStartDate: Date | number,
  contractEndDate: Date | number,
  terms: typeof PriceTerms[number],
  remarks: string
}

export interface ProductPrice {
  id: number;

  product: Product | null;
  uom: Uom;

  price: number;
}

export interface TransportPrice {
  id: number;

  location: Location | null;
  transportPricePerLoad:number;
  transportPricePerTon:number;
}

export interface PriceSearch {
  customerCode?: string;
  contractStartDate?: Date;
  contractEndDate?: Date;
}
