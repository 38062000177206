import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";

export function handleError(error: HttpErrorResponse) {
  console.log(error);

  if (error.status === 401) {
    return throwError('Please login and try again.');
  }

  if (error.error instanceof ErrorEvent) {
    console.error('An error occurred:', error.error.message);
    return throwError('Something bad happened; please try again later.');
  }

  if (error.status !== 400) {
    console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    return throwError('Something bad happened; please try again later.');
  }

  if (error.error.length)
    return throwError(error.error);

  if(error.error instanceof Object) {
    const messages = [];
    for (const key in error.error) {
      messages.push(error.error[key]);
    }
    return throwError(messages.join(", "));
  }
  return throwError(error);
}
