<app-scene [header]="'Search Price List'" [loading]="loading">
  <div class="flex gap-x-[2%]">
    <div class="flex w-[49%] gap-x-[2%]">
      <mat-form-field class="flex-1 w-[49%]">
        <button mat-icon-button matPrefix (click)="reload()" style="margin-left: 5px">
          <mat-icon class="text-primary">search</mat-icon>
        </button>
        <input matInput placeholder="Customer code" [(ngModel)]="search.customerCode" (change)="reload()">
      </mat-form-field>
    </div>
    <div class="flex w-[49%] gap-x-[2%]">
      <mat-form-field class="w-[49%]">
        <input matInput placeholder="Contract Start Date" [(ngModel)]="search.contractStartDate" (dateChange)="validateAndReload()" [matDatepicker]="pickerContractStartDate">
        <mat-datepicker-toggle matIconSuffix [for]="pickerContractStartDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerContractStartDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="w-[49%]">
        <input matInput placeholder="Contract End Date" [(ngModel)]="search.contractEndDate" (dateChange)="validateAndReload()" [matDatepicker]="pickerContractEndDate">
        <mat-datepicker-toggle matIconSuffix [for]="pickerContractEndDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerContractEndDate></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="flex">
    <div class="flex" style="padding-bottom: 22px">
      <button [routerLink]="['/price', 'new']" class="text-btn" style="margin: auto 20px">
        <span class="material-symbols-outlined">add</span>
        <span>Add price</span>
      </button>
    </div>
  </div>
  <app-pageable-datatable [getDataFn]="searchPrices">
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        No data matching the filter.
      </td>
    </tr>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="customerName">
      <th mat-header-cell *matHeaderCellDef>Customer Name</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.customer.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="customerCode">
      <th mat-header-cell *matHeaderCellDef>Customer Code</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.customer.code }}
      </td>
    </ng-container>

    <ng-container matColumnDef="customerType">
      <th mat-header-cell *matHeaderCellDef>Customer Type</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.customerType }}
      </td>
    </ng-container>

    <ng-container matColumnDef="quotationRef">
      <th mat-header-cell *matHeaderCellDef>Quotation Ref</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.quotationRef }}
      </td>
    </ng-container>

    <ng-container matColumnDef="contractStartDate">
      <th mat-header-cell *matHeaderCellDef>Contract Start Date</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.contractStartDate | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="contractEndDate">
      <th mat-header-cell *matHeaderCellDef>Contract End Date</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.contractEndDate | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="terms">
      <th mat-header-cell *matHeaderCellDef>Terms</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.terms.label }}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <button mat-icon-button
                *ngIf="element.id > 0"
                [routerLink]="['/price', element.id]">
          <span class="material-symbols-outlined text-primary">edit</span>
        </button>
        <button mat-icon-button
                *ngIf="element.id > 0"
                (click)="deletePrice(element)">
          <span class="material-symbols-outlined text-alert">delete</span>
        </button>
        <button mat-icon-button
                *ngIf="element.id > 0 && !element.contractEndDate"
                [routerLink]="['/price', element.id]"
                [queryParams]="{isRevise: true}"
        >
          <span class="material-symbols-outlined text-primary">source_notes</span>
        </button>
      </td>
    </ng-container>
  </app-pageable-datatable>
</app-scene>
