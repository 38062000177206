import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sample, SampleChild } from './sample';
import { SampleService } from './sample-service';
@Component({
  selector: 'app-sample',
  templateUrl: './sample.component.html',
  providers: [SampleService],
  styleUrls: ['./sample.component.css'],
})
export class SampleComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'id',
    'sampleStr',
    'sampleBool',
    'sampleValue',
    'sampleDate',
    'child',
    'childListAsStr',
    'actionCol',
  ];
  dataSource = [] as Sample[];
  tempId = 0;
  isLoading = false;
  constructor(
    private sampleService: SampleService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    // this.sampleService.getSamples().subscribe(
    //   (data: Sample[]) => {
    //     this.isLoading = true;
    //     this.setDatasource(data);
    //   },
    //   (error) => {
    //     this.processError(error);
    //     this.isLoading = false;
    //   }
    // );
  }

  ngAfterViewInit(): void {
    this.isLoading = true;
    var i = 0;
    setTimeout(() => {
      while (i < 50) {
        this.addNewRow();
        i = i + 1;
      }
      this.isLoading = false;
    }, 1500);
  }

  saveSamples(): void {
    this.sampleService.saveSample(this.dataSource).subscribe(
      (data: Sample[]) => {
        this.setDatasource(data);
      },
      (error) => {
        this.processError(error);
      }
    );
  }

  deleteSample(param: Sample): void {
    this.sampleService.deleteSample(param).subscribe(
      (data: Sample[]) => {
        this.setDatasource(data);
      },
      (error) => {
        this.processError(error);
      }
    );
  }

  addNewRow(): void {
    const sample = {
      id: this.tempId,
      sampleStr: '',
      sampleBool: true,
      sampleValue: 123,
      sampleDate: new Date(),

      child: null,
      childList: [] as SampleChild[],
      childListAsStr: '',
    } as Sample;
    this.tempId--;
    this.dataSource = [...this.dataSource, sample];
  }

  setDatasource(data: Sample[]) {
    this.dataSource = data;
    for (let sample of this.dataSource) {
      var str = '';
      for (let child of sample.childList) {
        if (str != '') {
          str += ', ';
        }
        str += child.name;
      }
      sample.childListAsStr = str;
    }

    if (this.dataSource.length == 0) {
      this.addNewRow();
    }

    console.log(this.dataSource);
    this.isLoading = false;
  }

  processError(error: any) {
    this.snackBar.open(error, 'Close', { duration: 10000 });
  }
}
