<app-scene [header]="'PriceTerm'" [loading]="loading">
  <table mat-table [dataSource]="priceTerms" class="mat-elevation-z8" matSort (matSortChange)="localSort($event)">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="label">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Label</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <mat-form-field floatLabel='auto' class="pt-2 pb-2 w-full">
          <input matInput class="rounded-sm" [(ngModel)]="element.label" />
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <button mat-icon-button (click)="deletePriceTerm(element, i)">
          <span
            class="material-symbols-outlined text-alert">
              delete
          </span>
        </button>
      </td>
    </ng-container>
  </table>

  <button class="text-btn" (click)="addRow()">
    <span class="material-symbols-outlined">add</span>
    <span>Add new row</span>
  </button>

  <div class="float-right" footer>
    <button mat-button (click)="save()" class="confirm-btn">Save</button>
  </div>
</app-scene>
