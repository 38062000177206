import { Customer } from "../customer/customer";

export const LocationType = ['Public', 'Private'] as const;
export const AcknowledgementMethod = ['App QR', 'e-Sign', 'Physical DO']

export interface Location {
  id: number;
  selfOwner: boolean;
  customerId: number;
  customer: Customer;
  locationCode: string;
  locationName: string;
  personInCharge: string;
  contactNumber: string;
  locationAddress: string;
  locationType: typeof LocationType[number];
  acknowledgementMethod: typeof AcknowledgementMethod[number];
  quantitySubmission: boolean;
  longitude: number;
  latitude: number;
  radius: number
}
