import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {forkJoin} from 'rxjs';
import {finalize, switchMap, tap} from "rxjs/operators";
import {LocationTemplateService} from '../location-template.service';
import {LocationTemplatePrice} from '../location-template';
import {LocationService} from "../../location/location-service";
import {TransportPrice} from "../../price/price";
import {DialogService} from "../../../common-component/dialog/dialog-service";
import {SuggestInputData} from "../../../common-component/suggest-input/suggest-input";
import {Location} from "../../location/location";

@Component({
  selector: 'app-location-temp-details',
  templateUrl: './location-temp-details.component.html',
  styleUrls: ['./location-temp-details.component.css'],
  providers: [LocationTemplateService, LocationService]
})
export class LocationTempDetailsComponent implements OnInit {
  constructor(private route: ActivatedRoute,
              private router: Router,
              private locationService: LocationService,
              private locationTemplateService: LocationTemplateService,
              private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private dialog: DialogService,
  ) {
    this.formGroup = formBuilder.group({
      name: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      activeStatus: new FormControl(true),
    });
  }

  loading = false;

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    if (id === 'new') {
      this.loading = true;
      this.locationService.listLocations().pipe(
        finalize(() => this.loading = false)
      ).subscribe(r => this.locations = r);
      return;
    }
    this.locationTemplateId = id;

    this.loading = true;
    forkJoin({
      locations: this.locationService.listLocations().pipe(
        tap(r => this.locations = r)
      ),
      template: this.locationTemplateService.getLocationTemplate(id).pipe(
        tap(data => this.formGroup.patchValue({
            name: data.name,
            description: data.description,
            activeStatus: data.activeStatus,
          })
        )
      ),
      details: this.locationTemplateService.listLocationPrices(id).pipe(
        tap(data => {
          this.prices = data;
        })
      )
    }).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    })
  }

  locationTemplateId = 0;
  formGroup: FormGroup;

  displayedColumns = [
    "locationCode",
    "locationName",
    "transportPricePerLoad",
    "transportPricePerTon",
    "action",
  ];
  prices: LocationTemplatePrice[] = [];
  addRow = () => this.prices = [...this.prices, {id: 0, transportPricePerLoad: 0, transportPricePerTon: 0, location: null}];

  locations: Location[] = [];
  locationSuggestData = new SuggestInputData(
    () => this.locations,
    (key, l) => l.locationCode.includes(key),
    (key, l) => l.locationCode === key,
  )

  save() {
    this.formGroup.markAllAsTouched();
    console.log('Form Values',this.formGroup,this.formGroup.valid);
    if (!this.formGroup.valid) return;

    // validate table data
    const messages : string[] = [];
    this.prices && this.prices.forEach(price => {
      price.location && price.location.id
        && !price.transportPricePerTon
        && !price.transportPricePerLoad
        && messages.push('Transport Price Per Ton is required,Transport Price Per Load is required');
    })
    if(messages.length > 0) {
      this.snackBar.open(messages as any,'Hide', {duration: 10000})
      return;
    }

    this.loading = true;
    const action = this.locationTemplateId === 0 ? 'Create' : 'Update';
    this.locationTemplateService.saveLocationTemplate(this.locationTemplateId, this.formGroup.getRawValue(), this.prices)
      .pipe(
        finalize(() => this.loading = false)
      ).subscribe({
      next: () => {
        this.snackBar.open(action + ' Location Template successful', 'Close', {duration: 2000});
        this.close();
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  close() {
    this.router.navigate(["/location-templates"]);
  }

  deletePrice(transportPrice: TransportPrice, index: number) {
    console.log(this.prices, transportPrice);
    if(transportPrice.id) {
      this.dialog.confirm("Delete location transport price", "Do you want to delete this location transport price?", {alert: true})
        .pipe(
          tap(() => this.loading = true),
          switchMap(() => this.locationTemplateService.deleteTransportPrice(transportPrice)),
          finalize(() => this.loading = false),
        )
        .subscribe({
          next: () => {
            this.prices = this.prices.filter(p => p.id !== transportPrice.id);
            this.snackBar.open('Delete location transport price successful', 'Close', {duration: 2000});
          },
          error: (error) => this.snackBar.open(error, 'Close', {duration: 10000}),
        });
    } else {
      this.prices.splice(index, 1);
      this.prices = [...this.prices];
    }
  }
}
