<app-scene header="Location" [loading]="loading">
  <div [formGroup]="formGroup" class="custom-form">
    <div class="flex space-x-[2%]">
      <div class="w-[49%]">
        <div class="flex space-x-5">
          <app-suggest-input class="flex-1"
                             label="Customer Name"
                             [control]="formGroup.get('customer')"
                             (valueChange)="updateCustomer($event)"
                             [valueField]="'name'"
                             [nameField]="['name']"
                             [suggestData]="customerSuggestData"
          >
          </app-suggest-input>
          <div class="flex-1 flex items-center">
            <mat-checkbox formControlName="selfOwner" class="flex-1">
              Self Owner
            </mat-checkbox>
          </div>
        </div>

        <div class="flex space-x-5">
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <mat-label>Location Name</mat-label>
            <input matInput formControlName="locationName"
                   (blur)="transformToUppercase('locationName'); suggestCode()"
            >
          </mat-form-field>
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <mat-label>Location Code</mat-label>
            <input matInput formControlName="locationCode">
          </mat-form-field>
        </div>
        <div class="flex space-x-5">
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <mat-label>Person In Charge</mat-label>
            <input matInput formControlName="personInCharge">
          </mat-form-field>
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <mat-label>Contact Number</mat-label>
            <input matInput formControlName="contactNumber">
          </mat-form-field>
        </div>
        <div class="flex space-x-5">
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <mat-label>Location Address</mat-label>
            <input matInput formControlName="locationAddress">
          </mat-form-field>
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <mat-label>Location Type</mat-label>
            <mat-select formControlName="locationType">
              <mat-option *ngFor="let type of locationType" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mb-4" hidden="true">
          <mat-checkbox formControlName="quantitySubmission" class="flex-1">
            Quantity Submission
          </mat-checkbox>
        </div>
        <div class="flex space-x-5">
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <mat-label>Acknowledgement Method</mat-label>
            <mat-select formControlName="acknowledgementMethod">
              <mat-option *ngFor="let method of acknowledgementMethod" [value]="method">
                {{method}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="flex-1">
            <mat-form-field [floatLabel]="'always'" class="flex-1">
              <mat-label>Radius</mat-label>
              <input matInput formControlName="radius">
            </mat-form-field>
          </div>
        </div>
        <div class="flex space-x-5">
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <mat-label>Latitude</mat-label>
            <input matInput formControlName="latitude">
          </mat-form-field>
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <mat-label>Longitude</mat-label>
            <input matInput formControlName="longitude" >
          </mat-form-field>
        </div>
      </div>
      <div class="w-[49%]">
        <gt-location-map
          [selectable]="true"
          [latitude]="formGroup.controls['latitude'].value"
          [longitude]="formGroup.controls['longitude'].value"
          [radius]="formGroup.controls['radius'].value"
          (onChangeLatLng)="onLatLngChange($event)"></gt-location-map>
      </div>
    </div>
  </div>
  <div class="float-right" footer>
    <button mat-button (click)="close()" class="mr-5 cancel-btn">Close</button>
    <button mat-button (click)="save()" class="confirm-btn">Save</button>
  </div>
</app-scene>
