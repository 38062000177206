import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-scene',
  templateUrl: './scene.component.html',
  styleUrls: ['./scene.component.css']
})
export class SceneComponent {
  @Input()
  header?: string;
  @Input()
  headerTemplate?: TemplateRef<any>;

  @Input()
  loading = false;
}
