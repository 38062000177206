export class RadiusOverlay extends google.maps.OverlayView {
  bounds_: google.maps.LatLngBounds;
  div_: HTMLElement | null;
  constructor(bounds: google.maps.LatLngBounds) {
    super();
    this.bounds_ = bounds;
    this.div_ = null;
  }

  onAdd() {
    this.div_ = document.createElement('div');
    this.div_.style.borderStyle = 'dashed';
    this.div_.style.borderWidth = '1px';
    this.div_.style.borderColor = '#F9562B';
    this.div_.style.borderRadius = '50%';
    this.div_.style.position = 'absolute';
    this.div_.classList.add('radius-overlay');
    // this.div_.innerHTML = '<div><div><div></div></div></div>';
    this.div_.innerHTML = '<div></div>';
    // <div style="border: 1px dashed #F9562B; border-radius: 50%"></div>
    const panes = this.getPanes()!;
    panes.overlayLayer.appendChild(this.div_);
  }

  draw() {
    const overlayProjection = this.getProjection();
    if (overlayProjection) {
      const sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest())!;
      const ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast())!;

      if (this.div_) {
        this.div_.style.left = sw.x + 'px';
        this.div_.style.top = ne.y + 'px';
        this.div_.style.width = ne.x - sw.x + 'px';
        this.div_.style.height = sw.y - ne.y + 'px';
      }
    }
  }

  updatePosition(bounds: google.maps.LatLngBounds) {
    this.bounds_ = bounds;
    this.draw();
  }

  onRemove() {
    if (this.div_) {
      (this.div_.parentNode as HTMLElement).removeChild(this.div_);
      this.div_ = null;
    }
  }
}
