import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProductTemplate, ProductTemplatePrice } from './product-template';
import { PagingRequest, PagingResult } from "../../common-component/pageable-datatable/pageable";
import { handleError } from "../../common/http-request";
import { throwError } from "rxjs";

@Injectable()
export class ProductTemplateService {
  base: string = environment.API_ENDPOINT + '/template/';
  productPriceBase: string = environment.API_ENDPOINT + '/template/product-price/';

  constructor(private http: HttpClient) {
  }

  searchProductTemplates(request: PagingRequest<ProductTemplate>, name: string, filterActive: boolean = true) {
    return this.http
      .get<PagingResult<ProductTemplate>>(this.base + 'page', {
        params: {...request, name, type: 'PRODUCT', filterActive}
      })
      .pipe(catchError(handleError));
  }

  listTemplates() {
    return this.http
      .get<ProductTemplate[]>(this.base + 'list/active', {
        params: {type: "PRODUCT"}
      })
      .pipe(catchError(handleError));
  }

  saveProductTemplate(id: number, params: any, prices: ProductTemplatePrice[]) {
    const body = {
      ...params,
      type: 'PRODUCT',
      productPrices: prices.filter(price => price.product?.id).map(price => ({
        id: price.id,
        price: price.price,
        uomId: price.product!.uom?.id,
        productId: price.product!.id
      }))
    };
    if (id === 0) {
      return this.http
        .post<any>(this.base + 'create', body)
        .pipe(catchError(handleError));
    }
    return this.http
      .put<any>(this.base + 'update/' + id, body)
      .pipe(catchError(handleError));
  }

  getProductTemplate(id: number) {
    return this.http
      .get<ProductTemplate>(this.base + id)
      .pipe(catchError(handleError));
  }

  listProductPrices(productTemplateId: number) {
    return this.http
      .get<ProductTemplatePrice[]>(this.productPriceBase + 'list', {
        params: {productTemplateId}
      })
      .pipe(catchError(handleError));
  }

  deleteTemplate(template: ProductTemplate) {
    if (!template.id) {
      return throwError('Invalid Param. Id not exist.');
    }

    return this.http
      .delete<any>(this.base + 'delete/' + template.id)
      .pipe(catchError(handleError));
  }

  deleteProductPrice(productPrice: ProductTemplatePrice) {
    if (!productPrice.id) {
      return throwError('Invalid Param. Id not exist.');
    }

    return this.http
      .delete<any>(this.productPriceBase + 'delete/' + productPrice.id)
      .pipe(catchError(handleError));
  }
}
