import { HttpClient } from "@angular/common/http";
import { catchError, finalize } from "rxjs/operators";
import { handleError } from "../../common/http-request";
import { Injectable } from "@angular/core";
import { handleCommonFile, parseErrorBlob } from "../../common/utilities";

@Injectable()
export abstract class ReportAbstractService<T> {

  protected constructor(
    private http: HttpClient,
    private reportName: string,
    private baseUrl: string
  ) {
  }

  abstract processParams(params: any): any;

  generateReport(params: any) {
    params = this.processParams(params);
    return this.http
      .get<T>(this.baseUrl + "generate", {params})
      .pipe(catchError(handleError));
  }

  previewPdf(params: any, loadingChange: Function, errorDisplay: Function) {
    params = this.processParams(params);
    this.getPdf(params, loadingChange)
      .subscribe({
        next: (response: any) => {
          handleCommonFile(new Blob([response], {type: response.type}), this.reportName, true)
        },
        error: err => errorDisplay(err)
      })
  }

  downloadPdf(params: any, loadingChange: Function, errorDisplay: Function) {
    params = this.processParams(params);
    this.getPdf(params, loadingChange)
      .subscribe({
        next: (response: any) => {
          handleCommonFile(new Blob([response], {type: response.type}), this.reportName)
        },
        error: err => errorDisplay(err)
      })
  }

  downloadExcel(params: any, loadingChange: Function, errorDisplay: Function) {
    params = this.processParams(params);

    loadingChange(true);
    this.http
      .get(this.baseUrl + 'excel', {responseType: 'blob' as 'json', params})
      .pipe(catchError(parseErrorBlob))
      .pipe(finalize(() => loadingChange(false)))
      .pipe(catchError(handleError))
      .subscribe({
        next: (response: any) => {
          handleCommonFile(new Blob([response], {type: response.type}), this.reportName)
        },
        error: err => errorDisplay(err)
      })
  }

  private getPdf(params: any, loadingChange: Function) {
    loadingChange(true);
    return this.http
      .get(this.baseUrl + 'pdf', {responseType: 'blob' as 'json', params})
      .pipe(catchError(parseErrorBlob))
      .pipe(finalize(() => loadingChange(false)))
      .pipe(catchError(handleError))
  }
}
