import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { catchError, finalize } from "rxjs/operators";
import { handleError } from "../../common/http-request";
import { InvoiceHeader } from "./invoice-header";
import { handleCommonFile, parseErrorBlob } from "../../common/utilities";
import { InvoiceDetail } from "./invoice-detail";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class InvoiceService {
  base: string = environment.API_ENDPOINT + '/invoice/';

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {
  }

  generateInvoicingOrInvoiceException(params: any, loadingChange: Function, errorDisplay: Function) {
    loadingChange(true);
    return this.http
      .post(this.base + 'generate', {}, {responseType: 'blob' as 'json', params})
      .pipe(finalize(() => loadingChange(false)))
      .pipe(catchError(parseErrorBlob))
      .pipe(catchError(handleError))
      .subscribe({
        next: (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          handleCommonFile(
            new Blob(binaryData, {type: dataType}),
            response.type === "application/zip" ? "Invoicing" : "Invoice Exception"
          )
          if (response.type === "application/pdf") {
            this.snackBar.open(
              "Cannot generate invoice. See invoice exception file for more detail.",
              'Hide',
              {duration: 10000}
            )
          }
        },
        error: err => errorDisplay(err)
      });
  }

  previewInvoiceOrInvoiceException(params: any, loadingChange: Function, errorDisplay: Function) {
    loadingChange(true);
    this.http
      .post(this.base + 'preview', {}, {responseType: 'blob' as 'json', params})
      .pipe(finalize(() => loadingChange(false)))
      .pipe(catchError(parseErrorBlob))
      .pipe(catchError(handleError))
      .subscribe({
        next: (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          handleCommonFile(new Blob(binaryData, {type: dataType}), "Invoicing", true)
        },
        error: err => errorDisplay(err)
      })
  }

  filterInvoiceHeader(params: any) {
    Object.keys(params).forEach(key => {
      if (params[key] === null || params[key] == '') {
        delete params[key];
      }
    });
    return this
      .http
      .get<InvoiceHeader[]>(this.base + "invoice-header/filter", {params})
      .pipe(catchError(handleError));
  }

  reprintInvoicing(invoiceIds: number[], loadingChange: Function, errorDisplay: Function) {
    loadingChange(true);
    const params = {
      invoiceIds
    }
    this.http
      .post(this.base + 'reprint', {}, {responseType: 'blob' as 'json', params})
      .pipe(finalize(() => loadingChange(false)))
      .pipe(catchError(parseErrorBlob))
      .pipe(catchError(handleError))
      .subscribe({
        next: (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          handleCommonFile(new Blob(binaryData, {type: dataType}), "Invoicing")
        },
        error: err => errorDisplay(err)
      })
  }

  printCashSaleInvoice(invoiceId: number, loadingChange: Function, errorDisplay: Function) {
    loadingChange(true);
    const params = {
      invoiceId
    }
    this.http
      .post(this.base + 'cash-sale-print', {}, {responseType: 'blob' as 'json', params})
      .pipe(finalize(() => loadingChange(false)))
      .pipe(catchError(parseErrorBlob))
      .pipe(catchError(handleError))
      .subscribe({
        next: (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          handleCommonFile(new Blob(binaryData, {type: dataType}), "Invoice Detail Report")
        },
        error: err => errorDisplay(err)
      })
  }

  downloadCashSaleExcel(invoiceId: number, loadingChange: Function, errorDisplay: Function) {
    loadingChange(true);
    const params = {
      invoiceId
    }
    this.http
      .post(this.base + 'cash-sale-excel', {}, {responseType: 'blob' as 'json', params})
      .pipe(finalize(() => loadingChange(false)))
      .pipe(catchError(parseErrorBlob))
      .pipe(catchError(handleError))
      .subscribe({
        next: (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          handleCommonFile(new Blob(binaryData, {type: dataType}), "Invoice Detail Report")
        },
        error: err => errorDisplay(err)
      })
  }

  findInvoiceDetail(invoiceHeaderID: number) {
    const params = {invoiceHeaderID};
    return this
      .http
      .get<InvoiceDetail[]>(this.base + "invoice-detail/find", {params})
      .pipe(catchError(handleError));
  }

  getInvoiceHeader(invoiceHeaderId: number) {
    return this
      .http
      .get<InvoiceHeader>(this.base + "invoice-header/" + invoiceHeaderId)
      .pipe(catchError(handleError));
  }

  updateActualAmount(data: { amount: number | string; id: number }[]) {
    return this
      .http
      .post(this.base + "invoice-detail/update-actual-amount", data)
      .pipe(catchError(handleError));
  }
}
