import { Component } from '@angular/core';
import { ReportAbstractComponent } from "../report-abstract.component";
import { ProductSummaryReportRecord } from "./product-summary-report-record";
import { FormBuilder, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ProductSummaryReportService } from "./product-summary-report-service";
import {catchError} from "rxjs/operators";
import {ProductService} from "../../product/product-service";
import {Product} from "../../product/product";
import {of} from "rxjs";
import {CustomerService} from "../../customer/customer-service";
import {LocationService} from "../../location/location-service";

@Component({
  selector: 'app-customer-product-summary-report',
  templateUrl: './product-summary-report.component.html',
  styleUrls: ['./product-summary-report.component.css'],
  providers: [ProductSummaryReportService, CustomerService, ProductService, LocationService]
})
export class ProductSummaryReportComponent extends ReportAbstractComponent<ProductSummaryReportRecord[]> {

  reportDisplayedColumns = [
    "productName",
    "totalQty",
    "totalLoad"
  ];
  reportDisplayedTotalColumns = [
    'granTotalQtyLabel',
    'totalAmountQty',
    'totalAmountLoad',
  ];

  constructor(private _formBuilder: FormBuilder,
              private _snackBar: MatSnackBar,
              private customerProductSummaryReportService: ProductSummaryReportService,
              customerService: CustomerService,
              productService: ProductService,
              locationService: LocationService

  ) {
    super(_formBuilder, _snackBar, customerProductSummaryReportService, customerService, productService, locationService);
  }

  getFieldList(): { fieldName: string; validators?: any[] }[] {
    return [
      {fieldName: "fromDate", validators: [Validators.required]},
      {fieldName: "toDate", validators: [Validators.required]},
      {fieldName: "fromProductCode"},
      {fieldName: "toProductCode"},
    ];
  }

  getSortDefault(): number {
    return 0;
  }

  getSortList(): string[] {
    return ["Product Name", "Total Qty", "Total Load"];
  }

  getTotalAmountQty(): number {
    if (!this.reportData || !this.reportData.length) return 0;
    return this.reportData.reduce((store, current) => store + current.totalQty, 0);
  }

  getTotalAmountLoad() {
    if (!this.reportData || !this.reportData.length) return 0;
    return this.reportData.reduce((store, current) => store + current.totalLoad, 0);
  }

  perfectMatchProduct: Product | null = null;
  suggestProducts: Product[] = [];

  reloadSuggestProducts = (code: string) => {
    this.perfectMatchProduct = null;
    this.suggestProducts = [];
    this.productService.findProductByCode(code).pipe(catchError(() => of(null))).subscribe(value => {
      this.perfectMatchProduct = value;
    })
    this.productService.pageProducts({page: 0, size: 5, sortBy: 'code'}, code, false, true)
      .subscribe(value => this.suggestProducts = value.content)
  };

}
