import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {InvoiceService} from "../invoice-service";
import {InvoiceDetail} from "../invoice-detail";
import {finalize, tap} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";
import {InvoiceHeader} from "../invoice-header";
import {forkJoin} from "rxjs";
import {formatNumber} from "@angular/common";

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.css']
})
export class InvoiceDetailComponent implements OnInit {

  loading = false;
  invoiceHeaderID = 0;
  invoiceDetails: InvoiceDetail[] = [];
  invoiceHeader: InvoiceHeader = <InvoiceHeader>{};
  displayedColumns: string[] = [
    "SNo",
    "doNo",
    'customerName',
    'locationName',
    'productDesc',
    'doDate',
    'vehNo',
    'doQty',
    'productPrice',
    'transportPerLoad',
    'grandTotal',
    'gstAmount',
    'netTotal',
  ];
  displayedColumnsWithActualAmount: string[] = [
    "SNo",
    "doNo",
    'customerName',
    'locationName',
    'productDesc',
    'doDate',
    'vehNo',
    'doQty',
    'productPrice',
    'transportPerLoad',
    'grandTotal',
    'gstAmount',
    'netTotal',
    'actualAmount',
    'remark',
  ];

  footer = [
    ...new Array(9).fill('emptyFooter'),
    'totalText',
    'grandTotalTotal',
    'gstTotal',
    'netTotalTotal',
  ];

  footer1 = [
    ...new Array(9).fill('emptyFooter'),
    'totalText',
    'grandTotalTotal',
    'gstTotal',
    'netTotalTotal',
    'actualAmountTotal',
    'emptyFooter',
  ];

  footer2 = [
    ...new Array(9).fill('emptyFooter'),
    'diffText',
    'grandTotalDiff',
    'gstDiff',
    'netTotalDiff',
    'actualDiff',
    'emptyFooter',
  ];

  footer3 = [
    ...new Array(9).fill('emptyFooter'),
    'actualText',
    'actualAmountTotalNoGst',
    'actualAmountGst',
    'actualAmountTotal',
    'actualAmountTotal',
    'emptyFooter',
  ];

  constructor(private route: ActivatedRoute,
              private invoiceService: InvoiceService,
              private snackBar: MatSnackBar,
              private router: Router,
  ) {
  }

  calcNetTotal(invoiceDetail: InvoiceDetail) {
    return invoiceDetail.gstAmount + invoiceDetail.invoiceAmount;
  }

  ngOnInit(): void {
    this.invoiceHeaderID = this.route.snapshot.params.id;
    this.loading = true;
    forkJoin({
      invoiceDetails: this.invoiceService.findInvoiceDetail(this.invoiceHeaderID).pipe(
        tap(data => this.invoiceDetails = data
          .sort((a, b) => {
            if (a.location.id !== b.location.id) {
              return a.location.id - b.location.id;
            }

            return a.doNo.localeCompare(b.doNo);
          })
          .map(d => {
            d.actualInvoiceAmount = formatNumber(Number(d.actualInvoiceAmount), 'en-US', '1.2-2').replace(",", '')
            return d;
          })
        )
      ),
      invoiceHeader: this.invoiceService.getInvoiceHeader(this.invoiceHeaderID).pipe(
        tap(data => this.invoiceHeader = data)
      )
    }).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });

  }

  close() {
    this.router.navigate(["/search-invoice"]);
  }

  getGrandTotal(invoiceDetail: InvoiceDetail) {
    return invoiceDetail.invoiceAmount;
  }

  getGrandTotalTotal() {
    return this.invoiceHeader.invoiceAmount;
  }

  getGSTTotal() {
    return this.invoiceHeader.gstAmount;
  }

  getNetTotalTotal() {
    return this.getGrandTotalTotal() + this.getGSTTotal();
  }

  getActualAmountTotal() {
    return this.invoiceDetails.reduce((sum, current) => sum + Number(current.actualInvoiceAmount), 0);
  }

  getGst() {
    return this.getGSTTotal() / this.getGrandTotalTotal();
  }

  getActualAmountTotalNoGst() {
    return this.getActualAmountTotal() / (1 + this.getGst());
  }

  getActualGst() {
    return this.getActualAmountTotalNoGst() * this.getGst();
  }

  canModifyActual() {
    return this.invoiceHeader.customer
      ? this.invoiceHeader.customer.name.includes('CASH SALE')
      : this.invoiceHeader.location?.customer?.name?.includes('CASH SALE')
  }

  save() {
    console.log('Data', this.invoiceDetails);

    const invalid = this.invoiceDetails.filter(d => d.actualInvoiceAmount === '');
    if (invalid.length > 0) {
      this.snackBar.open('Actual amount required', 'Hide', {duration: 5000});
      return;
    }

    this.invoiceService.updateActualAmount(this.invoiceDetails.map(d => ({
      id: d.id,
      amount: d.actualInvoiceAmount,
      remark: d.remark,
    })))
      .pipe(
        finalize(() => this.loading = false)
      ).subscribe({
      next: () => {
        this.snackBar.open('Save actual invoice amount successful', 'Close', {duration: 2000});
        this.close();
      },
      error: (error: any) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  handlePrint() {
    this.invoiceService.printCashSaleInvoice(
      this.invoiceHeaderID,
      (value: boolean) => this.loading = value,
      this.errorDisplay
    );
  }

  handleExcel() {
    this.invoiceService.downloadCashSaleExcel(
      this.invoiceHeaderID,
      (value: boolean) => this.loading = value,
      this.errorDisplay
    );
  }

  errorDisplay = (error: any) => this.snackBar.open(error, 'Hide', {duration: 10000});
}
