import { Component, ViewChild } from '@angular/core';
import { DialogService } from 'src/app/common-component/dialog/dialog-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageableDatatableComponent } from 'src/app/common-component/pageable-datatable/pageable-datatable.component';
import { PagingRequest } from 'src/app/common-component/pageable-datatable/pageable';
import { finalize, switchMap, tap } from "rxjs/operators";
import { LocationTemplateService } from './location-template.service';
import { LocationTemplate } from './location-template';

@Component({
  selector: 'app-location-template',
  templateUrl: './location-template.component.html',
  styleUrls: ['./location-template.component.css'],
  providers: [LocationTemplateService],
})
export class LocationTemplateComponent {
  constructor(
    private dialog: DialogService,
    private locationTemplateService: LocationTemplateService,
    private snackBar: MatSnackBar,
  ) {
  }

  loading = false;

  displayedColumns: string[] = [
    'id',
    'name',
    'description',
    'activeStatus',
    'action'
  ];

  @ViewChild(PageableDatatableComponent)
  datatable!: PageableDatatableComponent<LocationTemplate>;

  keyword = "";

  searchLocationTemplates = (request: PagingRequest<LocationTemplate>) => {
    this.loading = true;
    return this.locationTemplateService.searchLocationTemplates(request, this.keyword).pipe(
      finalize(() => this.loading = false),
    );
  }

  reload(): void {
    this.datatable.reload();
  }

  delete(param: LocationTemplate): void {
    this.dialog.confirm("Delete template", "Do you want to delete this template?", {alert: true})
      .pipe(
        tap(() => this.loading = true),
        switchMap(() => this.locationTemplateService.deleteTemplate(param)),
        finalize(() => this.loading = false),
      )
      .subscribe({
        next: () => {
          this.snackBar.open('Delete template successful', 'Close', {duration: 2000});
          this.reload();
        },
        error: (error) => this.snackBar.open(error, 'Close', {duration: 10000}),
      });
  }
}
