import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import {GstService} from "../gst-service";
import {parseDate} from "../../../common/utilities";

@Component({
  selector: 'app-gst-detail',
  templateUrl: './gst-detail.component.html',
  styleUrls: ['./gst-detail.component.css'],
  providers: [GstService]
})
export class GstDetailComponent implements OnInit {
  constructor(private route: ActivatedRoute,
              private router: Router,
              private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private gstService: GstService,
  ) {
    this.formGroup = formBuilder.group({
      effectiveStartDate: new FormControl('', [Validators.required]),
      effectiveEndDate: new FormControl(''),
      value: new FormControl("", [Validators.required]),
    });
  }

  loading = true;
  isRevise = false;
  formGroup: FormGroup;

  gstId = 0;

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    if (id === 'new') {
      this.loading = false;
      return;
    }

    const queryParams = this.route.snapshot.queryParamMap;
    this.isRevise = queryParams.get("isRevise") === "true";
    this.loading = true;
    this.gstService.getGst(id).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: (data) => {
        this.gstId = data.id;

        data.effectiveStartDate = parseDate(data.effectiveStartDate);
        data.effectiveEndDate = parseDate(data.effectiveEndDate);
        this.formGroup.get('password')?.clearValidators();
        this.formGroup.patchValue({
          effectiveStartDate: data.effectiveStartDate,
          effectiveEndDate: data.effectiveEndDate,
          value: data.value,
        });
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  save() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) return;

    const action = this.gstId === 0 ? 'Create' :this.isRevise ?  "Revise" : 'Update';
    this.loading = true;
    this.gstService.saveGst(this.gstId,this.isRevise, this.formGroup.getRawValue()).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: () => {
        this.snackBar.open(action + ' GST successful', 'Close', {duration: 2000});
        this.close();
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  close() {
    this.router.navigate(["/gsts"]);
  }
}
