import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../auth-route/auth-route.service";
import {MatSnackBar} from "@angular/material/snack-bar";

/**
 * Page Reset Password
 */
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.page.html',
  styleUrls: ['./reset-password.page.css']
})
export class ResetPasswordPage implements OnInit {
  form: FormGroup;
  hashID!: string;
  loading = false;
  submitted = false;
  error = null as any;
  errorMessage: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private snackBar: MatSnackBar,
  ) {
    this.form = new FormGroup({
      linkHash: new FormControl(this.hashID, [Validators.required]),
      newPassword: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required])
    }, [this.confirmPasswordValidation.bind(this)]);

  }

  get formControls() {
    return this.form.controls;
  }

  onSubmit(): any {
    this.submitted = true;
    console.log(this.form)
    if (this.form.invalid) {
      return false;
    }
    this.loading = true;
    const data = this.form.value;
    delete data.confirmPassword;
    this.authenticationService.resetPassword(data).subscribe(() => {
      this.snackBar.open("Password reset successful", "Hide", {duration: 10000});
      this.router.navigate(['/login']);
    },
      (error: any) => {
        console.log(error);
        console.log(JSON.stringify(error));
        this.error = error;

        if (error.error && !(error.error instanceof ProgressEvent)) {
          this.errorMessage = error.error;
        } else {
          this.errorMessage = error.statusText;
        }

        this.loading = false;
      });
  }

  confirmPasswordValidation(control: AbstractControl): ValidationErrors {
    const form = control as FormGroup;
    if (form.controls.newPassword.valid) {
      const newPassword = form.controls.newPassword.value;
      const confirmPassword = form.controls.confirmPassword.value;
      console.log(newPassword === confirmPassword)
      return newPassword === confirmPassword ? {} : {
        confirmPassword: "Not match"
      };
    }
    return {};
  }

  ngOnInit(): void {
    this.authenticationService.logout(false);
    this._route.params.subscribe(params => {
      if (params.hashID) {
        this.hashID = params.hashID;
        this.form.patchValue({linkHash: this.hashID})
      } else {
        this.snackBar.open("Invalid URL", 'Hide', {duration: 10000})
        this.router.navigate(['/login']);
      }
    })
  }
}
