const ICON_SCALE = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 15H5.66667M9.94444 6.05556L15 1L9.94444 6.05556ZM15 1H10.3333H15ZM15 1V5.66667V1ZM1 15L6.05556 9.94444L1 15ZM1 15V10.3333V15Z" stroke="#F9562B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const ICON_UNSCALE = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.9965 5.001H12.4181L15.7061 1.71303C15.8943 1.52485 16 1.26961 16 1.00347C16 0.737334 15.8943 0.482097 15.7061 0.29391C15.5179 0.105723 15.2627 0 14.9965 0C14.7304 0 14.4752 0.105723 14.287 0.29391L10.999 3.57188V1.00347C10.999 0.738419 10.8937 0.484222 10.7063 0.296802C10.5189 0.109381 10.2647 0.00408921 9.99962 0.00408921C9.73456 0.00408921 9.48037 0.109381 9.29295 0.296802C9.10553 0.484222 9.00023 0.738419 9.00023 1.00347V6.00038C9.00023 6.26544 9.10553 6.51963 9.29295 6.70705C9.48037 6.89447 9.73456 6.99976 9.99962 6.99976H14.9965C15.2616 6.99976 15.5158 6.89447 15.7032 6.70705C15.8906 6.51963 15.9959 6.26544 15.9959 6.00038C15.9959 5.73533 15.8906 5.48113 15.7032 5.29371C15.5158 5.10629 15.2616 5.001 14.9965 5.001ZM6.00209 8.99853H1.00518C0.740124 8.99853 0.485928 9.10382 0.298507 9.29124C0.111087 9.47866 0.00579476 9.73286 0.00579476 9.99791C0.00579476 10.263 0.111087 10.5172 0.298507 10.7046C0.485928 10.892 0.740124 10.9973 1.00518 10.9973H3.57359L0.295616 14.2853C0.201945 14.3782 0.127597 14.4887 0.0768596 14.6105C0.0261223 14.7323 0 14.8629 0 14.9948C0 15.1268 0.0261223 15.2574 0.0768596 15.3792C0.127597 15.5009 0.201945 15.6115 0.295616 15.7044C0.388521 15.7981 0.499054 15.8724 0.620838 15.9231C0.742622 15.9739 0.873247 16 1.00518 16C1.13711 16 1.26773 15.9739 1.38952 15.9231C1.5113 15.8724 1.62183 15.7981 1.71474 15.7044L5.00271 12.4164V14.9948C5.00271 15.2599 5.108 15.5141 5.29542 15.7015C5.48284 15.8889 5.73704 15.9942 6.00209 15.9942C6.26714 15.9942 6.52134 15.8889 6.70876 15.7015C6.89618 15.5141 7.00147 15.2599 7.00147 14.9948V9.99791C7.00147 9.73286 6.89618 9.47866 6.70876 9.29124C6.52134 9.10382 6.26714 8.99853 6.00209 8.99853Z" fill="#F9562B"/>
</svg>`;

export class ScaleControl {
  map: google.maps.Map;
  html : HTMLDivElement;
  button: HTMLButtonElement;
  isScaled: boolean = false;
  constructor(options: any) {
    this.map = options.map;
    this.html = document.createElement('DIV') as HTMLDivElement;
    this.button = document.createElement('BUTTON') as HTMLButtonElement;
    this.initUI();
    this.initEvent();
  }

  initUI() {

    this.html.style.marginTop = "12px";
    this.html.style.marginLeft = "12px";

    this.button.style.cursor = "pointer";
    this.button.style.display = "flex";
    this.button.style.alignItems = "center";
    this.button.style.justifyContent = "center";
    this.button.style.width = "30px";
    this.button.style.height = "30px";
    this.button.style.padding = "0";
    this.button.style.margin = "0";
    this.button.style.border = "none";
    this.button.style.borderRadius = "6px";
    this.button.style.background = "rgba(255, 255, 255, 0.8)";
    this.button.style.boxShadow = "inset 0px 0px 4px rgba(0, 0, 0, 0.2)";

    this.button.innerHTML = ICON_SCALE;

    this.html.appendChild(this.button);
  }

  initEvent() {
    this.button.addEventListener('click', () => {
      const locationHtml: HTMLElement = document.getElementById('gt-location') as HTMLElement;
      const parentHtml: HTMLElement = this.map.getDiv().parentElement as HTMLElement;

      if(this.isScaled) {
        (this.map.getDiv() as HTMLDivElement).style.width = `${parentHtml.offsetWidth}px`;
        (this.map.getDiv() as HTMLDivElement).style.height = `${parentHtml.offsetHeight}px`;
        this.button.innerHTML = ICON_SCALE;
        this.isScaled = false;
        setTimeout(() => {
          (this.map.getDiv() as HTMLDivElement).classList.remove('scaled');
        }, 300);
      } else {
        (this.map.getDiv() as HTMLDivElement).style.width = `${locationHtml.offsetWidth}px`;
        (this.map.getDiv() as HTMLDivElement).style.height = `${locationHtml.offsetHeight - 40}px`;
        (this.map.getDiv() as HTMLDivElement).classList.add('scaled');
        this.button.innerHTML = ICON_UNSCALE;
        this.isScaled = true;
      }
    })
  }
}
