import {Component, OnInit} from '@angular/core';
import {ProductTemplateService} from "../product-template-service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {finalize, switchMap, tap} from "rxjs/operators";
import {forkJoin} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ProductTemplatePrice} from "../product-template";
import {ProductService} from "../../product/product-service";
import {Uom} from "../../uom/uom";
import {UomService} from "../../uom/uom.service";
import {DialogService} from "../../../common-component/dialog/dialog-service";
import {SuggestInputData} from "../../../common-component/suggest-input/suggest-input";
import {Product} from "../../product/product";

@Component({
  selector: 'app-product-template-detail',
  templateUrl: './product-template-detail.component.html',
  styleUrls: ['./product-template-detail.component.css'],
  providers: [ProductTemplateService]
})
export class ProductTemplateDetailComponent implements OnInit {
  constructor(private route: ActivatedRoute,
              private router: Router,
              private productService: ProductService,
              private productTemplateService: ProductTemplateService,
              private uomService: UomService,
              private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private dialog: DialogService,
  ) {
    this.formGroup = formBuilder.group({
      name: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      activeStatus: new FormControl(true),
    });
  }

  loading = true;

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    if (id === 'new') {
      this.loading = true;
      forkJoin({
        products: this.productService.listProducts().pipe(
          tap(r => this.products = r)
        ),
        uom: this.uomService.listUom().pipe(
          tap(r => this.uom = r)
        ),
      }).pipe(
        finalize(() => this.loading = false)
      ).subscribe({
        error: (error) => {
          this.snackBar.open(error, 'Hide', {duration: 10000});
        },
      });
      return;
    }
    this.productTemplateId = id;

    this.loading = true;
    forkJoin({
      products: this.productService.listProducts().pipe(
        tap(r => this.products = r)
      ),
      uom: this.uomService.listUom().pipe(
        tap(r => this.uom = r)
      ),
      template: this.productTemplateService.getProductTemplate(id).pipe(
        tap(data => this.formGroup.patchValue({
            name: data.name,
            description: data.description,
            activeStatus: data.activeStatus,
          })
        )
      ),
      details: this.productTemplateService.listProductPrices(id).pipe(
        tap(data => {
          this.prices = data.map(record => {
            record.product!.uom = record.uom;
            return record;
          });
        })
      )
    }).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    })
  }

  productTemplateId = 0;
  formGroup: FormGroup;

  displayedColumns = [
    "code",
    "description",
    "uom",
    "price",
    "action",
  ];
  prices: ProductTemplatePrice[] = [];
  addRow = () => this.prices = [...this.prices, {id: 0, price: 0, product: {uom: <Uom>{}} as any, uom: <Uom>{}}];

  products: Product[] = [];
  productSuggestData = new SuggestInputData(
    () => this.products,
    (key, p) => p.code.includes(key),
    (key, p) => p.code === key,
  )

  uom: Uom[] = [];
  uomSuggestData = new SuggestInputData(
    () => this.uom,
    (key, t) => t.uomCode.includes(key),
    (key, t) => t.uomCode === key,
  )

  save() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) return;
    const messages: any[] = [];
    this.prices && this.prices.forEach(price => {
      price.product && price.product.id && (!price.product!.uom || !price.product!.uom.id) && messages.push("Uom is required");
    })
    if (messages.length > 0) {
      this.snackBar.open(messages as any, 'Hide', {duration: 10000})
      return;
    }

    this.loading = true;
    const action = this.productTemplateId === 0 ? 'Create' : 'Update';
    this.productTemplateService.saveProductTemplate(this.productTemplateId, this.formGroup.getRawValue(), this.prices)
      .pipe(
        finalize(() => this.loading = false)
      ).subscribe({
      next: () => {
        this.snackBar.open(action + ' productTemplate successful', 'Close', {duration: 2000});
        this.close();
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  close() {
    this.router.navigate(["/product-templates"]);
  }

  deletePrice(price: ProductTemplatePrice, index: number) {
    if (price.id) {
      this.dialog.confirm("Delete product template", "Do you want to delete this product template?", {alert: true})
        .pipe(
          tap(() => this.loading = true),
          switchMap(() => this.productTemplateService.deleteProductPrice(price)),
          finalize(() => this.loading = false),
        )
        .subscribe({
          next: () => {
            this.prices = this.prices.filter(p => p.id !== price.id);
            this.snackBar.open('Delete product template successful', 'Close', {duration: 2000});
          },
          error: (error) => this.snackBar.open(error, 'Close', {duration: 10000}),
        });
    } else {
      this.prices.splice(index, 1);
      this.prices = [...this.prices];
    }
  }
}
