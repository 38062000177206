<app-scene [header]="'Search Location Template'" [loading]="loading">
    <div class="flex">
      <mat-form-field class="w-[30%]">
        <button mat-icon-button matPrefix (click)="reload()" style="margin-left: 5px">
          <mat-icon class="text-primary">search</mat-icon>
        </button>
        <input matInput placeholder="Search template" [(ngModel)]="keyword" (change)="reload()">
      </mat-form-field>
      <div class="flex" style="padding-bottom: 22px">
        <button [routerLink]="['/location-template', 'new']" class="text-btn" style="margin: auto 20px">
          <span class="material-symbols-outlined">add</span>
          <span>Add new template</span>
        </button>
      </div>
    </div>
    <app-pageable-datatable [getDataFn]="searchLocationTemplates" #datatable>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No data matching the filter.
        </td>
      </tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{ element.id > 0 ? element.id : ''}}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{ element.description }}
        </td>
      </ng-container>

      <ng-container matColumnDef="activeStatus">
        <th mat-header-cell *matHeaderCellDef>Active Status</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <span class="active-status" [class.disable]="!element.activeStatus"></span>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <button mat-icon-button
                  *ngIf="element.id > 0"
                  [routerLink]="['/location-template', element.id]">
          <span
            class="material-symbols-outlined text-primary">
              edit
          </span>
          </button>
          <button mat-icon-button (click)="delete(element)">
            <span
              class="material-symbols-outlined text-alert">
                delete
            </span>
          </button>
        </td>
      </ng-container>
    </app-pageable-datatable>
  </app-scene>
