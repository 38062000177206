import {Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import {finalize, switchMap, tap} from "rxjs/operators";
import { MiscTransactionService } from "../misc-transaction-service";
import { DO_TYPE, MiscTransactionType } from "../misc-transaction";
import {forkJoin} from "rxjs";
import { ProductService } from "../../product/product-service";
import { MiscTransactionAttachment } from "../misc-transaction-attachment";
import { MiscTransactionAttachmentService } from "../misc-transaction-attachment-service";
import { DatePipe } from "@angular/common";
import { DialogService } from "../../../common-component/dialog/dialog-service";
import {Product} from "../../product/product";
import {SuggestInputData} from "../../../common-component/suggest-input/suggest-input";


@Component({
  selector: 'app-misc-transaction-detail',
  templateUrl: './misc-transaction-detail.component.html',
  styleUrls: ['./misc-transaction-detail.component.css'],
  providers: [MiscTransactionService,
    MiscTransactionAttachmentService,
    DatePipe,
  ]
})
export class MiscTransactionDetailComponent implements OnInit {
  constructor(private route: ActivatedRoute,
              private router: Router,
              private transactionService: MiscTransactionService,
              private productService: ProductService,
              private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private transactionAttachmentService: MiscTransactionAttachmentService,
              private dialogService: DialogService,
  ) {
    this.formGroup = formBuilder.group({
      transactionDate: new FormControl('', Validators.required),
      type: new FormControl(MiscTransactionType[0], Validators.required),
      productCode: new FormControl('', Validators.required),
      qty: new FormControl('', [Validators.required, Validators.min(0)]),
      supplierName: new FormControl('', Validators.required),
      countryOfOrigin: new FormControl('', Validators.required),
      invoiceDate: new FormControl('', Validators.required),
      invoiceNo: new FormControl('', Validators.required),
      rawMaterialCost: new FormControl('', Validators.required),
      freightCharges: new FormControl('', Validators.required),
      distanceCharges: new FormControl('', Validators.required),
      subTotalCostPrice: new FormControl(''),
      carriageCharges: new FormControl('', Validators.required),
      netTotalCostPrice: new FormControl(''),
      totalCost: new FormControl(''),
      remarks: new FormControl(''),
    });

    this.formGroup.controls["totalCost"].disable();
    this.formGroup.controls["netTotalCostPrice"].disable();
    this.formGroup.controls["subTotalCostPrice"].disable();

    ["rawMaterialCost", "freightCharges", "distanceCharges", "carriageCharges", "qty"].forEach(
      field => this.formGroup.controls[field].valueChanges.subscribe({
        next: () => {
          this.calcReferenceData();
        }
    }));

    this.formGroup.controls["type"].valueChanges.subscribe({
      next: val => {
        this.handleValidator(val);
      }
    })

    this.handleDisableWhenUpdate();
  }

  errorMap: Map<string, any> = new Map();

  handleValidator = (val: any) => {
    let fields = ['supplierName',
      'countryOfOrigin',
      'invoiceDate',
      'invoiceNo',
      'rawMaterialCost',
      'freightCharges',
      'distanceCharges',
      'carriageCharges'];
    if(this.types[0] === val) {
      fields.forEach(field => {
        let formField = this.formGroup.get(field);
        formField?.addValidators(Validators.required);
        this.formGroup.get(field)?.setErrors(this.errorMap.get(field));
      })
      this.errorMap.clear();
    } else {
      this.errorMap.clear();
      fields.forEach(field => {
        let formField = this.formGroup.get(field);
        this.errorMap.set(field, formField?.errors);
        formField?.clearValidators();
        formField?.setErrors(null);
      })
    }
  }

  handleDisableWhenUpdate = () => {
    if(this.miscTransactionId !== 0) {
      this.formGroup.controls["transactionDate"].disable();
      this.formGroup.controls["productCode"].disable();
      this.formGroup.controls["type"].disable();
      if(this.formGroup.controls["type"].value == DO_TYPE) {
        this.formGroup.controls["qty"].disable();
        this.formGroup.controls["remarks"].disable();
      }
    }
  }

  calcReferenceData = () => {
    let subTotalCostPrice = 0;
    ["rawMaterialCost", "freightCharges", "distanceCharges"].forEach(
      field => {
        subTotalCostPrice += Number.parseFloat(this.formGroup.get(field)?.value);
      }
    );
    let netTotalCostPrice = subTotalCostPrice + Number.parseFloat(this.formGroup.get("carriageCharges")?.value);
    let totalCost = Number.parseFloat(this.formGroup.get("qty")?.value) * netTotalCostPrice;
    this.formGroup.patchValue({
      subTotalCostPrice: isNaN(subTotalCostPrice) ? '' : subTotalCostPrice,
      netTotalCostPrice: isNaN(netTotalCostPrice) ? '' : netTotalCostPrice,
      totalCost: isNaN(totalCost) ? '' : totalCost
    })
  }

  products: Product[] = [];
  productSuggestData = new SuggestInputData(
    () => this.products,
    (key, p) => p.code.includes(key),
    (key, p) => p.code === key,
  )

  loading = true;

  miscTransactionId = 0;

  formGroup: FormGroup;
  attachmentControl: FormControl = new FormControl('', Validators.required);
  types = MiscTransactionType;
  attachments: MiscTransactionAttachment[] = [];

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    if (id === 'new') {
      this.productService.listProducts().pipe(
        finalize(() => this.loading = false)
      ).subscribe(r => this.products = r);
      return;
    }
    this.loading = true;
    forkJoin({
      products: this.productService.listProducts().pipe(
        tap(r => this.products = r)
      ),
      transaction: this.transactionService.getMiscTransaction(id).pipe(
        tap((data) => {
          this.miscTransactionId = data.id;
          this.formGroup.get('password')?.clearValidators();
          this.formGroup.patchValue({
            transactionDate: new Date(data.transactionDate),
            type: data.type,
            productCode: {
              code: data.productCode
            },
            qty: data.qty,
            supplierName: data.supplierName,
            countryOfOrigin: data.countryOfOrigin,
            invoiceDate: data.invoiceDate ? new Date(data.invoiceDate) : '',
            invoiceNo: data.invoiceNo,
            rawMaterialCost: data.rawMaterialCost,
            freightCharges: data.freightCharges,
            distanceCharges: data.distanceCharges,
            subTotalCostPrice: data.subTotalCostPrice,
            carriageCharges: data.carriageCharges,
            netTotalCostPrice: data.netTotalCostPrice,
            totalCost: data.totalCost,
            remarks: data.remarks,
          });
          this.calcReferenceData();
          this.handleValidator(data.type);
          this.handleDisableWhenUpdate();
        })
      ),
      transactionAttachment: this.transactionAttachmentService.listAttachments(id).pipe(
        tap(data  => {
          this.attachments = data;
        })
      ),
      product: this.productService.findProductByMiscTransactionId(id).pipe(
        tap(data => {
          this.formGroup.get('productCode')?.setValue(data);
        })
      )
    }).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    })
  }

  save() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) return;

    const action = this.miscTransactionId === 0 ? 'Create' : 'Update';
    this.loading = true;
    this.transactionService.saveMiscTransaction(this.miscTransactionId, {
      ...this.formGroup.getRawValue(),
      productCode: this.formGroup.get("productCode")?.value?.code,
    },
      this.attachments
    ).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: () => {
        this.snackBar.open(action + ' transaction successful', 'Close', {duration: 2000});
        this.close();
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  close() {
    this.router.navigate(["/misc-transactions"]);
  }

  getAsteriskClass(field: string) : string {
    const formField = this.formGroup.get(field);
    if(!formField || !formField.validator) return '';
    const validator = formField.validator({} as AbstractControl);
    if(validator && validator.required) {
      return ' star-inserted';
    }
    return '';
  }

  onFileChange($event: Event) {
    if(!$event.target || !(<HTMLInputElement>$event.target).files) return;
    let fileList = (($event.target as HTMLInputElement).files as FileList);
    for(let i = 0; i < fileList.length; i++) {
      let file = fileList.item(i);
      if(file == null) continue;
      this.attachments.push({
        file,
        fileName: file.name
      });
    }
  }

  downloadAttachment(file: MiscTransactionAttachment) {
    this.transactionAttachmentService.downloadAttachment(file);
  }

  deleteAttachment(attachment: MiscTransactionAttachment): void {
    if(attachment.file) {
      this.attachments = this.attachments.filter(att => att !== attachment);
      return;
    }
    this.dialogService.confirm(
      "Delete Misc Transaction Attachment",
      "Do you want to delete this transaction attachment?",
      {alert: true}
    )
      .pipe(
        tap(() => this.loading = true),
        switchMap(() => this.transactionAttachmentService.deleteMiscTransactionAttachment(attachment)),
        finalize(() => this.loading = false),
      )
      .subscribe({
        next: () => {
          this.attachments = this.attachments.filter(att => att !== attachment);
          this.snackBar.open('Delete transaction attachment successful', 'Close', {duration: 2000});
        },
        error: (error) => this.snackBar.open(error, 'Close', {duration: 10000}),
      });
  }

  getRenderTypes() : string[] {
    if(this.isDoView()) {
      return this.types as any;
    }
    return this.types.slice(0, this.types.length - 1);
  }

  isDoView() {
    return this.miscTransactionId !== 0 && this.formGroup.controls["type"].value === DO_TYPE;
  }
}
