<app-scene [header]="'Search Do Modification'" [loading]="loading">
  <div class="flex gap-x-[2%]">
    <div class="flex w-[49%] gap-x-[2%]">
      <mat-form-field class="flex-1 w-[49%]">
        <button mat-icon-button matPrefix (click)="reload()" style="margin-left: 5px">
          <mat-icon class="text-primary">search</mat-icon>
        </button>
        <input matInput placeholder="DO No" [(ngModel)]="doNo" (change)="reload()">
      </mat-form-field>
    </div>
  </div>
  <app-pageable-datatable [getDataFn]="searchDoModifications">
    <tr *matNoDataRow class="mat-row">
      <td [attr.colspan]="displayedColumns.length" class="mat-cell">
        No data matching the filter.
      </td>
    </tr>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

    <ng-container matColumnDef="doNo">
      <th *matHeaderCellDef mat-header-cell>DO No.</th>
      <td *matCellDef="let element; let i = index;" mat-cell>
        {{ element.doNo }}
      </td>
    </ng-container>

    <ng-container matColumnDef="doQty">
      <th *matHeaderCellDef mat-header-cell>New DO Qty</th>
      <td *matCellDef="let element; let i = index;" mat-cell>
        {{ element.doQty | number : '1.2-2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="errorCause">
      <th *matHeaderCellDef mat-header-cell>Error Cause</th>
      <td *matCellDef="let element; let i = index;" mat-cell>
        {{ element.errorCause }}
      </td>
    </ng-container>

    <ng-container matColumnDef="otherCause">
      <th *matHeaderCellDef mat-header-cell>Other Cause</th>
      <td *matCellDef="let element; let i = index;" mat-cell>
        {{ element.otherCause }}
      </td>
    </ng-container>

    <ng-container matColumnDef="newCustomerSite">
      <th *matHeaderCellDef mat-header-cell>New Customer Site</th>
      <td *matCellDef="let element; let i = index;" mat-cell>
        {{ element.location?.locationName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="newVehicleNo">
      <th *matHeaderCellDef mat-header-cell>New Vehicle No</th>
      <td *matCellDef="let element; let i = index;" mat-cell>
        {{ element.newVehicleNo }}
      </td>
    </ng-container>

    <ng-container matColumnDef="newProduct">
      <th *matHeaderCellDef mat-header-cell>New Product</th>
      <td *matCellDef="let element; let i = index;" mat-cell>
        {{ element.product?.description }}
      </td>
    </ng-container>

    <ng-container matColumnDef="newTransporter">
      <th *matHeaderCellDef mat-header-cell>New Transporter</th>
      <td *matCellDef="let element; let i = index;" mat-cell>
        {{ element.newTransporter }}
      </td>
    </ng-container>

    <ng-container matColumnDef="newDoDate">
      <th *matHeaderCellDef mat-header-cell>New Do Date</th>
      <td *matCellDef="let element; let i = index;" mat-cell>
        {{ element.newDoDate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="cancel">
      <th *matHeaderCellDef mat-header-cell>Is Cancel</th>
      <td *matCellDef="let element; let i = index;" mat-cell>
        <span [class.disable]="!element.cancel" class="is-cancel"></span>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th *matHeaderCellDef mat-header-cell>Action</th>
      <td *matCellDef="let element; let i = index;" mat-cell>
        <button
          *ngIf="element.id > 0"
          [queryParams]="{doNo: element.doNo}"
          [routerLink]="['/do-modification']"
          mat-icon-button
        >
          <span *ngIf="!element.hadInvoice" class="material-symbols-outlined text-primary">edit</span>
          <span *ngIf="element.hadInvoice" class="material-symbols-outlined text-primary">remove_red_eye</span>
        </button>
      </td>
    </ng-container>
  </app-pageable-datatable>
</app-scene>
