import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageableDatatableComponent } from "../../common-component/pageable-datatable/pageable-datatable.component";
import { PagingRequest } from "../../common-component/pageable-datatable/pageable";
import { finalize, switchMap, tap } from "rxjs/operators";
import { PriceService } from "./price-service";
import { Price, PriceSearch } from "./price";
import { User } from "../user/user";
import { DialogService } from "../../common-component/dialog/dialog-service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  providers: [PriceService],
  styleUrls: ['./price.component.css'],
})
export class PriceComponent {
  constructor(
    private dialog: MatDialog,
    private dialogService: DialogService,
    private priceService: PriceService,
    private snackBar: MatSnackBar,
  ) {
  }

  loading = true;

  displayedColumns: string[] = [
    'customerName',
    'customerCode',
    'customerType',
    'quotationRef',
    'contractStartDate',
    'contractEndDate',
    'terms',
    'action',
  ];

  @ViewChild(PageableDatatableComponent)
  datatable!: PageableDatatableComponent<Price>;

  search: PriceSearch = <PriceSearch>{};

  searchPrices = (request: PagingRequest<Price>) => {
    this.loading = true;
    return this.priceService.searchPrices(request, this.search).pipe(finalize(() => this.loading = false));
  }

  deletePrice(param: User): void {
    this.dialogService.confirm("Delete price", "Do you want to delete this price?", {alert: true})
      .pipe(
        tap(() => this.loading = true),
        switchMap(() => this.priceService.deletePrice(param)),
        finalize(() => this.loading = false),
      )
      .subscribe({
        next: () => {
          this.snackBar.open('Delete price successful', 'Close', {duration: 2000});
          this.reload();
        },
        error: (error) => this.snackBar.open(error, 'Close', {duration: 10000}),
      });
  }

  validateAndReload() {
    if(this.search.contractStartDate
      && this.search.contractEndDate
      && this.search.contractStartDate.getTime()
      > this.search.contractEndDate.getTime()) {
      this.snackBar.open('The contract start date must before the contract end date', 'Close', {duration: 10000})
      return;
    }
    this.reload();
  }

  reload(): void {
    this.datatable.reload();
  }
}
