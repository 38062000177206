import {NgModule} from '@angular/core';
import {RouterModule,Routes} from '@angular/router';
import {AuthGuard} from './common/auth.guard';
import {AuthRouteComponent} from './components/auth-route/auth-route.component';
import {CustomerComponent} from "./components/customer/customer.component";
import {CustomerDetailComponent} from "./components/customer/customer-detail/customer-detail.component";
import {LocationTempDetailsComponent} from './components/location-template/location-temp-details/location-temp-details.component';
import {LocationTemplateComponent} from './components/location-template/location-template.component';
import {LocationComponent} from './components/location/location.component';
import {ProductTemplateDetailComponent} from "./components/product-template/product-template-detail/product-template-detail.component";
import {ProductTemplateComponent} from "./components/product-template/product-template.component";
import {ProductComponent} from "./components/product/product.component";
import { PriceComponent } from "./components/price/price.component";
import {PriceDetailComponent} from "./components/price/price-detail/price-detail.component";

import {HomeComponent} from './components/home/home.component';
import {LoginComponent} from './components/login/login.component';
import {SampleComponent} from './components/sample/sample.component';
import {TwoComponent} from './components/two/two.component';
import {Roles} from './components/user/user';
import {UserDetailComponent} from "./components/user/user-detail/user-detail.component";
import {UserComponent} from "./components/user/user.component";
import { MiscTransactionComponent } from "./components/misc-transaction/misc-transaction.component";
import {MiscTransactionDetailComponent} from "./components/misc-transaction/misc-transaction-detail/misc-transaction-detail.component";
import { UomComponent } from './components/uom/uom.component';
import { GenerateInvoicingComponent } from "./components/invoice/generate-invoicing/generate-invoicing.component";
import { CustomerSummaryReportComponent } from "./components/report/customer-summary-report/customer-summary-report.component";
import { LocationDetailComponent } from "./components/location/location-detail/location-detail.component";
import { DailyTransactionReportComponent } from "./components/report/daily-transaction-report/daily-transaction-report.component";
import { CustomerProductSummaryReportComponent } from "./components/report/customer-product-summary-report/customer-product-summary-report.component";
import { VehicleSummaryReportComponent } from "./components/report/vehicle-summary-report/vehicle-summary-report.component";
import { ProductSummaryReportComponent } from "./components/report/product-summary-report/product-summary-report.component";
import { CustomerDetailedReportComponent } from "./components/report/customer-detailed-report/customer-detailed-report.component";
import { CustomerVehicleReportComponent } from "./components/report/customer-vehicle-report/customer-vehicle-report.component";
import { InventoryReportComponent } from "./components/report/inventory-report/inventory-report.component";
import { SearchInvoiceComponent } from "./components/invoice/search-invoice/search-invoice.component";
import { InvoiceDetailComponent } from "./components/invoice/invoice-detail/invoice-detail.component";
import { SearchDoComponent } from "./components/search-do/search-do.component";
import { DoModificationComponent } from "./components/do-modification/do-modification.component";
import { DoModificationDetailComponent } from "./components/do-modification/do-modification-detail/do-modification-detail.component";
import {ForgetPasswordComponent} from "./components/login/forget-password/forget-password.component";
import {ResetPasswordPage} from "./components/login/reset-password/reset-password.page";
import {GstComponent} from "./components/gst/gst.component";
import {GstDetailComponent} from "./components/gst/gst-detail/gst-detail.component";
import {PriceTermComponent} from "./components/price-term/price-term.component";

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
  },
  {
    path: 'ru/:hashID',
    component: ResetPasswordPage
  },
  {
    path: '',
    component: AuthRouteComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'sample',
    component: SampleComponent,
    canActivate: [AuthGuard],
    data: { roles: [] },
  },
  {
    path: 'two',
    component: TwoComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'users',
    component: UserComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'user/:id',
    component: UserDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'customers',
    component: CustomerComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'customer/:id',
    component: CustomerDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'gsts',
    component: GstComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'gst/:id',
    component: GstDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'products',
    component: ProductComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'product-templates',
    component: ProductTemplateComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'product-template/:id',
    component: ProductTemplateDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'locations',
    component: LocationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'location/:id',
    component: LocationDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'location-templates',
    component: LocationTemplateComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'location-template/:id',
    component:  LocationTempDetailsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'prices',
    component: PriceComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'price/:id',
    component: PriceDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'misc-transactions',
    component: MiscTransactionComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'misc-transaction/:id',
    component: MiscTransactionDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'price-term',
    component: PriceTermComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'uom',
    component: UomComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'generate-invoicing',
    component: GenerateInvoicingComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'report/customer-summary-report',
    component: CustomerSummaryReportComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'report/daily-transaction-report',
    component: DailyTransactionReportComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'report/customer-product-summary-report',
    component: CustomerProductSummaryReportComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'report/vehicle-summary-report',
    component: VehicleSummaryReportComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'report/product-summary-report',
    component: ProductSummaryReportComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'report/customer-detailed-report',
    component: CustomerDetailedReportComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'report/customer-vehicle-report',
    component: CustomerVehicleReportComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'report/inventory-report',
    component: InventoryReportComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'search-invoice',
    component: SearchInvoiceComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'invoice-detail/:id',
    component: InvoiceDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'search-do',
    component: SearchDoComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'do-modifications',
    component: DoModificationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
  {
    path: 'do-modification',
    component: DoModificationDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles[0], Roles[1]] },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
