import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import {finalize, tap} from "rxjs/operators";
import { LocationService } from "../location-service";
import {AcknowledgementMethod, LocationType} from "../location";
import { CustomerService } from "../../customer/customer-service";
import { Customer } from "../../customer/customer";
import {BehaviorSubject, forkJoin} from "rxjs";
import {SuggestInputData} from "../../../common-component/suggest-input/suggest-input";

@Component({
  selector: 'app-location-detail',
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.css'],
  providers: [LocationService, CustomerService]
})
export class LocationDetailComponent implements OnInit {
  constructor(private route: ActivatedRoute,
              private router: Router,
              private locationService: LocationService,
              private customerService: CustomerService,
              private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
  ) {
    this.formGroup = formBuilder.group({
      selfOwner: new FormControl(true, [Validators.required]),
      customer: new FormControl('', [Validators.required]),
      locationCode: new FormControl('', [Validators.required]),
      locationName: new FormControl('', [Validators.required]),
      personInCharge: new FormControl('', [Validators.required]),
      contactNumber: new FormControl('', [Validators.required]),
      locationAddress: new FormControl('', [Validators.required]),
      locationType: new FormControl(LocationType[0], [Validators.required]),
      acknowledgementMethod: new FormControl(AcknowledgementMethod[2], [Validators.required]),
      quantitySubmission: new FormControl(false, [Validators.required]),
      longitude: new FormControl('', [Validators.required]),
      latitude: new FormControl('', [Validators.required]),
      radius: new FormControl('', [Validators.required]),
    });
    this.formGroup.controls["longitude"].disable();
    this.formGroup.controls["latitude"].disable();

    this.selectedCustomer.subscribe(c => {
      if (c === null) {
        this.locationCodes = []
      } else {
        locationService.listLocationCodes(c.id).subscribe(locations => {
          this.locationCodes = locations.map(l => l.locationCode);
        })
      }
    })
  }

  locationCodes: string[] = [];

  transformToUppercase(controlName: string): void {
    const control = this.formGroup.get(controlName);
    if (control) {
      const value = control.value;
      control.setValue(value.toUpperCase());
    }
  }

  suggestCode() {
    console.log(this.formGroup.value.locationCode)
    if (this.formGroup.value.locationCode === '') {
      let suggestLocationCode = this.formGroup.value.customer.code + this.formGroup.value.locationName.trim().split(" ").map((s: string) => s[0]).join("");

      if (suggestLocationCode.startsWith('0')) {
        suggestLocationCode = suggestLocationCode.replace(/^0+/, '');
      }

      if (this.locationCodes.includes(suggestLocationCode)) {
        let num = 1;
        while (this.locationCodes.includes(suggestLocationCode + num)) {
          num++;
        }
        suggestLocationCode = suggestLocationCode + num;
      }

      this.formGroup.patchValue({locationCode: suggestLocationCode})
    }
  }

  selectedCustomer = new BehaviorSubject<Customer | null>(null);
  updateCustomer = (customer: Customer | null) => {
    this.selectedCustomer.next(customer);
    if (!customer) return;
    this.formGroup.get("customerType")?.patchValue(customer.type);
  }

  customers: Customer[] = [];
  customerSuggestData = new SuggestInputData(
    () => this.customers,
    (key, c) => c.name.includes(key),
    (key, c) => c.name === key,
  )

  loading = true;

  locationId = 0;

  formGroup: FormGroup;
  locationType = LocationType;
  acknowledgementMethod = AcknowledgementMethod;
  geocodingService!: google.maps.Geocoder;

  ngOnInit(): void {
    this.geocodingService = new google.maps.Geocoder();
    const id = this.route.snapshot.params.id;
    if (id === 'new') {
      this.loading = true;
      this.customerService.listCustomers().pipe(
        finalize(() => this.loading = false)
      ).subscribe(r => this.customers = r);
      return;
    }

    this.loading = true;
    forkJoin({
      customers: this.customerService.listCustomers().pipe(
        tap(r => this.customers = r)
      ),
      locations: this.locationService.getLocation(id).pipe(
        tap(data => {
          this.locationId = data.id;
          this.formGroup.get('password')?.clearValidators();
          this.formGroup.patchValue({
            selfOwner: data.selfOwner,
            customerId: data.customerId,
            customer: data.customer,
            locationCode: data.locationCode,
            locationName: data.locationName,
            personInCharge: data.personInCharge,
            contactNumber: data.contactNumber,
            locationAddress: data.locationAddress,
            locationType: data.locationType,
            acknowledgementMethod: data.acknowledgementMethod,
            quantitySubmission: data.quantitySubmission,
            longitude: data.longitude,
            latitude: data.latitude,
            radius: data.radius,
          });
        }))
    }).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  save() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) return;

    const action = this.locationId === 0 ? 'Create' : 'Update';
    this.loading = true;
    this.locationService.saveLocation(this.locationId, this.formGroup.getRawValue()).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: () => {
        this.snackBar.open(action + ' location successful', 'Close', {duration: 2000});
        this.close();
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  close() {
    this.router.navigate(["/locations"]);
  }

  onLatLngChange(latLng: any) {
    this.geocodingService.geocode(
      {
        location: new google.maps.LatLng(latLng.latitude, latLng.longitude),
      },
      (result, status) => {
        if (status == 'OK' && result && result.length > 0) {
          this.formGroup.controls.locationAddress.setValue(result[0].formatted_address);
        }
      }
    );
    this.formGroup.controls.latitude.setValue(latLng.latitude);
    this.formGroup.controls.longitude.setValue(latLng.longitude);
  }

}
