import { Component } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { VehicleSummaryReportService } from "./vehicle-summary-report-service";
import { VehicleSummaryReportRecord } from "./vehicle-summary-report-record";
import { ReportAbstractComponent } from "../report-abstract.component";
import {of, Subject} from "rxjs";
import {catchError, map, switchMap} from "rxjs/operators";
import {CustomerService} from "../../customer/customer-service";
import {LocationService} from "../../location/location-service";
import {Location} from "../../location/location";
import {ProductService} from "../../product/product-service";

@Component({
  selector: 'app-daily-transaction-report',
  templateUrl: './vehicle-summary-report.component.html',
  styleUrls: ['./vehicle-summary-report.component.css'],
  providers: [VehicleSummaryReportService, CustomerService, ProductService, LocationService]
})
export class VehicleSummaryReportComponent extends ReportAbstractComponent<VehicleSummaryReportRecord[]> {


  constructor(_formBuilder: FormBuilder,
              _snackBar: MatSnackBar,
              dailyTransactionReportService: VehicleSummaryReportService,
              customerService: CustomerService,
              productService: ProductService,
              locationService: LocationService
  ) {
    super(_formBuilder, _snackBar, dailyTransactionReportService, customerService, productService, locationService);
  }

  reportDisplayedColumns = [
    "customerName",
    "locationName",
    "transporterName",
    "vehicle",
    "totalLoad",
    "totalQty",
    "rate",
    "totalAmount",
  ]

  reportDisplayedTotalColumns = [
    'emptyFooter',
    'emptyFooter',
    'emptyFooter',
    'granTotalQtyLabel',
    "totalAmountLoad",
    "totalAmountQty",
    'emptyFooter',
    "totalAmountAmount",
  ];

  getTotalAmountQty(): number {
    if (!this.reportData || !this.reportData.length) return 0;
    return this.getFilteredData().reduce((store, current) =>  store + current.totalQty, 0);
  }

  getTotalAmountLoad() {
    if (!this.reportData || !this.reportData.length) return 0;
    return this.getFilteredData().reduce((store, current) => store + current.totalLoad, 0);
  }

  getTotalAmountAmount() {
    if (!this.reportData || !this.reportData.length) return 0;
    return this.getFilteredData().reduce((store, current) => store + current.totalAmount ?? 0, 0);
  }


  private getFilteredData() {
    console.log(this.reportData.filter(current => current.customerName != null))
    return this.reportData.filter(current => current.customerName != null);
  }

  getFieldList(): { fieldName: string; validators?: any }[] {
    return [
      {fieldName: "fromDate", validators: [Validators.required]},
      {fieldName: "toDate", validators: [Validators.required]},
      {fieldName: "fromCustomerCode"},
      {fieldName: "toCustomerCode"},
      {fieldName: "fromLocationCode"},
      {fieldName: "toLocationCode"},
      {fieldName: "transporterName"},
      {fieldName: "vehicleNo"},
    ];
  }

  getSortDefault(): number {
    return 0;
  }

  getSortList(): string[] {
    return [
      "Customer Name",
      "Location Name",
      "Transporter Name",
      "Vehicle",
      "Total Qty",
      "Total Load",
      "Rate",
      "Total Amount",
    ];
  }

  customerKeyword = new Subject<{ name?: string, code?: string }>();
  suggestCustomers = this.customerKeyword.pipe(
    switchMap(key => this.customerService
      .searchCustomers({page: 0, size: 5, sortBy: key.name ? 'name' : key.code ? 'code' : 'id'}, key)
    ),
    map(pagingResult => pagingResult.content)
  );

  perfectMatchCustomer = this.customerKeyword.pipe(
    switchMap(key => this.customerService
      .findCustomerByCode(key)
    )
  );

  perfectMatchLocation: Location | null = null;
  suggestLocations: Location[] = [];

  reloadSuggestLocations = (code: string) => {
    this.perfectMatchLocation = null;
    this.suggestLocations = [];
    this.locationService.findLocationByCode(code).pipe(catchError(() => of(null))).subscribe(value => {
      this.perfectMatchLocation = value;
    })
    this.locationService.pageLocations({page: 0, size: 5, sortBy: 'locationCode'}, code).subscribe(value => {
      this.suggestLocations = value.content;
    })
  };
}
