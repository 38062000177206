<app-scene header="Stock Transaction" [loading]="loading">
  <div [formGroup]="formGroup">
    <div class="max-w-2xl custom-form">
      <div class="flex space-x-5">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <mat-label>Transaction Date</mat-label>
          <input matInput formControlName="transactionDate" [matDatepicker]="pickerTransactionDate">
          <mat-datepicker-toggle matIconSuffix [for]="pickerTransactionDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerTransactionDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <mat-label>Transaction Type</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let type of getRenderTypes()" [value]="type">
              {{type}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex space-x-5">
        <app-suggest-input class="flex-1"
                           label="Product"
                           [control]="formGroup.get('productCode')"
                           [valueField]="'description'"
                           [nameField]="['description']"
                           [optionField]="['code', 'description']"
                           [suggestData]="productSuggestData"
        >
        </app-suggest-input>
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <mat-label>Quantity</mat-label>
          <input matInput formControlName="qty">
        </mat-form-field>
      </div>
      <div class="flex space-x-5">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <mat-label>Remarks</mat-label>
          <textarea matInput formControlName="remarks"></textarea>
        </mat-form-field>
        <div class="flex-1"></div>
      </div>
    </div>
    <div class="bg-[#f2f4ff] p-4 pb-0 rounded-r-lg"
         *ngIf="this.formGroup.get('type')?.value === types[0]">
      <div class="flex space-x-5">
        <div class="flex-1 flex space-x-5 items-center">
          <mat-label [class]="'mb-4 w-1/3' + getAsteriskClass('supplierName')">Name of Suppliers</mat-label>
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <input matInput formControlName="supplierName">
          </mat-form-field>
        </div>
        <div class="flex-1 flex space-x-5 items-center">
          <mat-label [class]="'mb-4 w-1/3' + getAsteriskClass('rawMaterialCost')">Raw Material Cost <br>(per ton)</mat-label>
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <input matInput formControlName="rawMaterialCost">
          </mat-form-field>
        </div>
        <div class="flex-1 flex space-x-5 items-center">
          <mat-label [class]="'mb-4 w-1/3' + getAsteriskClass('carriageCharges')">Carriage Charges <br>(per ton)</mat-label>
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <input matInput formControlName="carriageCharges">
          </mat-form-field>
        </div>
      </div>
      <div class="flex space-x-5">
        <div class="flex-1 flex space-x-5 items-center">
          <mat-label [class]="'mb-4 w-1/3' + getAsteriskClass('countryOfOrigin')">Country Of Origin</mat-label>
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <input matInput formControlName="countryOfOrigin">
          </mat-form-field>
        </div>
        <div class="flex-1 flex space-x-5 items-center">
          <mat-label [class]="'mb-4 w-1/3' + getAsteriskClass('freightCharges')">Freight Charges <br>(per ton)</mat-label>
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <input matInput formControlName="freightCharges">
          </mat-form-field>
        </div>
        <div class="flex-1 flex space-x-5 items-center">
          <mat-label [class]="'mb-4 w-1/3' + getAsteriskClass('netTotalCostPrice')">Net Total Cost <br>(per ton)</mat-label>
          <mat-form-field [floatLabel]="'always'" class="flex-1 clear-input">
            <input matInput formControlName="netTotalCostPrice" readonly>
          </mat-form-field>
        </div>
      </div>
      <div class="flex space-x-5">
        <div class="flex-1 flex space-x-5 items-center">
          <mat-label [class]="'mb-4 w-1/3' + getAsteriskClass('invoiceDate')">Supplier Invoice Date</mat-label>
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <input matInput formControlName="invoiceDate" [matDatepicker]="pickerInvoiceDate">
            <mat-datepicker-toggle matIconSuffix [for]="pickerInvoiceDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerInvoiceDate></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="flex-1 flex space-x-5 items-center">
          <mat-label [class]="'mb-4 w-1/3' + getAsteriskClass('distanceCharges')">Distance Charges <br>(per ton)</mat-label>
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <input matInput formControlName="distanceCharges">
          </mat-form-field>
        </div>
        <div class="flex-1 flex space-x-5 items-center">
          <mat-label [class]="'mb-4 w-1/3' + getAsteriskClass('totalCost')">Total Cost</mat-label>
          <mat-form-field [floatLabel]="'always'" class="flex-1 clear-input">
            <input matInput formControlName="totalCost" readonly>
          </mat-form-field>
        </div>
      </div>
      <div class="flex space-x-5">
        <div class="flex-1 flex space-x-5 items-center">
          <mat-label [class]="'mb-4 w-1/3' + getAsteriskClass('invoiceNo')">Supplier Invoice No</mat-label>
          <mat-form-field [floatLabel]="'always'" class="flex-1">
            <input matInput formControlName="invoiceNo">
          </mat-form-field>
        </div>
        <div class="flex-1 flex space-x-5 items-center">
          <mat-label [class]="'mb-4 w-1/3' + getAsteriskClass('subTotalCostPrice')">Sub-Total Cost Price</mat-label>
          <mat-form-field [floatLabel]="'always'" class="flex-1 clear-input">
            <input matInput formControlName="subTotalCostPrice">
          </mat-form-field>
        </div>
        <div class="flex-1"></div>
      </div>
    </div>
    <div class="max-w-2xl p-4 pb-0 rounded-r-lg" *ngIf="!isDoView()">
      <input
        [formControl]="attachmentControl"
        type="file"
        hidden
        multiple
        class="form-control"
        (change)="onFileChange($event)" #fileInput>
      <mat-label class="block">Attachment support files</mat-label>
      <div>
        <mat-list *ngFor="let attachment of attachments">
          <mat-list-item class="border-b border-b-2 space-between flex-row-reverse">
            <span matListItemTitle class="cursor-pointer" (click)="downloadAttachment(attachment)">{{attachment.fileName}}</span>
            <span matListItemIcon class="cursor-pointer" (click)="deleteAttachment(attachment)"><mat-icon>close</mat-icon></span>
          </mat-list-item>
        </mat-list>
        <button mat-button (click)="fileInput.click()" class="confirm-btn mt-4"><mat-icon>attach_file</mat-icon>Attach supporting</button>
      </div>
    </div>
  </div>

  <div class="float-right" footer>
    <button mat-button (click)="close()" class="mr-5 cancel-btn">Close</button>
    <button mat-button (click)="save()" class="confirm-btn" *ngIf="!isDoView()">Save</button>
  </div>
</app-scene>

