import { Component, OnInit } from '@angular/core';
import { DoModificationService } from "../do-modification-service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {finalize} from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import {SuggestInputData} from "../../../common-component/suggest-input/suggest-input";
import {ProductService} from "../../product/product-service";
import * as moment from "moment";
import {LocationService} from "../../location/location-service";
import {Product} from "../../product/product";
import {Location} from "../../location/location";

@Component({
  selector: 'app-do modification-detail',
  templateUrl: './do-modification-detail.component.html',
  styleUrls: ['./do-modification-detail.component.css'],
  providers: [DoModificationService, LocationService]
})
export class DoModificationDetailComponent implements OnInit {
  loading = true;
  doModificationId = 0;
  formGroup: FormGroup;

  products: Product[] = [];
  productSuggestData = new SuggestInputData(
    () => this.products,
    (key, p) => p.description.includes(key),
    (key, p) => p.description === key,
  )

  locations: Location[] = [];
  locationSuggestData = new SuggestInputData(
    () => this.locations,
    (key, l) => l.locationName.includes(key),
    (key, l) => l.locationName === key,
  )

  constructor(private route: ActivatedRoute,
              private router: Router,
              private doModificationService: DoModificationService,
              formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private productService: ProductService,
              private locationService: LocationService,
  ) {
    this.formGroup = formBuilder.group({
      doNo: new FormControl('', [Validators.required]),
      doQty: new FormControl(''),
      errorCause: new FormControl(''),
      otherCause: new FormControl(''),
      newCustomerSite: new FormControl(''),
      newVehicleNo: new FormControl(''),
      newProduct: new FormControl(''),
      newTransporter: new FormControl(''),
      newDoDate: new FormControl(''),
      cancel: new FormControl(false),
    });
    this.formGroup.controls["doNo"].disable();
  }

  hadInvoice = false;

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParamMap;
    const doNo = queryParams.get("doNo");
    if (!doNo) {
      this.close();
      return;
    }
    this.locationService
      .findLocationByDoNo(doNo)
      .subscribe(r => this.locations = r);
    this.productService
      .findProductByDoNo(doNo)
      .subscribe(r => this.products = r);
    this.formGroup.controls["doNo"].setValue(doNo);
    this.loading = true;
    this.doModificationService.getDoModificationByDoNo(doNo).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: (data) => {
        this.doModificationId = data.id;
        this.formGroup.get('password')?.clearValidators();
        this.formGroup.patchValue({
          doNo: data.doNo,
          doQty: data.doQty,
          errorCause: data.errorCause,
          otherCause: data.otherCause,
          newCustomerSite: data.location,
          newVehicleNo: data.newVehicleNo,
          newProduct: data.product,
          newTransporter: data.newTransporter,
          newDoDate: data.newDoDate ? moment(data.newDoDate, "DD-MM-YYYY") : "",
          cancel: data.cancel,
        });
        this.hadInvoice = data.hadInvoice;
      },
      error: () => {
      },
    });
  }

  save() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) return;

    const action = this.doModificationId === 0 ? 'Create' : 'Update';
    this.loading = true;
    this.doModificationService.saveDoModification(this.doModificationId, this.formGroup.getRawValue()).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: () => {
        this.snackBar.open(action + ' do modification successful', 'Close', {duration: 2000});
        this.close();
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  close() {
    this.router.navigate(["/do-modifications"]);
  }

}
