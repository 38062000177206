import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-two',
  templateUrl: './two.component.html',
  providers: [],
  styleUrls: ['./two.component.css'],
})
export class TwoComponent implements OnInit {
  constructor(private snackBar: MatSnackBar) {}

  ngOnInit(): void {}

  processError(error: any) {
    this.snackBar.open(error, 'Close', { duration: 10000 });
  }
}
