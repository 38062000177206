<div class="main-container">
  <mat-drawer-container
    class="nav-drawer-container" autosize>
    <mat-drawer #drawer
                *ngIf="(currentUser)"
                class="pt-0 mt-0 min-h-full h-full w-2/12 flex nav-drawer-sidenav"
                mode="side"
                opened="true">
      <div class="pl-5 w-full h-16 flex">
        <h1
          class="mb-0 pl-3 w-full inline-block text-left self-center text-3xl font-bold text-secondary">
          Fuyuan
        </h1>
      </div>
      <mat-divider class="border-divider"></mat-divider>
      <mat-tree
        class="pl-5 align-text-top self-center flex-grow w-[100%]"
        [dataSource]="dataSource"
        [treeControl]="treeControl">
        <mat-tree-node
          class="m-0"
          *matTreeNodeDef="let node" matTreeNodePadding>
          <span class="material-symbols-outlined w-5 h-5">
              {{node.icon}}
          </span>
          <a routerLink="{{node.route}}" class="pl-3" >{{node.name}}</a>
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild"
                       matTreeNodePadding>
          <div matTreeNodeToggle disabled class="flex justify-center items-center">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' :
              'chevron_right'}}
            </mat-icon>
          </div>
          {{node.name}}
        </mat-tree-node>
      </mat-tree>

      <div
        class="text-secondary pb-2 pl-2">
        <h6>Copyright ©{{currentYear}}</h6>
        <h6>Fuyuan Resources Pte Ltd</h6>
        <h6>All rights reserved</h6>
      </div>
    </mat-drawer>
    <div class="nav-drawer-sidenav-content flex flex-col relative">
      <div *ngIf="(currentUser)"
           class="z-10 absolute flex self-end bottom-0 left-0">
        <button
          class="bottom-0 left-0 absolute w-9 h-9 z-10 mb-3 ml-3 rounded-full
          text-primary bg-secondary text-primary leading-3
          border border-primary"
          (click)="drawer.toggle()">
          <mat-icon>{{ drawer && drawer.opened ?
            'chevron_left' : 'chevron_right'
            }}</mat-icon>
        </button>
      </div>
      <mat-card *ngIf="(currentUser)">
        <div class="min-w-full w-full h-16 flex">
                    <span
                      class="pl-3 text-left self-center flex-grow">
                        Inventory & Billing System
                    </span>
          &nbsp;
          <ngx-avatar
            class="pr-3 text-right self-center"
            name="{{currentUser.name.split(' ')[0]}}"></ngx-avatar>

          <a routerLink="{{'/user/' + currentUser.id}}"
             class="pr-3 right-0 text-right self-center">{{currentUser.name}}</a>
          <!-- <span routerLink="/change-password"
              class="pr-3 right-0 text-right self-center">
              System Admin</span> -->
          <button
            class="pr-3 right-0 text-right self-center text-primary bg-secondary"
            (click)="logout()">
            <mat-icon>logout</mat-icon>
          </button>

        </div>
      </mat-card>
      <mat-divider></mat-divider>
      <div class="flex-1 overflow-auto">
        <router-outlet></router-outlet>
      </div>
    </div>

  </mat-drawer-container>
</div>
