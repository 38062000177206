import {Component, OnInit} from '@angular/core';
import { ProductService } from "./product-service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Product } from "./product";
import { finalize, switchMap, tap } from "rxjs/operators";
import { Uom } from "../uom/uom";
import { SuggestInputData } from "../../common-component/suggest-input/suggest-input";
import { UomService } from "../uom/uom.service";
import { DialogService } from "../../common-component/dialog/dialog-service";
import {Sort} from "@angular/material/sort";
import {forkJoin} from "rxjs";

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  constructor(private productService: ProductService,
              private uomService: UomService,
              private snackBar: MatSnackBar,
              private dialog: DialogService,
  ) {
  }

  loading = true;

  ngOnInit(): void {
    this.reload();
  }

  compare(a: any, b: any, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  localSort(sort: Sort): void {
    this.products = [...this.products.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'code':
          return this.compare(a.code, b.code, isAsc);
        case 'description':
          return this.compare(a.description, b.description, isAsc);
        case 'activeStatus':
          return this.compare(a.activeStatus, b.activeStatus, isAsc);
        default:
          return this.compare(a.id, b.id, isAsc);
      }
    })]
  }

  private reload() {
    this.loading = true;
    forkJoin({
      uom: this.uomService.listUom().pipe(
        tap(r => this.uom = r)
      ),
      products: this.productService.listProducts().pipe(
        tap(r => {
          this.products = r;
          if (this.products.length < 1) this.addRow();
        })
      )
    }).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    });
  }

  displayedColumns: string[] = [
    'code',
    'description',
    'uom',
    'activeStatus',
    'action'
  ];

  uom: Uom[] = [];
  uomSuggestData = new SuggestInputData(
    () => this.uom,
    (key, t) => t.uomCode.includes(key),
    (key, t) => t.uomCode === key,
  )

  products: Product[] = [];

  addRow = () => {
    this.products = [...this.products, {id: 0, code: '', description: '', uom: <Uom>{}, activeStatus: true}]
  }

  deleteProduct(product: Product, index: number) {
    if(product.id) {
      this.dialog.confirm("Delete product", "Do you want to delete this product?", {alert: true})
        .pipe(
          tap(() => this.loading = true),
          switchMap(() => this.productService.deleteProduct(product)),
          finalize(() => this.loading = false),
        )
        .subscribe({
          next: () => {
            this.products = this.products.filter(p => p.id !== product.id);
            this.snackBar.open('Delete product successful', 'Close', {duration: 2000});
          },
          error: (error) => this.snackBar.open(error, 'Close', {duration: 10000}),
        });
    } else {
      this.products.splice(index, 1);
      this.products = [...this.products];
    }
  }

  save() {
    // validate table data
    const messages : string[] = [];
    const codeSet = new Set();
    this.products.forEach(product => {
      !product.code && messages.push("Code is required");
      !product.uom || !product.uom.id && messages.push("Uom is required");
      product.code && (codeSet.has(product.code) ? messages.push("Duplicated code") : product.code && codeSet.add(product.code));
    })
    if(messages.length > 0) {
      this.snackBar.open(messages as any, 'Hide', {duration: 10000});
      return;
    }

    this.loading = true;
    this.productService.saveProducts(this.products).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: () => {
        this.reload();
        this.snackBar.open('Save products successful', 'Close', {duration: 2000});
      },
      error: (error) => this.snackBar.open(error, 'Hide', {duration: 10000}),
    })
  }
}
