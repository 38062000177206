import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Customer } from './customer';
import {PagingRequest, PagingResult} from "../../common-component/pageable-datatable/pageable";
import { handleError } from "../../common/http-request";

@Injectable()
export class CustomerService {
  base: string = environment.API_ENDPOINT + '/customer/';

  constructor(private http: HttpClient) {}
  searchCustomers(request: PagingRequest<Customer>, params: { key?: string, code?: string, name?: string }) {
    return this.http
      .get<PagingResult<Customer>>(this.base + 'page', {
        params: {...request, ...params}
      })
      .pipe(catchError(handleError));
  }

  listCustomers() {
    return this.http
      .get<Customer[]>(this.base + 'list')
      .pipe(catchError(handleError));
  }

  saveCustomer(id: number, params: any) {
    if (id === 0) {
      return this.http
        .post<any>(this.base + 'create', params)
        .pipe(catchError(handleError));
    }
    return this.http
      .put<any>(this.base + 'update/' + id, params)
      .pipe(catchError(handleError));
  }

  getCustomer(id: number) {
    return this.http
      .get<Customer>(this.base + id)
      .pipe(catchError(handleError));
  }

  findCustomerByCode(str: any) {
    return this.http
      .get<Customer>(this.base + "?code=" + str)
      .pipe(catchError(handleError));
  }
}
