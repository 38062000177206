import { Component } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomerSummaryReportRecord } from "./customer-summary-report-record";
import { CustomerSummaryReportService } from "./customer-summary-report-service";
import { ReportAbstractComponent } from "../report-abstract.component";
import {of, Subject} from "rxjs";
import {catchError, map, switchMap} from "rxjs/operators";
import {CustomerService} from "../../customer/customer-service";
import {ProductService} from "../../product/product-service";
import {LocationService} from "../../location/location-service";
import {Product} from "../../product/product";
import {Location} from "../../location/location";

@Component({
  selector: 'app-customer-summary-report',
  templateUrl: './customer-summary-report.component.html',
  styleUrls: ['./customer-summary-report.component.css'],
  providers: [CustomerSummaryReportService, CustomerService, ProductService, LocationService]
})
export class CustomerSummaryReportComponent extends ReportAbstractComponent<CustomerSummaryReportRecord[]> {

  reportDisplayedColumns = [
    "date",
    "customerName",
    "locationName",
    "productName",
    "totalQty",
    "totalLoad"
  ]
  reportDisplayedTotalColumns = [
    'emptyFooter',
    'emptyFooter',
    'emptyFooter',
    'granTotalQtyLabel',
    'totalAmountQty',
    'totalAmountLoad'
  ];

  constructor(private _formBuilder: FormBuilder,
              private _snackBar: MatSnackBar,
              private customerSummaryReportService: CustomerSummaryReportService,
              customerService: CustomerService,
              productService: ProductService,
              locationService: LocationService
  ) {
    super(_formBuilder, _snackBar, customerSummaryReportService, customerService, productService, locationService);
  }

  getTotalAmountQty(): number {
    if (!this.reportData || !this.reportData.length) return 0;
    return this.reportData.reduce((store, current) => store + current.totalQty, 0);
  }

  getTotalAmountLoad() {
    if (!this.reportData || !this.reportData.length) return 0;
    return this.reportData.reduce((store, current) => store + current.totalLoad, 0);
  }

  getFieldList(): { fieldName: string; validators?: any[] }[] {
    return [
      {fieldName: "fromDate", validators: [Validators.required]},
      {fieldName: "toDate", validators: [Validators.required]},
      {fieldName: "fromCustomerCode"},
      {fieldName: "toCustomerCode"},
      {fieldName: "fromProductCode"},
      {fieldName: "toProductCode"},
      {fieldName: "fromLocationCode"},
      {fieldName: "toLocationCode"},
    ];
  }

  getSortDefault(): number {
    return 1;
  }

  getSortList(): string[] {
    return ["Date", "Customer Name", "Location Name", "Product Name", "Total Qty", "Total Load"];
  }

  customerKeyword = new Subject<{ name?: string, code?: string }>();
  suggestCustomers = this.customerKeyword.pipe(
    switchMap(key => this.customerService
      .searchCustomers({page: 0, size: 5, sortBy: key.name ? 'name' : key.code ? 'code' : 'id'}, key)
    ),
    map(pagingResult => pagingResult.content)
  );

  perfectMatchCustomer = this.customerKeyword.pipe(
    switchMap(key => this.customerService
      .findCustomerByCode(key)
    )
  );

  perfectMatchProduct: Product | null = null;
  suggestProducts: Product[] = [];

  reloadSuggestProducts = (code: string) => {
    this.perfectMatchProduct = null;
    this.suggestProducts = [];
    this.productService.findProductByCode(code).pipe(catchError(() => of(null))).subscribe(value => {
      this.perfectMatchProduct = value;
    })
    this.productService.pageProducts({page: 0, size: 5, sortBy: 'code'}, code, false, true)
      .subscribe(value => this.suggestProducts = value.content)
  };

  perfectMatchLocation: Location | null = null;
  suggestLocations: Location[] = [];

  reloadSuggestLocations = (code: string) => {
    this.perfectMatchLocation = null;
    this.suggestLocations = [];
    this.locationService.findLocationByCode(code).pipe(catchError(() => of(null))).subscribe(value => {
      this.perfectMatchLocation = value;
    })
    this.locationService.pageLocations({page: 0, size: 5, sortBy: 'locationCode'}, code).subscribe(value => {
      this.suggestLocations = value.content;
    })
  };
}
