import { Component, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Roles, User } from './user';
import { UserService } from './user-service';
import { PagingRequest } from "../../common-component/pageable-datatable/pageable";
import { PageableDatatableComponent } from "../../common-component/pageable-datatable/pageable-datatable.component";
import { DialogService } from "../../common-component/dialog/dialog-service";
import { finalize, switchMap, tap } from "rxjs/operators";
import {Sort} from "@angular/material/sort";
import {AuthenticationService} from "../auth-route/auth-route.service";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  providers: [UserService],
  styleUrls: ['./user.component.css'],
})
export class UserComponent {
  displayedColumns: string[] = [
    'id',
    'username',
    'name',
    'email',
    'role',
    'activeStatus',
    'action'
  ];

  @ViewChild(PageableDatatableComponent)
  datatable!: PageableDatatableComponent<User>;

  sort: Sort | null = null;

  sortChange(sort: Sort) {
    this.sort = sort;
    this.reload();
  }

  currentUser?: User | null;

  constructor(
    private dialog: DialogService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  loading = true;

  searchUsers = (request: PagingRequest<User>) => {
    this.loading = true;
    if (this.sort && this.sort.direction !== "") {
      request.sortDir = this.sort.direction;
      request.sortBy = this.sort.active as keyof User;
    }
    return this.userService.searchUsers(request).pipe(finalize(() => this.loading = false));
  }

  deleteUser(param: User): void {
    this.dialog.confirm("Delete user", "Do you want to delete this user?", {alert: true})
      .pipe(
        tap(() => this.loading = true),
        switchMap(() => this.userService.deleteUser(param)),
        finalize(() => this.loading = false),
      )
      .subscribe({
        next: () => {
          this.snackBar.open('Delete user successful', 'Close', {duration: 2000});
          this.reload();
        },
        error: (error) => this.snackBar.open(error, 'Close', {duration: 10000}),
      });
  }

  reload(): void {
    this.datatable.reload();
  }

  getRoleName(param: User): string | undefined {
    return Roles.find((role2) => role2.value === param.role)?.name;
  }
}
