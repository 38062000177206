<app-scene [header]="'User management'" [loading]="loading">
  <button [routerLink]="['/user/new']" class="text-btn" *ngIf="currentUser?.role === 'Admin'">
    <span class="material-symbols-outlined">add</span>
    <span>Add user</span>
  </button>
  <app-pageable-datatable [getDataFn]="searchUsers" matSort (matSortChange)="sortChange($event)">
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        No data matching the filter.
      </td>
    </tr>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.id > 0 ? element.id : ''}}
      </td>
    </ng-container>

    <ng-container matColumnDef="username">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Username</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.username }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Full name</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.email }}
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.role }}
      </td>
    </ng-container>

    <ng-container matColumnDef="activeStatus">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Active status</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <span class="active-status" [class.disable]="!element.activeStatus"></span>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <button mat-icon-button
          *ngIf="element.id > 0"
          [routerLink]="['/user', element.id]">
          <span
            class="material-symbols-outlined text-primary">
            edit
          </span>
        </button>
        <button mat-icon-button
                *ngIf="currentUser?.role === 'Admin' && element.id !== currentUser?.id && element.id > 0"
          (click)="deleteUser(element)">
          <span
            class="material-symbols-outlined text-alert">
            delete
          </span>
        </button>
      </td>
    </ng-container>
  </app-pageable-datatable>
</app-scene>
