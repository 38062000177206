import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Product } from './product';
import { handleError } from "../../common/http-request";
import { PagingRequest, PagingResult } from "../../common-component/pageable-datatable/pageable";
import {SuggestInputData} from "../../common-component/suggest-input/suggest-input";

@Injectable()
export class ProductService {
  base: string = environment.API_ENDPOINT + '/product/';

  constructor(private http: HttpClient) {}

  listProducts() {
    return this.http
      .get<Product[]>(this.base + 'list')
      .pipe(catchError(handleError));
  }

  pageProducts(request: PagingRequest<Product>, key: string, filterActive: boolean = true, filterForTransaction = false) {
    return this.http
      .get<PagingResult<Product>>(this.base + 'page', {
        params: {...request, key, filterActive, filterForTransaction}
      })
      .pipe(catchError(handleError));
  }

  saveProducts(products: Product[]) {
    products = products.map(product => ({...product, uomId: product.uom ? product.uom.id : null}));
    return this.http
      .put<any>(this.base + 'save-all', products)
      .pipe(catchError(handleError));
  }

  findProductByMiscTransactionId(miscTransactionId : number) {
    return this.http
      .get<PagingResult<Product>>(this.base + 'find', {
        params: { miscTransactionId }
      })
      .pipe(catchError(handleError));
  }

  deleteProduct(product: Product) {
    if (!product.id) {
      return throwError('Invalid Param. Id not exist.');
    }

    return this.http
      .delete<any>(this.base + 'delete/' + product.id)
      .pipe(catchError(handleError));
  }

  findProductByDoNo(doNo: string | null){
    return this.http.get<Product[]>(this.base + 'getProductByDoNo?doNo=' + doNo);
  }

  findProductByCode(code: string | null){
    return this.http.get<Product>(this.base + 'getProductByCode?code=' + code);
  }
}
