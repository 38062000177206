<app-scene header="Inventory Report" [loading]="loading">
  <div [formGroup]="formGroup" class="custom-form">
    <div class="w-[calc(100vw-486px)]">
      <div class="flex space-x-5">
        <div class="flex-1">
          <mat-label class="font-bold">Type</mat-label>
          <div class="flex space-x-5">
            <mat-form-field [floatLabel]="'always'" class="flex-1">
              <mat-select placeholder=" Type" formControlName="type">
                <mat-option *ngFor="let type of REPORT_TYPE" [value]="type">
                  {{type}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="flex-1">
          <mat-label class="font-bold">Do Date</mat-label>
          <div class="flex space-x-5">
            <mat-form-field [floatLabel]="'always'" class="flex-1">
              <input placeholder="From" matInput formControlName="fromDate" [matDatepicker]="pickerDateRangeFrom">
              <mat-datepicker-toggle matIconSuffix [for]="pickerDateRangeFrom"></mat-datepicker-toggle>
              <mat-datepicker #pickerDateRangeFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field [floatLabel]="'always'" class="flex-1">
              <input placeholder="To" matInput formControlName="toDate" [matDatepicker]="pickerDateRangeTo">
              <mat-datepicker-toggle matIconSuffix [for]="pickerDateRangeTo"></mat-datepicker-toggle>
              <mat-datepicker #pickerDateRangeTo></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="flex space-x-5">
        <div class="flex-1">
          <mat-label class="font-bold">Product Code</mat-label>
          <div class="flex space-x-5">
            <app-suggest-input class="flex-1"
                               placeholder="From"
                               [control]="formGroup.get('fromProductCode')"
                               [valueField]="'code'"
                               [nameField]="['code']"
                               [suggestData]="productSuggestData"
            >
            </app-suggest-input>
            <app-suggest-input  class="flex-1"
                                placeholder="To"
                                [control]="formGroup.get('toProductCode')"
                                [valueField]="'code'"
                                [nameField]="['code']"
                                [suggestData]="productSuggestData"
            >
            </app-suggest-input>
          </div>
        </div>
      </div>
    </div>
    <div>
      <button mat-button (click)="generateReport()" class="confirm-btn mr-5">
        <mat-icon>receipt</mat-icon>
        Generate Report
      </button>
      <button mat-button (click)="clearInput()" class="mr-5 cancel-btn">Clear</button>
    </div>

    <div class="mt-4 flex w-[calc(100vw-486px)]">
      <div class="flex-1">
        <mat-label class="font-bold mr-4">Sort By</mat-label>
        <mat-form-field [floatLabel]="'always'">
          <mat-select placeholder="Sort By" formControlName="sortBy">
            <mat-option *ngFor="let sort of getSortList()" [value]="sort">
              {{sort}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex-1"></div>
    </div>
    <div class="overflow-auto w-[2000px]" *ngIf="reportData.stockInGroups && reportData.stockInGroups.length">
      <h3 class="!font-bold">STOCK-IN REPORT</h3>
      <table class="mb-16 stockin" mat-table *ngFor="let inventoryGroup of reportData.stockInGroups" [dataSource]="inventoryGroup.transactions">
        <tr mat-header-row *matHeaderRowDef="reportDisplayedColumns"></tr>
        <tr mat-header-row *matFooterRowDef="reportDisplayedTotalColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: reportDisplayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="reportDisplayedColumns.length">
            No data matching the filter.
          </td>
        </tr>

        <ng-container matColumnDef="transactionDate">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{element.transactionDate | date : 'dd-MM-yyyy'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="supplierName">
          <th mat-header-cell *matHeaderCellDef>Name Of Supplier</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            {{element.supplierName}}
          </td>
        </ng-container>

        <ng-container matColumnDef="countryOfOrigin">
          <th mat-header-cell *matHeaderCellDef>Country Of Origin</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{element.countryOfOrigin}}
          </td>
        </ng-container>

        <ng-container matColumnDef="invoiceDate">
          <th mat-header-cell *matHeaderCellDef>Supplier Invoice Date</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{element.invoiceDate | date : 'dd-MM-yyyy'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="invoiceNo">
          <th mat-header-cell *matHeaderCellDef>Supplier Invoice Number</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            {{element.invoiceNo}}
          </td>
        </ng-container>

        <ng-container matColumnDef="productName">
          <th mat-header-cell *matHeaderCellDef>Product Name</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            {{element.productName}}
          </td>
        </ng-container>

        <ng-container matColumnDef="rawMaterialCost">
          <th mat-header-cell *matHeaderCellDef>Raw Material Cost (Per Ton)</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{element.rawMaterialCost | currency: 'SGD ' : 'symbol' : '1.2-2'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="freightCharges">
          <th mat-header-cell *matHeaderCellDef>Freight Charges (Per Ton)</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{element.freightCharges | currency: 'SGD ' : 'symbol' : '1.2-2'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="distanceCharges">
          <th mat-header-cell *matHeaderCellDef>Distance Charges (Per Ton)</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{element.distanceCharges | currency: 'SGD ' : 'symbol' : '1.2-2'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="subTotalCostPrice">
          <th mat-header-cell *matHeaderCellDef>SubTotal Cost Price</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{element.subTotalCostPrice | currency: 'SGD ' : 'symbol' : '1.2-2'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="carriageCharges">
          <th mat-header-cell *matHeaderCellDef>Carriage Charges (Per Ton)</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{element.carriageCharges | currency: 'SGD ' : 'symbol' : '1.2-2'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="netTotalCostPrice">
          <th mat-header-cell *matHeaderCellDef>Net Total Cost Price</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{element.netTotalCostPrice | currency: 'SGD ' : 'symbol' : '1.2-2'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="qty">
          <th mat-header-cell *matHeaderCellDef>Quantity</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{element.qty | number : '1.2-2'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="totalCost">
          <th mat-header-cell *matHeaderCellDef>Total Cost</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{element.totalCost | currency: 'SGD ' : 'symbol' : '1.2-2'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="emptyFooter">
          <td *matFooterCellDef [hidden]="!this.reportData.stockInGroups || !this.reportData.stockInGroups.length"></td>
        </ng-container>

        <ng-container matColumnDef="subTotalLabel">
          <td *matFooterCellDef class="text-center border-b-2 border-black font-bold px-4"
              [hidden]="!this.reportData.stockInGroups || !this.reportData.stockInGroups.length">Sub-Total
          </td>
        </ng-container>

        <ng-container matColumnDef="subTotalQty">
          <td *matFooterCellDef class="text-center border-b-2 border-black font-bold px-4"
              [hidden]="!this.reportData.stockInGroups || !this.reportData.stockInGroups.length">{{inventoryGroup.subTotalQuantity | number : '1.2-2'}}</td>
        </ng-container>

        <ng-container matColumnDef="subTotalCost">
          <td *matFooterCellDef class="text-center border-b-2 border-black font-bold px-4"
              [hidden]="!this.reportData.stockInGroups || !this.reportData.stockInGroups.length">{{inventoryGroup.subTotalCost | currency: 'SGD ' : 'symbol' : '1.2-2'}}</td>
        </ng-container>

      </table>
    </div>
    <div class="overflow-auto w-[calc(60vw-292px)]" *ngIf="reportData.stockOutGroups && reportData.stockOutGroups.length">
      <h3 class="!font-bold">STOCK-OUT REPORT</h3>
      <table class="mb-16 stockout" mat-table *ngFor="let inventoryGroup of reportData.stockOutGroups" [dataSource]="inventoryGroup.transactions">
        <tr mat-header-row *matHeaderRowDef="reportStockOutDisplayedColumns"></tr>
        <tr mat-header-row *matFooterRowDef="reportStockOutDisplayedTotalColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: reportStockOutDisplayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="reportStockOutDisplayedColumns.length">
            No data matching the filter.
          </td>
        </tr>

        <ng-container matColumnDef="transactionDate">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{element.transactionDate | date : 'dd-MM-yyyy'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="doNo">
          <th mat-header-cell *matHeaderCellDef>DO No.</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{element.remarks}}
          </td>
        </ng-container>

        <ng-container matColumnDef="supplierName">
          <th mat-header-cell *matHeaderCellDef>Name Of Customer</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{element.supplierName}}
          </td>
        </ng-container>

        <ng-container matColumnDef="productName">
          <th mat-header-cell *matHeaderCellDef>Product Name</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{element.productName}}
          </td>
        </ng-container>

        <ng-container matColumnDef="qty">
          <th mat-header-cell *matHeaderCellDef>Quantity</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="!text-center">
            {{element.qty | number : '1.2-2'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="emptyFooter">
          <td *matFooterCellDef [hidden]="!this.reportData.stockOutGroups || !this.reportData.stockOutGroups.length"></td>
        </ng-container>

        <ng-container matColumnDef="subTotalLabel">
          <td *matFooterCellDef class="text-center border-b-2 border-black font-bold px-4"
              [hidden]="!this.reportData.stockOutGroups || !this.reportData.stockOutGroups.length">Sub-Total
          </td>
        </ng-container>

        <ng-container matColumnDef="subTotalQty">
          <td *matFooterCellDef class="text-center border-b-2 border-black font-bold px-4"
              [hidden]="!this.reportData.stockOutGroups || !this.reportData.stockOutGroups.length">{{inventoryGroup.subTotalQuantity | number : '1.2-2'}}</td>
        </ng-container>

      </table>
    </div>
  </div>

  <div class="float-right" footer *ngIf="(reportData.stockInGroups && reportData.stockInGroups.length > 0) || (reportData.stockOutGroups && reportData.stockOutGroups.length > 0)">
    <button mat-button (click)="downloadReportExcel()" class="mr-5 outline-btn">
      <mat-icon>save_alt</mat-icon>
      Download Excel
    </button>
    <button mat-button (click)="downloadReportPdf()" class="mr-5 outline-btn">
      <mat-icon>save_alt</mat-icon>
      Download PDF
    </button>
    <button mat-button (click)="printReport()" class="confirm-btn">
      <mat-icon>local_printshop</mat-icon>
      Print Report
    </button>
  </div>
</app-scene>

