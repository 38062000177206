export class SuggestInputData<V> {
  private getOptions: () => V[];
  value: V | null = null;

  suggests: V[] = [];
  private suggestFilter: (key: string, option: V) => boolean;
  private perfectMatchFilter?: (key: string, option: V) => boolean;
  perfectMatch: V | null = null;
  currentKey = '';

  constructor(
    getOptions: () => V[],
    suggestFilter: (key: string, option: V) => boolean,
    perfectMatchFilter?: (key: string, option: V) => boolean,
    initValue?: V | null,
  ) {
    this.getOptions = getOptions;
    this.suggestFilter = suggestFilter;
    this.perfectMatchFilter = perfectMatchFilter;
    this.value = initValue ?? null;
  }

  public reload(key: string) {
    if (key === this.currentKey && this.suggests.length > 0) return;

    const options = this.getOptions();

    this.currentKey = key;
    this.suggests = options.filter(o => this.suggestFilter(key, o)).slice(0, 5);
    const perfectMatchFilter = this.perfectMatchFilter;
    if (perfectMatchFilter) {
      this.perfectMatch = key ? options.find(o => perfectMatchFilter(key, o)) ?? null : null;
    }
  }
}
