<app-scene header="Search Invoice" [loading]="loading">
  <div [formGroup]="formGroup" class="max-w-2xl custom-form">
    <div>
      <mat-label class="font-bold">Invoice No.</mat-label>
      <div class="flex space-x-5">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input placeholder="From" matInput formControlName="fromInvoiceNo">
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input placeholder="To" matInput formControlName="toInvoiceNo">
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-label class="font-bold">Invoice Date</mat-label>
      <div class="flex space-x-5">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input placeholder="From" matInput formControlName="fromInvoiceDate" [matDatepicker]="pickerDateRangeFrom">
          <mat-datepicker-toggle matIconSuffix [for]="pickerDateRangeFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerDateRangeFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input placeholder="To" matInput formControlName="toInvoiceDate" [matDatepicker]="pickerDateRangeTo">
          <mat-datepicker-toggle matIconSuffix [for]="pickerDateRangeTo"></mat-datepicker-toggle>
          <mat-datepicker #pickerDateRangeTo></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-label class="font-bold">Customer Code</mat-label>
      <div class="flex space-x-5">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input placeholder="From" matInput formControlName="fromCustomerCode">
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input placeholder="To" matInput formControlName="toCustomerCode">
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-label class="font-bold">Location Code</mat-label>
      <div class="flex space-x-5">
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input placeholder="From" matInput formControlName="fromLocationCode">
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" class="flex-1">
          <input placeholder="To" matInput formControlName="toLocationCode">
        </mat-form-field>
      </div>
    </div>
    <div>
      <button mat-button (click)="getListData()" class="confirm-btn mr-5">
        <mat-icon>search</mat-icon>
        Search
      </button>
      <button mat-button (click)="clearInput()" class="mr-5 cancel-btn">Clear</button>
    </div>
  </div>
  <div class="mt-4">
    <table mat-table [dataSource]="searchInvoiceData">
      <tr mat-header-row *matHeaderRowDef="reportDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: reportDisplayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="reportDisplayedColumns.length">
          No data matching the filter.
        </td>
      </tr>

      <ng-container matColumnDef="check">
        <th mat-header-cell *matHeaderCellDef><mat-checkbox [(ngModel)]="allCheck" (change)="handleAllCheck()"></mat-checkbox></th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <mat-checkbox [(ngModel)]="checks[i]" (change)="handleCheck()"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="viewInvoice">
        <th mat-header-cell *matHeaderCellDef>View</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <button mat-icon-button
                  (click)="handleViewInvoice(element)">
            <span class="material-symbols-outlined text-primary">remove_red_eye</span>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="invoiceNo">
        <th mat-header-cell *matHeaderCellDef>Invoice No.</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{element.invoiceNo}}
        </td>
      </ng-container>

      <ng-container matColumnDef="invoiceDate">
        <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{element.invoiceDate | date : 'dd-MM-yyyy'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="customerCode">
        <th mat-header-cell *matHeaderCellDef>Customer Code</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{element.location ? element.location.customer.code : element.customer?.code}}
        </td>
      </ng-container>

      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef>Customer Name</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{element.location ? element.location.customer.name : element.customer?.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="locationCode">
        <th mat-header-cell *matHeaderCellDef>Location Code</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{element.location?.locationCode}}
        </td>
      </ng-container>

      <ng-container matColumnDef="locationName">
        <th mat-header-cell *matHeaderCellDef>Location Name</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{element.location?.locationName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="invoiceAmount">
        <th mat-header-cell *matHeaderCellDef>Total Amount</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{element.actualInvoiceAmount | number : '1.2-2'}}
        </td>
      </ng-container>

    </table>
  </div>
  <div class="float-right" footer *ngIf="this.searchInvoiceData.length > 0">
    <button mat-button (click)="handleReprint()" class="confirm-btn">
      <mat-icon>local_printshop</mat-icon>
      Re-Print Invoice
    </button>
  </div>
</app-scene>


