<app-scene header="GST" [loading]="loading">
  <div [formGroup]="formGroup" class="max-w-2xl custom-form">
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Effective Start Date</mat-label>
        <input matInput formControlName="effectiveStartDate" [matDatepicker]="pickerStartDate">
        <mat-datepicker-toggle matIconSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerStartDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Effective End Date</mat-label>
        <input matInput formControlName="effectiveEndDate" [matDatepicker]="pickerEndDate">
        <mat-datepicker-toggle matIconSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerEndDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="flex space-x-5">
      <mat-form-field [floatLabel]="'always'" class="flex-1">
        <mat-label>Value</mat-label>
        <input matInput formControlName="value">
      </mat-form-field>
      <div class="flex-1"></div>
    </div>
  </div>
  <div class="float-right" footer>
    <button mat-button (click)="close()" class="mr-5 cancel-btn">Close</button>
    <button mat-button (click)="save()" class="confirm-btn">Save</button>
  </div>
</app-scene>
