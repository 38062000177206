<div class="flex flex-col h-[100%] relative">
  <div *ngIf="loading" class="absolute top-0 left-0 w-[100%] h-[100%] z-[1] flex">
    <div class="absolute top-0 left-0 bg-white opacity-50 w-[100%] h-[100%]"></div>
    <mat-progress-spinner color="primary" class="m-auto"
                          mode="indeterminate">
    </mat-progress-spinner>
  </div>
  <div class="flex-1 flex space-x-5">
    <ng-content select="[left]"></ng-content>
    <div class="flex-1">
      <div class="flex flex-col px-20 py-5">
        <div>
          <ng-container *ngTemplateOutlet="headerTemplate ? headerTemplate : defaultHeader"></ng-container>
          <ng-template #defaultHeader>
            <h1 *ngIf="header" class="text-3xl font-bold">{{ header }}</h1>
          </ng-template>
        </div>
        <div class="flex-1">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
    <ng-content select="[right]"></ng-content>
  </div>
  <div class="scene-footer clearfix">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
