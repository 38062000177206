import { Component, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductTemplate } from './product-template';
import { ProductTemplateService } from './product-template-service';
import { PagingRequest } from "../../common-component/pageable-datatable/pageable";
import { PageableDatatableComponent } from "../../common-component/pageable-datatable/pageable-datatable.component";
import { finalize, switchMap, tap } from "rxjs/operators";
import { DialogService } from "../../common-component/dialog/dialog-service";

@Component({
  selector: 'app-product-template',
  templateUrl: './product-template.component.html',
  providers: [ProductTemplateService],
  styleUrls: ['./product-template.component.css'],
})
export class ProductTemplateComponent {
  constructor(
    private dialog: DialogService,
    private productTemplateService: ProductTemplateService,
    private snackBar: MatSnackBar,
  ) {
  }

  loading = true;

  displayedColumns: string[] = [
    'id',
    'name',
    'description',
    'activeStatus',
    'action'
  ];

  @ViewChild(PageableDatatableComponent)
  datatable!: PageableDatatableComponent<ProductTemplate>;

  keyword = "";

  searchProductTemplates = (request: PagingRequest<ProductTemplate>) => {
    this.loading = true;
    return this.productTemplateService.searchProductTemplates(request, this.keyword, false).pipe(
      finalize(() => this.loading = false),
    );
  }

  reload(): void {
    this.datatable.reload();
  }

  delete(param: ProductTemplate): void {
    this.dialog.confirm("Delete template", "Do you want to delete this template?", {alert: true})
      .pipe(
        tap(() => this.loading = true),
        switchMap(() => this.productTemplateService.deleteTemplate(param)),
        finalize(() => this.loading = false),
      )
      .subscribe({
        next: () => {
          this.snackBar.open('Delete template successful', 'Close', {duration: 2000});
          this.reload();
        },
        error: (error) => this.snackBar.open(error, 'Close', {duration: 10000}),
      });
  }
}
