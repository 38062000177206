import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
  ViewChild
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from "rxjs";
import { PagingRequest, PagingResult } from "./pageable";
import { MatColumnDef, MatHeaderRowDef, MatNoDataRow, MatRowDef, MatTable } from "@angular/material/table";
import {MatSort, MatSortHeader, Sort} from "@angular/material/sort";

@Component({
  selector: 'app-pageable-datatable',
  templateUrl: './pageable-datatable.component.html',
  styleUrls: ['./pageable-datatable.component.css'],
})
export class PageableDatatableComponent<T> implements OnInit, AfterContentInit {
  @ContentChildren(MatHeaderRowDef) headerRowDefs!: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs!: QueryList<MatRowDef<T>>;
  @ContentChildren(MatColumnDef) columnDefs!: QueryList<MatColumnDef>;
  @ContentChildren(MatNoDataRow) noDataRows!: QueryList<MatNoDataRow>;

  @ViewChild(MatTable, {static: true}) table!: MatTable<T>;

  ngAfterContentInit() {
    this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
    this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
    this.headerRowDefs.forEach(headerRowDef => this.table.addHeaderRowDef(headerRowDef));

    if (this.noDataRows.last) {
      this.table.setNoDataRow(this.noDataRows.last);
    }
  }

  @Input()
  getDataFn!: (filter: PagingRequest<T>) => Observable<PagingResult<T>>;

  paging: PagingRequest<T> = {
    page: 0,
    size: 20
  }
  pagingResult: PagingResult<T> = {
    content: [],
    first: true,
    last: true,
    numberOfElements: 0,
    totalElements: 0,
    totalPages: 0,
    number: 0
  };

  constructor(private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.reload();
  }

  reload(): void {
    this.getDataFn(this.paging).subscribe({
      next: data => this.setDatasource(data),
      error: error => this.processError(error),
    });
  }

  setDatasource(data: PagingResult<T>) {
    this.pagingResult = data;
  }

  processError(error: any) {
    this.snackBar.open(error, 'Close', {duration: 10000});
  }

  getServerData(ev: any) {
    this.paging.size = ev.pageSize;
    this.paging.page = ev.pageIndex;
    this.reload();
  }
}
