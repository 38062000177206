import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PagingRequest, PagingResult } from 'src/app/common-component/pageable-datatable/pageable';
import { environment } from 'src/environments/environment';
import { handleError } from 'src/app/common/http-request';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Uom } from './uom';

@Injectable({
  providedIn: 'root'
})
export class UomService {
  base: string = environment.API_ENDPOINT + '/uom/';

  constructor(private http: HttpClient) { }


  listUom() {
    return this.http
      .get<Uom[]>(this.base + 'list')
      .pipe(catchError(handleError));
  }

  pageUoms(request: PagingRequest<Uom>, code: string) {
    return this.http
      .get<PagingResult<Uom>>(this.base + 'page', {
        params: {...request, code}
      })
      .pipe(catchError(handleError));
  }

  deleteUom(price: Uom): any {
    if (!price.id) {
      return throwError('Invalid Param. Id not exist.');
    }

    return this.http
      .delete<any>(this.base + 'delete/' + price.id)
      .pipe(catchError(handleError));
  }

  saveUom(prices: Uom[]) {
      return this.http
        .post<any>(this.base + 'create', prices)
        .pipe(catchError(handleError))
  }
}
