<table mat-table [dataSource]="pagingResult.content"
       class="mat-elevation-z8">
  <ng-content></ng-content>
</table>
<mat-paginator [length]="pagingResult.totalElements"
               [pageSize]="paging.size"
               [pageSizeOptions]="[5, 10, 25, 100]"
               (page)="getServerData($event)"
               aria-label="Select page">
</mat-paginator>
