import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { handleError } from "../../common/http-request";
import { MiscTransactionAttachment } from "./misc-transaction-attachment";
import { throwError } from "rxjs";
@Injectable()
export class MiscTransactionAttachmentService {
  base = environment.API_ENDPOINT + '/misc-transaction-attachment/';

  constructor(private http: HttpClient) {}

  listAttachments(transactionId: number) {
    return this.http
      .get<MiscTransactionAttachment[]>(this.base + 'list', { params: { transactionId } })
      .pipe(catchError(handleError));
  }

  download(blob: Blob, filename: string) {
    const a = document.createElement("a");
    a.setAttribute("href", URL.createObjectURL(blob));
    a.setAttribute("download", filename);
    a.click();
    URL.revokeObjectURL(a.getAttribute("href") as string);
  }

  downloadAttachment(attachment: MiscTransactionAttachment) {
    if(attachment.file) {
      const blob = new Blob([attachment.file]);
      this.download(blob, attachment.file.name)
      return;
    }
    if(attachment.id) {
      this.http
        .get(`${this.base}download/${attachment.id}`, { responseType: 'blob' as 'json' })
        .pipe(catchError(handleError))
        .subscribe((response: any) => {
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          this.download(new Blob(binaryData, {type: dataType}), attachment.fileName)
        })
    }
  }

  deleteMiscTransactionAttachment(miscTransactionAttachment: MiscTransactionAttachment) {
    if (miscTransactionAttachment.id) {
      return this.http
        .delete<any>(this.base + 'delete/' + miscTransactionAttachment.id)
        .pipe(catchError(handleError));
    } else {
      return throwError('Invalid Param. Id not exist.');
    }
  }

}
