<div class="h-screen flex self-center justify-center items-center">
  <mat-card>
    <h5 class=" mt-3 pt-3 text-center">Fuyuan Inventory & Billing</h5>
    <mat-card-content>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="grid grid-cols-2 justify-center items-center">
          <div class="col-span-2">
            <h5 class="text-center">Login</h5>
          </div>
          <div>
            <label>Username</label>
          </div>
          <div>
            <input
              type="text" formControlName="username"
              class="pl-3 border border-black"
              [ngClass]="{ 'bg-warn-yellow': submitted && formControls.username.errors }"/>
          </div>
          <div></div>
          <div></div>
          <div *ngIf="submitted && formControls.username.errors"
               class="text-warn-red col-span-2">
            <div *ngIf="formControls.username.errors.required">
              Username is required
            </div>
          </div>
          <div class="mt-5">
            <label>Password</label>
          </div>
          <div class="mt-5">
            <input
              type="password" formControlName="password"
              class="pl-3 border border-black"
              [ngClass]="{ 'bg-warn-yellow': submitted && formControls.password.errors }"/>
          </div>
          <div></div>
          <div></div>
          <div *ngIf="submitted && formControls.password.errors"
               class="text-warn-red col-span-2">
            <div *ngIf="formControls.password.errors.required">
              Password is required
            </div>
          </div>
          <div *ngIf="error"
               class="mt-3 col-span-2 text-warn-red flex self-center justify-center items-center">{{ errorMessage }}
          </div>
          <div
            class="mt-3 col-span-2 flex self-center justify-between items-center">
            <a [routerLink]="['/forget-password']" class="auth-forgot-link underline">Forget password?</a>
            <button
              class="p-3 rounded-md bg-primary text-secondary"
              [disabled]="loading">
                            <span *ngIf="loading"
                                  class="spinner-border spinner-border-sm mr-1"></span>
              Login
            </button>
          </div>
        </div>
      </form>
      <div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
