import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { DialogService } from "../../common-component/dialog/dialog-service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PageableDatatableComponent } from "../../common-component/pageable-datatable/pageable-datatable.component";
import { PagingRequest } from "../../common-component/pageable-datatable/pageable";
import { finalize, switchMap, tap } from "rxjs/operators";
import { MiscTransactionService } from "./misc-transaction-service";
import {
  DO_TYPE,
  MiscTransaction,
  MiscTransactionSearch,
  MiscTransactionTypeAll
} from "./misc-transaction";

@Component({
  selector: 'app-misc-transaction',
  templateUrl: './misc-transaction.component.html',
  styleUrls: ['./misc-transaction.component.css'],
})
export class MiscTransactionComponent implements AfterViewInit {
  constructor(
    private dialog: MatDialog,
    private dialogService: DialogService,
    private transactionService: MiscTransactionService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngAfterViewInit(): void {
    this.search.type = MiscTransactionTypeAll[0];
  }

  loading = true;

  displayedColumns: string[] = [
    'transactionDate',
    'type',
    'productCode',
    'qty',
    'balance',
    'supplierName',
    'totalCost',
    'action',
  ];

  @ViewChild(PageableDatatableComponent)
  datatable!: PageableDatatableComponent<MiscTransaction>;

  search: MiscTransactionSearch = <MiscTransactionSearch>{
    type: MiscTransactionTypeAll[0]
  };

  types = MiscTransactionTypeAll;
  DO_TYPE = DO_TYPE;

  searchMiscTransactions = (request: PagingRequest<MiscTransaction>) => {
    this.loading = true;
    return this.transactionService.searchMiscTransactions({...request, sortBy: 'transactionDate', sortDir: 'desc'}, {...this.search})
      .pipe(finalize(() => this.loading = false));
  }

  deleteMiscTransaction(param: MiscTransaction): void {
    this.dialogService.confirm(
      "Delete Misc Transaction",
      "Do you want to delete this transaction?",
      {alert: true}
    )
      .pipe(
        tap(() => this.loading = true),
        switchMap(() => this.transactionService.deleteMiscTransaction(param)),
        finalize(() => this.loading = false),
      )
      .subscribe({
        next: () => {
          this.snackBar.open('Delete price successful', 'Close', {duration: 2000});
          this.reload();
        },
        error: (error) => this.snackBar.open(error, 'Close', {duration: 10000}),
      });
  }

  validateAndReload() {
    if(this.search.transactionStartDate
      && this.search.transactionEndDate
      && this.search.transactionStartDate.getTime()
        > this.search.transactionEndDate.getTime()) {
      this.snackBar.open('The transaction start date must before the transaction end date', 'Close', {duration: 10000})
      return;
    }
    this.reload();
  }

  reload(): void {
    this.datatable.reload();
  }
}
