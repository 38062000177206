<app-scene [header]="'Search Transaction'" [loading]="loading">
  <div class="flex gap-x-[2%]">
    <div class="flex w-[49%] gap-x-[2%]">
      <mat-form-field class="flex-1 w-[49%]">
        <button mat-icon-button matPrefix (click)="reload()" style="margin-left: 5px">
          <mat-icon class="text-primary">search</mat-icon>
        </button>
        <input matInput placeholder="Product code" [(ngModel)]="search.productCode" (change)="reload()">
      </mat-form-field>
      <mat-form-field class="flex-1 w-[49%]">
        <mat-select [(ngModel)]="search.type" (selectionChange)="reload()">
          <mat-option *ngFor="let type of types" [value]="type">
            {{type}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex w-[49%] gap-x-[2%]">
      <mat-form-field class="w-[49%]">
        <input matInput placeholder="Transaction Start Date" [(ngModel)]="search.transactionStartDate" (dateChange)="validateAndReload()" [matDatepicker]="pickerTransactionStartDate">
        <mat-datepicker-toggle matIconSuffix [for]="pickerTransactionStartDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerTransactionStartDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="w-[49%]">
        <input matInput placeholder="Transaction End Date" [(ngModel)]="search.transactionEndDate" (dateChange)="validateAndReload()" [matDatepicker]="pickerTransactionEndDate">
        <mat-datepicker-toggle matIconSuffix [for]="pickerTransactionEndDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerTransactionEndDate></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="flex">
    <div class="flex" style="padding-bottom: 22px">
      <button [routerLink]="['/misc-transaction', 'new']" class="text-btn" style="margin: auto 20px">
        <span class="material-symbols-outlined">add</span>
        <span>Add Transaction</span>
      </button>
    </div>
  </div>
  <app-pageable-datatable [getDataFn]="searchMiscTransactions">
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        No data matching the filter.
      </td>
    </tr>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="transactionDate">
      <th mat-header-cell *matHeaderCellDef>Transaction Date</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.transactionDate | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Transaction Type</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.type }}
      </td>
    </ng-container>

    <ng-container matColumnDef="productCode">
      <th mat-header-cell *matHeaderCellDef>Product Code</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.productCode }}
      </td>
    </ng-container>

    <ng-container matColumnDef="qty">
      <th mat-header-cell *matHeaderCellDef>Quantity</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.qty | number : '1.2-2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef>Balance</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.balance | number : '1.2-2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="supplierName">
      <th mat-header-cell *matHeaderCellDef>Supplier Name</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.supplierName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="totalCost">
      <th mat-header-cell *matHeaderCellDef>Total Cost</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        {{ element.totalCost | number : '1.2-2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <button mat-icon-button
                *ngIf="element.id > 0"
                [routerLink]="['/misc-transaction', element.id]">
          <span class="material-symbols-outlined text-primary" *ngIf="element.type !== DO_TYPE">edit</span>
          <span class="material-symbols-outlined text-primary" *ngIf="element.type === DO_TYPE">remove_red_eye</span>
        </button>
        <button mat-icon-button
                *ngIf="element.id > 0"
                (click)="deleteMiscTransaction(element)">
          <span class="material-symbols-outlined text-alert">delete</span>
        </button>
      </td>
    </ng-container>
  </app-pageable-datatable>
</app-scene>

