import { Component } from '@angular/core';
import { ReportAbstractComponent } from "../report-abstract.component";
import { FormBuilder, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { InventoryReportService } from "./inventory-report-service";
import { InventoryReportData } from "./inventory-report-data";
import {ProductService} from "../../product/product-service";
import {catchError} from "rxjs/operators";
import {Product} from "../../product/product";
import {of} from "rxjs";
import {CustomerService} from "../../customer/customer-service";
import {LocationService} from "../../location/location-service";

@Component({
  selector: 'app-customer-product-summary-report',
  templateUrl: './inventory-report.component.html',
  styleUrls: ['./inventory-report.component.css'],
  providers: [InventoryReportService, CustomerService, ProductService, LocationService]
})
export class InventoryReportComponent extends ReportAbstractComponent<InventoryReportData> {
  REPORT_TYPE = [
    'ALL',
    'STOCK-IN',
    'STOCK-OUT',
  ];

  reportDisplayedColumns = [
    "transactionDate",
    "supplierName",
    "countryOfOrigin",
    "invoiceDate",
    "invoiceNo",
    "productName",
    "rawMaterialCost",
    "freightCharges",
    "distanceCharges",
    "subTotalCostPrice",
    "carriageCharges",
    "netTotalCostPrice",
    "qty",
    "totalCost",
  ];
  reportDisplayedTotalColumns = [
    ...new Array(11).fill('emptyFooter'),
    'subTotalLabel',
    'subTotalQty',
    'subTotalCost',
  ];

  reportStockOutDisplayedColumns = [
    "transactionDate",
    "doNo",
    "supplierName",
    "productName",
    "qty",
  ];
  reportStockOutDisplayedTotalColumns = [
    'emptyFooter',
    'emptyFooter',
    'emptyFooter',
    'subTotalLabel',
    'subTotalQty',
  ];

  constructor(_formBuilder: FormBuilder,
              _snackBar: MatSnackBar,
              reportService: InventoryReportService,
              customerService: CustomerService,
              productService: ProductService,
              locationService: LocationService
  ) {
    super(_formBuilder, _snackBar, reportService, customerService, productService, locationService);
  }

  getFieldList(): { fieldName: string; initValue?:any, validators?: any[] }[] {
    return [
      {fieldName: "type", initValue: "ALL"},
      {fieldName: "fromDate", validators: [Validators.required]},
      {fieldName: "toDate", validators: [Validators.required]},
      {fieldName: "fromProduct"},
      {fieldName: "toProduct"},
    ];
  }

  getSortDefault(): number {
    return 0;
  }

  getSortList(): string[] {
    return ["Product"];
  }
  perfectMatchProduct: Product | null = null;
  suggestProducts: Product[] = [];

  reloadSuggestProducts = (code: string) => {
    this.perfectMatchProduct = null;
    this.suggestProducts = [];
    this.productService.findProductByCode(code).pipe(catchError(() => of(null))).subscribe(value => {
      this.perfectMatchProduct = value;
    })
    this.productService.pageProducts({page: 0, size: 5, sortBy: 'code'}, code, false, true)
      .subscribe(value => this.suggestProducts = value.content)
  };
}
