<mat-form-field floatLabel='always'>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <ng-template [ngIf]="control" [ngIfElse]="noControlInput">
    <input matInput class="rounded-sm"
           (blur)="onBlur()"
           (focus)="reload()"
           [placeholder]="placeholder"
           [matAutocomplete]="auto"
           [formControl]="formControl"
    />
  </ng-template>
  <ng-template #noControlInput>
    <input matInput class="rounded-sm"
           (blur)="onBlur()"
           (focus)="reload()"
           [placeholder]="placeholder"
           [matAutocomplete]="auto"
           [(ngModel)]="value"
           (ngModelChange)="onChange($event)"
    />
  </ng-template>
  <mat-autocomplete #auto="matAutocomplete"
                    [displayWith]="getName"
                    (optionSelected)="onSelected($event)"
                    [panelWidth]="'fit-content'"
                    (closed)="clearIfNeeded()"
  >

    <mat-option *ngIf="suggestData.perfectMatch" [value]="suggestData.perfectMatch"
    >
      {{ getOptionName(suggestData.perfectMatch) }}
    </mat-option>
    <ng-container *ngFor="let suggest of suggestData.suggests">
      <mat-option [value]="suggest" *ngIf="!suggestData.perfectMatch || !isOptionEqual(suggestData.perfectMatch, suggest)">
        {{ getOptionName(suggest) }}
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
