export const Roles = [
  { value: 'Admin', name: 'Finance Administrator' } as Role,
  { value: 'Operation', name: 'Operation Team' } as Role,
] as const;

export interface Role {
  name: string;
  value: string;
}

export interface User {
  id: number;
  username: string;
  password: string;
  name: string;
  email: string;
  role: string;
  activeStatus: boolean;

  token: string;
}
