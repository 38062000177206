import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PagingRequest, PagingResult } from 'src/app/common-component/pageable-datatable/pageable';
import { environment } from 'src/environments/environment';
import { ProductTemplate } from '../product-template/product-template';
import { handleError } from 'src/app/common/http-request';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LocationTemplate, LocationTemplatePrice } from './location-template';
import { TransportPrice } from "../price/price";

@Injectable({
  providedIn: 'root'
})
export class LocationTemplateService {
  base: string = environment.API_ENDPOINT + '/template/';
  locationPriceTemplateBase: string = this.base + 'transport-price/';

  constructor(private http: HttpClient) { }

  searchLocationTemplates(request: PagingRequest<LocationTemplate>, name: string) {
    return this.http
      .get<PagingResult<LocationTemplate>>(this.base + 'page', {
        params: {...request, name, type: "TRANSPORT"}
      })
      .pipe(catchError(handleError));
  }

  listTemplates() {
    return this.http
      .get<LocationTemplate[]>(this.base + 'list/active', {
        params: {type: "TRANSPORT"}
      })
      .pipe(catchError(handleError));
  }

  getLocationTemplate(id: number) {
    return this.http
      .get<LocationTemplate>(this.base + id)
      .pipe(catchError(handleError));
  }

  listLocationPrices(locationTemplateId: number, customerId?: number) {
    let params = {locationTemplateId} as any;
    if(customerId) params = {...params, customerId};
    return this.http
      .get<LocationTemplatePrice[]>(this.locationPriceTemplateBase + 'list', {
        params
      })
      .pipe(catchError(handleError));
  }

  saveLocationTemplate(id: number, params: any, prices: LocationTemplatePrice[]) {
    const body = {
      ...params,
      type: 'TRANSPORT',
      transportPrices: prices.filter(price => price.location && price.location.id).map(price => ({
        id: price.id,
        transportPricePerLoad: price.transportPricePerLoad,
        transportPricePerTon: price.transportPricePerTon,
        locationId: price.location!.id
      }))
    };
    if (id === 0) {
      return this.http
        .post<any>(this.base + 'create', body)
        .pipe(catchError(handleError));
    }
    return this.http
      .put<any>(this.base + 'update/' + id, body)
      .pipe(catchError(handleError));
  }

  deleteTemplate(template: ProductTemplate) {
    if (!template.id) {
      return throwError('Invalid Param. Id not exist.');
    }

    return this.http
      .delete<any>(this.base + 'delete/' + template.id)
      .pipe(catchError(handleError));
  }

  deleteTransportPrice(transportPrice: TransportPrice) {
    if (!transportPrice.id) {
      return throwError('Invalid Param. Id not exist.');
    }

    return this.http
      .delete<any>(this.locationPriceTemplateBase + 'delete/' + transportPrice.id)
      .pipe(catchError(handleError));
  }
}
