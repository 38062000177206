export const CustomerTypes = ['CASH', 'CREDIT'] as const;

export interface Customer {
  id: number;
  code: string;
  name: string;
  type: typeof CustomerTypes[number];

  address1: string;
  address2: string;
  address3: string;
  telephone: string;
  fax: string;

  activeStatus: boolean;
}
